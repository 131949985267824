import firebase from "@/firebase";
import { mapActions, mapGetters, mapMutations } from "vuex";
import NewWaterFix from "../NewProposal/NewWaterFix.vue"
import { showDeleteDialogAlert } from "@/utils/DialogAlertHelper"
import EndPoints from "@/services/apiPdf/EndPoints";
import SolutionsServices from '@/services/firebase/SolutionsServices/SolutionsServices';
import SolutionsPoint from '@/services/firebase/SolutionsPoint/SolutionsPoint';
import WaterFixApi from "@/services/apiPdf/WaterFixApi";
import WaterFixService from '@/services/firebase/WaterFix/WaterFixService';
import { showInfoSnackBar, showErrorSnackBar, showSuccessSnackBar } from "@/utils/SnackBartHelper";
import Rules from "@/utils/Rules";
import Utils from '@/scripts/Utils';
import SignatureModel from "../../../objects/Waterfix/CheckListTab/SignatureModel";
import SignatureController from "../../../objects/Waterfix/CheckListTab/SignatureController";

import Signature from '@/components/Waterfix/Signature'

export default {
  name: "ConsultWaterFix",
  components: {
    NewWaterFix,
    Signature,
  },
  data: function () {
    return {
      itemsMenu: [
        { value: 'proposed', title: 'WaterFix', slug: 'waterfix' },
        { value: 'proposed', title: 'Termo de Ciência', slug: 'compromise-term' },
        { value: 'proposed', title: 'Termo de Instalação', slug: 'installation-term' },
        { value: 'proposed', title: 'Termo de Devolução', slug: 'return-term' },
        { value: 'proposed', title: 'Termo de Vistoria', slug: 'inspection-term' },
        { value: 'proposed', title: 'Ordem de Serviço', slug: 'order-service' },
      ],
      idProposalPDF: "",
      registration: null,
      openViewProposal: false,
      isLoadingProposal: false,
      registerProposalDelete: null,
      register: this.$route.params.register,
      proposalList: [],
      headers: [
        { text: "Matrícula", value: "registration" },
        { text: "Cliente", value: "client" },
        { text: "Ações", value: "actions", sortable: false },
      ],
      searchItens: ["Matrícula", "Cliente"],
      // -------------------------------------------------------
      searchText: "",
      columnToSeach: "Cliente",
      proposalId: null,
      proposal: null,
      locationName: null,
      loading: false,
      choosePdfModal: false,
      termOption: 'water-fix',
      downloadOption: 'general',
      formGroupingOption: 'single',
      termOptions: [
        {
          name: 'WaterFix',
          id: 'water-fix',
          request: WaterFixApi.downloadProposal,
        },
        {
          name: 'Termo de Ciência',
          id: 'compromise-term',
          request: WaterFixApi.downloadCompromiseTerm,
        },
        {
          name: 'Termo de Instalação',
          id: 'installation-term',
          request: WaterFixApi.downloadInstallationTerm,
        },
        {
          name: 'Termo de Devolução',
          id: 'return-term',
          request: WaterFixApi.downloadReturnTerm,
        },
        {
          name: 'Termo de Vistoria',
          id: 'inspection-term',
          request: WaterFixApi.downloadInspectionTerm,
        },
        // {
        //   name: 'Ordem de Serviço',
        //   id: 'service-order',
        //   request: WaterFixApi.downloadServiceOrder,
        // },
      ],
      downloadOptions: [
        {
          name: 'Geral',
          id: 'general'
        },
        {
          name: 'Local',
          id: 'local'
        },
      ],
      formGroupingOptions: [
        {
          name: 'Junto',
          id: 'single'
        },
        {
          name: 'Separado',
          id: 'separate'
        },
      ],
      isSigned: false,
      locals: [],
      selectedLocals: [],
      signableDocuments: ['compromise-term', 'installation-term', 'return-term', 'inspection-term'],
      isSignedDocuments: { // Carregar junto com a abertura da modal de acordo com a proposta
        'compromise-term': false,
        'installation-term': false,
        'return-term': false,
        'inspection-term': false,
      },
      documentUrl: "https://firebasestorage.googleapis.com/v0/b/centralfoz-homolog.appspot.com/o/testes%2FFOZ_SUSTENTAVEL_TERMO_DE_INSTALACAO.pdf?alt=media&token=76b70ab3-b379-4fd9-83f6-9dd42da51ff8", // Colocar nesta variável a url da proposta
      signature: {},
      signatureModel: null,
      signatureController: null,
      dialogSignature: false,
      signatures: [],
    }
  },
  methods: {
    ...mapActions([
      'setWaterFixOpenStatus',
      'subscribeChannel',
    ]),
    // ---------------------------------------------------------
    openChoosePdfModal() {
      this.choosePdfModal = true;
    },
    closeChoosePdfModal() {
      this.choosePdfModal = false;
      this.resetDownload()
    },
    setProposalId(proposalId) {
      this.proposalId = proposalId;
    },
    clearProposalId() {
      this.setProposalId(null)
    },
    setProposalRegistration(registration) {
      this.registration = registration;
    },
    clearProposalRegistration() {
      this.setProposalRegistration(null)
    },
    setLocationName(locationName) {
      this.locationName = locationName;
    },
    clearLocationName() {
      this.setLocationName(null)
    },
    setLoading(status) {
      this.loading = status
    },
    newDownloadPdf(proposal) {
      // this.proposal = proposal
      const proposalId = proposal.id
      const registration = proposal.registration
      const locationName = proposal.locationInformation.name
      this.setProposalId(proposalId)
      this.setProposalRegistration(registration)
      this.setLocationName(locationName)
      this.openChoosePdfModal()
      this.read_signatures(proposal)
    },
    read_signatures(proposal) {
      this.isSignedDocuments = { // Carregar junto com a abertura da modal de acordo com a proposta
        'compromise-term': false,
        'installation-term': false,
        'return-term': false,
        'inspection-term': false,
      }
      this.signatures = this.signatureController.read_signatures_from_proposal(proposal).map((signature) => signature.get())
    },
    async save_signature(proposalId, registration, termOption, signatureURL, signatures) {
      this.signatures = await this.signatureController.save_signature(proposalId, registration, termOption, signatureURL, signatures)
      this.proposalList = this.proposalList.map(
        (proposal) => {
          if (proposal.id === proposalId) {
            return {
              ...proposal,
              signatures: Utils.copyObject(this.signatures)
            }
          }
          return proposal
        }
      )
      this.dialogSignature = false
    },
    async openDialogSignature() {
      this.setLoading(true)
      let requestParams = {
        proposalId: this.proposalId,
        term: this.termOption,
        download: 'general',
        group: 'single',
        locals: []
      }
      let request = this.termOptions.find((item) => item.id === this.termOption).request
      let documentPdfUrl = await request(this.locationName, requestParams, true)
      this.documentUrl = documentPdfUrl
      this.dialogSignature = true
      this.setLoading(false)
    },
    resetDownload() {
      this.termOption = this.termOptions[0].id;
      this.downloadOption = this.downloadOptions[0].id;
      this.formGroupingOption = this.formGroupingOptions[0].id;
      this.locals = [];
      this.selectedLocals = [];
      this.clearProposalId();
      this.clearProposalRegistration();
      this.clearLocationName();
    },
    async downloadPdfModal() {
      this.setLoading(true)
      let requestParams = {
        proposalId: this.proposalId,
        term: this.termOption,
        download: this.downloadOption,
        group: null,
        locals: []
      }
      let request = this.termOptions.find((item) => item.id === this.termOption).request
      if (this.formGroupingOption === 'separate') {
        if (!this.hasSelectedLocals) {
          showErrorSnackBar("É preciso selecionar os locais")
          this.setLoading(false)
          return;
        }
        requestParams.group = 'separate'
        if (this.selectedLocals.includes('all')) {
          requestParams.locals = ['all']
        } else {
          let localIds = Utils.removeDuplicates(this.locals.map((item) => item.id))
          localIds.shift()
          let diff1 = Utils.difference_array(localIds, this.selectedLocals)
          if (diff1.length <= 0) {
            requestParams.locals = ['all']
          } else {
            requestParams.locals = Utils.copyObject(this.selectedLocals)
          }
        }
      } else {
        requestParams.group = this.formGroupingOption
      }
      try {
        await request(this.locationName, requestParams)
        showSuccessSnackBar("O download do PDF foi feito com sucesso!")
      } catch (e) {
        console.error(e)
        showErrorSnackBar("Ocorreu um erro interno na geração do PDF")
      }

      this.setLoading(false)
      this.closeChoosePdfModal();
    },
    // ---------------------------------------------------------
    checkProposalPrices(proposal) {
      if (proposal) {
        if ('servicePrice' in proposal === true && 'solutionPrice' in proposal === true) {
          return false
        }
      }
      return true
    },
    async pdfRequest(proposal, action) {
      this.idProposalPDF = proposal.id
      let proposalPrices = proposal.proposed
      const solutionsData = await SolutionsServices.GetSolutions()
      const pointsData = await SolutionsPoint.getSolutionsPoint()

      proposalPrices.servicePrice.forEach((service, index) => {
        const solutionFinded = solutionsData.find(item => item.id === service.service)
        const solutionName = solutionFinded ? solutionFinded.solution : "- / -"
        proposalPrices.servicePrice[index].serviceNamePDF = solutionName
      })
      if (proposal.solutionList.length > 0) {
        proposal.solutionList.forEach((solution, index) => {
          const solutionFinded = solutionsData.find(item => item.id === solution.solution)
          const pointsFinded = pointsData.find(item => item.id === solution.type)
          const solutionName = solutionFinded ? solutionFinded.solution : "- / -"
          const pointName = pointsFinded ? pointsFinded.name : "- / -"
          proposal.solutionList[index].solutionNamePDF = solutionName
          proposal.solutionList[index].typeNamePDF = pointName
        });
      }

      proposal.consumptionHistoric.map(({ mounth }) => new Date(mounth.replace("-", "/")))
      proposal.consumptionHistoric.sort((a, b) => new Date(a.mounth.replace("-", "/")).getTime() - new Date(b.mounth.replace("-", "/")).getTime())

      let sendProposal = { ...proposal, proposed: proposalPrices }

      switch (action) {
        case 'proposed':
          this.statusRequest(await EndPoints.waterScanPdf(sendProposal))
          break;
        case 'checklist':
          this.statusRequest(await EndPoints.waterScanCheckListPdf(sendProposal))
          break;
      }
    },
    async statusRequest(res) {
      if (res) {
        this.idProposalPDF = ""
      } else (
        this.idProposalPDF = "",
        alert("Erro ao gerar o PDF!")
      )
    },
    openViewComponent(register) {
      this.registration = register
      this.openViewProposal = true;
    },
    closeComponent() {
      this.getCurrentProposal()
      this.openViewProposal = false;
      let path = '/water-fix/consult-proposal/all'
      if (this.$route.path !== path) this.$router.push(path);
    },
    getProposalDelet(register) {
      this.registerProposalDelete = register
      showDeleteDialogAlert(`Deseja excluir a proposta de matrícula "${register}"?`, this.deletProposal)
    },
    async deletProposal() {
      let register = this.registerProposalDelete
      this.isLoadingProposal = true
      await WaterFixService.deleteProposal(register)
      //Apenas para filtrar as propostas deletadas da visualização 
      this.proposalList = this.proposalList.filter((i, index) => {
        if (i.delete) {
          return
        }
        if (i.registration === register) {
          this.proposalList[index].delete = true
        }
        return i.registration !== register
      })
      this.isLoadingProposal = false
    },
    checkURL() {
      if (this.$route.params.register == "all") {
        this.getCurrentProposal()
      } else {
        this.openViewComponent(this.$route.params.register)
        this.getCurrentProposal()
      }
    },
    async getCurrentProposal() {
      this.isLoadingProposal = true
      await firebase.getProposalWaterFix()
        .then(response => {
          this.proposalList = response
          this.isLoadingProposal = false
        })
        .catch(err => {
          alert("Erro ao consultar propostas!")
          this.isLoadingProposal = false
          console.error(err)
        })
    },
  },
  computed: {
    filteredItems() {
      let text = Utils.defaultSpace(this.searchText)
      let list = this.proposalList
      list = list.filter((proposed) => {
        let value = ""
        if (this.columnToSeach == 'Matrícula') {
          value = proposed.registration
        } else if (this.columnToSeach == 'Cliente') {
          value = proposed.locationInformation.name
        }
        value = !value ? '' : value
        return (Utils.defaultSpace(value).toLowerCase().indexOf(text.toLowerCase()) > -1)
      });
      return list
    },
    rulesRequired() {
      return Rules.required();
    },
    isGeneralDownload() {
      return this.downloadOption == 'general'
    },
    isLocalDownload() {
      return this.downloadOption == 'local'
    },
    hasLocals() {
      return this.locals.length > 0;
    },
    hasSelectedLocals() {
      return this.selectedLocals.length > 0;
    },
    hasUniqueDownloadOptions() {
      return this.downloadOptions.length == 1;
    },
    IsSignableDocument() {
      return this.signableDocuments.includes(this.termOption)
    },
    isSignedDocument() {
      if (this.isSignedDocuments.hasOwnProperty(this.termOption)) return this.isSignedDocuments[this.termOption]
      return true
    },
  },
  watch: {
    termOption: {
      immediate: true,
      handler: function (newValue) {
        if (newValue) {
          switch (newValue) {
            case 'water-fix':
            case 'service-order':
              this.downloadOptions = [
                {
                  name: 'Geral',
                  id: 'general'
                },
              ];
            break;
            default:
              this.downloadOptions = [
                {
                  name: 'Geral',
                  id: 'general'
                },
                {
                  name: 'Local',
                  id: 'local'
                },
              ]
            break;
          }
          this.downloadOption = this.downloadOptions[0].id;
        }
      }
    },
    async formGroupingOption(newValue) {
      if (newValue) {
        if (newValue == 'separate') {
          this.setLoading(true);
          if (this.registration) {
            let proposal = await WaterFixService.getProposalByRegistration(this.registration)
            this.locals = proposal.solutionList.map(
              (item) => {
                return {
                  id: Utils.createSlug(item.local),
                  local: Utils.capitalizeString(item.local),
                }
              }
            )
            if (this.locals.length <= 0) {
              showErrorSnackBar("A proposta não tem Check List definido")
            } else {
              this.locals.unshift({
                id: 'all',
                local: 'Todos'
              })
            }
            this.setLoading(false);
          } else {
            this.setLoading(false);
            showErrorSnackBar("Ocorreu um erro na identificação da prosposta para baixar")
          }
        } else {
          this.locals = [];
          this.selectedLocals = [];
        }
      }
    },
    signatures(newVal) {
      if (Array.isArray(newVal)) {
        newVal.forEach(
          (sig) => {
            this.isSignedDocuments[sig.type] = true
          }
        )
      }
    }
  },
  created() {
    this.checkURL();
    this.signatureController = new SignatureController()
    this.signatureModel = new SignatureModel()
    this.signature = this.signatureModel.get()

    this.subscribeChannel({
      channel: 'waterfix',
      events: [
        // -----------------------------------------------------
        // Atualização da modal de assinaturas (checklist)
        {
          event: 'signature:modal',
          fn: (modal) => {
            this.dialogSignature = modal
          }
        }, 
        {
          event: 'signature',
          fn: (signatureURL) => {
            this.save_signature(this.proposalId, this.registration, this.termOption, signatureURL, this.signatures)
          }
        },
        {
          event: 'proposal.signature:update',
          fn: ({ id, signatures }) => {
            this.proposalList = this.proposalList.map(
              (proposal) => {
                if (proposal.id === id) {
                  return {
                    ...proposal,
                    signatures: signatures
                  }
                }
                return proposal
              }
            )
          }
        }
      ]
    })
  },
};