export const RoutesPath = {
    LOGIN: '/',
    CHOOSE_ALERT: '/alertas',
    ALERT_CONSUMPTION: '/alertas/cosumption',
    ALERT_TELEMETRY: '/alertas/telemetry',
    ALERT_GOAL: '/alertas/goal',
    DASHBOARD: '/dashboard',
    CONSUMPTION: '/consumption',
    CHOOSE_WATER_FIX: '/water-fix',
    WATER_FIX_NEW: '/water-fix/new-proposal',
    WATER_FIX_CONSULT: '/water-fix/consult-proposal/:register',
    WATER_FIX_ALL: '/water-fix/consult-proposal/all',


    CHOOSE_WATER_SCAN: '/water-scan',
    WATER_SCAN_NEW: '/water-scan/new-proposal',
    WATER_SCAN_CONSULT: '/water-scan/consult-proposal/:idProposal',
    WATER_SCAN_ALL: '/water-scan/consult-proposal/all',
    CALLED: '/called',
    UNITS: '/units',
    CHOOSE_SETTING: '/settings',
    SETTING_USERS: '/settings/users',
    SETTING_ALERTS: '/settings/alerts',
    SETTING_PERMISSIONS: '/settings/permissions',
    SETTING_HYDROMETRS: '/settings/hydrometers',
    SETTING_MODEMS: '/settings/modems',
    SETTING_WATER_AUTHORITIES: '/settings/water_authorities/compesa',
    SETTING_SOLUTIONS: '/solutions/solutions_services',
    SETTING_GROUP: '/solutions/group',
    SETTING_POINT: '/solutions/point',
    CHOOSE_SOLUTION: '/solutions',

    GOAL_FORECASTS: '/goals-forecasts',
    CHOOSE_REPORT: '/report',
    REPORT_ECONOMY: '/report/economy',
    REPORT_CALLED: '/report/called',
    REPORT_ALERT: '/report/alerts',
    REPORT_TELEMETRY: '/report/telemetry',
    NOTFOUND: '/404',
    FORBIDDEN: '/forbidden',
}