import DatePickerDialog from "@/components/DatePickerDialog/DatePickerDialog.vue";
import SelectClientDashboard from "@/components/Widgets/Select/select-client-dashboard.vue";
import SelectFranchiseeDashboard from "@/components/Widgets/Select/select-franchisee-dashboard.vue";
import SelectUnitAllOption from "@/components/Widgets/Select/select-unit-all-option.vue";
import ChartAlert from "@/components/charts/ChartsDashboard/Alert.vue";
import ChartCall from "@/components/charts/ChartsDashboard/Call.vue";
import ChartConsumption from "@/components/charts/ChartsDashboard/Consumption.vue";
import Alerts from "@/pages/Dashboard/Widgets/alerts.vue";
import Economy from "@/pages/Dashboard/Widgets/economy.vue";
import GeneralDetails from "@/pages/Dashboard/Widgets/generalDetails.vue";
import endpoint from "@/request/endpoint/dashboard.js";
import Utils from '@/scripts/Utils';
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Dashboard",
  components: {
    DatePickerDialog,
    GeneralDetails,
    ChartAlert,
    ChartConsumption,
    ChartCall,
    SelectFranchiseeDashboard,
    SelectClientDashboard,
    SelectUnitAllOption,
    Economy,
    Alerts,
  },

  data() {
    return {
      startDateDialog: false,
      startDatePicker: [Utils.formatDate(Utils.get_last_months()[0], '<YYYY>-<MM>'), Utils.formatDate(Utils.get_last_months()[5], '<YYYY>-<MM>')],
      franchisee_id: null,
      client_id: null,
      unit_id: null,
      openSelct: false,
      units: [],
      unit_ids: [],
      current_index_id: 0,
      is_executing: false,
      is_cycling: false,
      period: 6,
      progress_bar_fn: null,
      has_meta: false,
      parsed: null,
      selected: {
        franchisee_id: null,
        client_id: null,
        unit_id: null,
      },
      display: {
        unit_name: "",
        percentage: 0,
        consumption: {
          title: "Consumo",
          percentage: 0,
          is_loading: false,
          data: {
            labels: [],
            datasets: [],
          }
        },
        economy: {
          title: "Economia",
          percentage: 0,
          is_loading: false,
          data: {
            consumption: {
              average: 0,
              min: 0,
              max: 0,
              total: 0,
              formatAverage: Utils.formatNumber(0),
              formatMin: Utils.formatNumber(0),
              formatMax: Utils.formatNumber(0),
              formatTotal: Utils.formatNumber(0),
            },
            valueCurrency: {
              average: 0,
              min: 0,
              max: 0,
              total: 0,
              formatAverage: Utils.formatCurrency(0),
              formatMin: Utils.formatCurrency(0),
              formatMax: Utils.formatCurrency(0),
              formatTotal: Utils.formatCurrency(0),
            },
            savingConsumption: {
              average: 0,
              min: 0,
              max: 0,
              total: 0,
              formatAverage: Utils.formatNumber(0),
              formatMin: Utils.formatNumber(0),
              formatMax: Utils.formatNumber(0),
              formatTotal: Utils.formatNumber(0),
            },
            savingCurrency: {
              average: 0,
              min: 0,
              max: 0,
              total: 0,
              formatAverage: Utils.formatCurrency(0),
              formatMin: Utils.formatCurrency(0),
              formatMax: Utils.formatCurrency(0),
              formatTotal: Utils.formatCurrency(0),
            }
          }
        },
        call: {
          title: "Chamados",
          percentage: 0,
          is_loading: false,
          data: {
            labels: [],
            datasets: [],
            total: 0,
          }
        },
        alert: {
          title: "Alerta",
          percentage: 0,
          is_loading: false,
          data: {
            // non_periodic: {
            //   lack_of_signal: {
            //       total: 0
            //   },
            //   low_battery: {
            //       total: 0
            //   },
            //   no_variation: {
            //       total: 0
            //   },
            // },
            // periodic: []
            data: [],
            labels: [],
            total: 0,
          }
        },
        goal: {
          title: "Meta",
          percentage: 0,
          is_loading: false,
          data: {
            last_reading_date: null,
            next_reading_date: null,
            end_reading_date: null,
            meta_estimate: 0,
            economy_estimate: 0,
            total_calls_finished: 0,
          }
        },
      },
      request: {
        consumption: null,
        call: null,
        alert: null,
        goal: null,
        reading: null,
        historic: null,
      },
      stack_execution: [],
      is_active_execution: false,
      is_process_execution: false,

      units_id_allowed_current_user: [],
    };
  },
  computed: {
    ...mapGetters([
      "getUsers",
      "getCurrentUser",
      "getCurrentUserRank",
      "getCurrentUserID",
    ]),
    first_row_lg() {
      return this.has_meta ? 10 : 12
    },
    format_meta() {
      if (this.get_diff_meta == 0) return "--"
      return `${Utils.formatNumber(this.get_diff_meta)} M³`;
    },
    get_style_meta() {
      if (this.get_diff_meta == 0) return "padding: 15px;background-color: grey;color: white;font-weight: bold;font-size: 2vh;border-radius: 30px;";
      else if (this.get_diff_meta > 0) return "padding: 15px;background-color: rgb(34, 140, 34);color: white;font-weight: bold;font-size: 2vh;border-radius: 30px;";
      else return "padding: 15px;background-color: red;color: white;font-weight: bold;font-size: 2vh;border-radius: 30px;";
    },
    get_text_meta() {
      if (this.get_diff_meta == 0) return "--"
      else if (this.get_diff_meta > 0) return "Economia"
      else return "Ultrapassou"
    },
    get_text_style_meta() {
      if (this.get_diff_meta == 0) return "padding: 15px;color: grey;font-weight: bold;font-size: 2.2vh;"
      else if (this.get_diff_meta > 0) return "padding: 15px;color: rgb(34, 140, 34);font-weight: bold;font-size: 2.2vh;"
      else return "padding: 15px;color: red;font-weight: bold;font-size: 2.2vh;"
    },
    get_last_reading_date() {
      if (!this.display.goal.data.last_reading_date) return "--/--/--";
      return Utils.formatDate(this.display.goal.data.last_reading_date, "<DD>/<MM>/<YYYY>")
    },
    get_next_reading_date() {
      if (!this.display.goal.data.next_reading_date) return "--/--/--";
      return Utils.formatDate(this.display.goal.data.next_reading_date, "<DD>/<MM>/<YYYY>")
    },
    get_meta_estimate() {
      return Utils.formatNumber(this.display.goal.data.meta_estimate);
    },
    get_economy_estimate() {
      return Utils.formatNumber(this.display.goal.data.economy_estimate)
    },
    get_total_alerts() {
      return this.display.alert.data.total
    },
    get_end_reading_date() {
      if (!this.display.goal.data.end_reading_date) return "--/--/--";
      return Utils.formatDate(this.display.goal.data.end_reading_date, "<DD>/<MM>/<YYYY>")
    },
    get_total_calls_finished() {
      return this.display.goal.data.total_calls_finished
    }
  },

  methods: {
    ...mapActions([
      "getUnitsBy",
      "initUsersAndUnits",
      "getUsersBy",
    ]),
    async clearDateCalendar() {
      this.startDatePicker = [Utils.formatDate(Utils.get_last_months()[0], '<YYYY>-<MM>'), Utils.formatDate(Utils.get_last_months()[5], '<YYYY>-<MM>')]
    },
    async selectDateCalendar(date) {
      if (this.startDatePicker[0] > this.startDatePicker[1]) {
        let date_1 = this.startDatePicker[1]
        let date_2 = this.startDatePicker[0]
        this.startDatePicker[0] = date_1
        this.startDatePicker[1] = date_2
      }
      this.startDateDialog = false

    },
    add_execution(fn_promise) {
      this.stack_execution.push(fn_promise)
    },
    catch_franchisee_id(franchisee_id) {
      this.franchisee_id = franchisee_id;
    },
    catch_client_id(client_id) {
      this.client_id = client_id;
    },
    catch_unit_id(id) {
      if (this.units.length <= 0) return;
      this.set_selected(this.franchisee_id, this.client_id, id)

      if (!id) {
        Utils.waitingCondition(
          () => {
            this.close();
          },
          () => {
            return (
              !this.is_cycling &&
              !this.get_loading_display('consumption') &&
              !this.get_loading_display('economy') &&
              !this.get_loading_display('call') &&
              !this.get_loading_display('alert') &&
              !this.get_loading_display('goal')
            )
          },
          () => {
            this.execute();
          },
        )
      }
      else {
        Utils.waitingCondition(
          () => {
            this.close();
          },
          () => {
            return (
              !this.is_cycling &&
              !this.get_loading_display('consumption') &&
              !this.get_loading_display('economy') &&
              !this.get_loading_display('call') &&
              !this.get_loading_display('alert') &&
              !this.get_loading_display('goal')
            )
          },
          () => {
            this.cycle();
          },
        )
      }
    },

    set_selected(franchisee_id, client_id, unit_id) {
      this.selected = {
        franchisee_id,
        client_id,
        unit_id,
      }
    },

    clear_selected() {
      this.set_selected(null, null, null)
    },

    get_next_unit() {
      let parsed = {
        "type": null,
        "id": null,
        "unit_id": null,
        "start_date": this.startDatePicker[0],
        "end_date": this.startDatePicker[1],
      }
      if (this.selected.unit_id !== null) {
        if (this.selected.unit_id !== 'selecionar_todos') {
          parsed = {
            type: "unit",
            id: this.selected.unit_id,
            unit_id: this.selected.unit_id,
            "start_date": this.startDatePicker[0],
            "end_date": this.startDatePicker[1],
          }
        } else if (this.selected.client_id) {
          parsed = {
            type: "client",
            id: this.selected.client_id,
            unit_id: this.selected.unit_id,
            "start_date": this.startDatePicker[0],
            "end_date": this.startDatePicker[1],
          }
        } else if (this.selected.franchisee_id) {
          parsed = {
            type: "franchisee",
            id: this.selected.franchisee_id,
            unit_id: this.selected.unit_id,
            "start_date": this.startDatePicker[0],
            "end_date": this.startDatePicker[1],
          }
        }
      } else {
        let max_length = this.unit_ids.length
        if (this.current_index_id >= max_length) this.current_index_id = 0;
        parsed.type = "unit"
        parsed.id = this.unit_ids[this.current_index_id]
        parsed.unit_id = this.unit_ids[this.current_index_id]
        this.update_next_unit();
      }
      return parsed;
    },

    update_next_unit() {
      this.current_index_id++;
    },

    reset_display_unit() {
      this.reset_display_consumption()
      this.reset_display_economy()
      this.reset_display_call();
      this.reset_display_alert();
      this.reset_display_goal();
    },

    set_loading_display(type, status) {
      this.display[type].is_loading = status
    },

    get_loading_display(type) {
      return this.display[type].is_loading
    },

    set_display_data(type, data) {
      this.display[type].data = {
        ...this.display[type].data,
        ...data
      }
    },

    get_user_name(id) {
      let user = this.getUsers.find(
        (u) => {
          return u.id == id
        }
      )
      return user.hasOwnProperty('data') ? user.data.name : user.name;
    },

    get_unit_name(unit_id) {
      let unit = this.units.find(
        (u) => {
          return u.id == unit_id;
        }
      )
      return unit.name;
    },

    set_unit_name(parsed) {
      if (parsed.unit_id != 'selecionar_todos') {
        this.display.unit_name = this.get_unit_name(parsed.unit_id);
      } else if (parsed.type !== null) {
        this.display.unit_name = this.get_user_name(parsed.id);
      } else {
        this.display.unit_name = ''
      }
    },

    reset_display_consumption() {
      this.display['consumption'] = {
        title: "Consumo",
        percentage: 0,
        is_loading: this.display.consumption.is_loading,
        data: {
          labels: [],
          datasets: [],
        }
      };
    },

    reset_display_economy() {
      this.display['economy'] = {
        title: "Economia",
        percentage: 0,
        is_loading: this.display.economy.is_loading,
        data: {
          consumption: {
            average: 0,
            min: 0,
            max: 0,
            total: 0,
            formatAverage: Utils.formatNumber(0),
            formatMin: Utils.formatNumber(0),
            formatMax: Utils.formatNumber(0),
            formatTotal: Utils.formatNumber(0),
          },
          valueCurrency: {
            average: 0,
            min: 0,
            max: 0,
            total: 0,
            formatAverage: Utils.formatCurrency(0),
            formatMin: Utils.formatCurrency(0),
            formatMax: Utils.formatCurrency(0),
            formatTotal: Utils.formatCurrency(0),
          },
          savingConsumption: {
            average: 0,
            min: 0,
            max: 0,
            total: 0,
            formatAverage: Utils.formatNumber(0),
            formatMin: Utils.formatNumber(0),
            formatMax: Utils.formatNumber(0),
            formatTotal: Utils.formatNumber(0),
          },
          savingCurrency: {
            average: 0,
            min: 0,
            max: 0,
            total: 0,
            formatAverage: Utils.formatCurrency(0),
            formatMin: Utils.formatCurrency(0),
            formatMax: Utils.formatCurrency(0),
            formatTotal: Utils.formatCurrency(0),
          }
        }
      };
    },

    reset_display_call() {
      this.display['call'] = {
        title: "Chamados",
        percentage: 0,
        is_loading: this.display.call.is_loading,
        axis: 'y',
        data: {
          labels: [],
          datasets: [],
          total: 0,
        }
      };
    },

    reset_display_alert() {
      this.display['alert'] = {
        title: "Alerta",
        percentage: 0,
        is_loading: this.display.alert.is_loading,
        data: {
          data: [],
          labels: [],
          total: 0,
        }
      };
    },

    reset_display_goal() {
      this.display['goal'] = {
        title: "Meta",
        percentage: 0,
        is_loading: false,
        data: {
          last_reading_date: null,
          next_reading_date: null,
          end_reading_date: null,
          meta_estimate: 0,
          economy_estimate: 0,
          total_calls_finished: 0,
        }
      };
    },

    async request_consumption(parsed) {
      let response = null;
      response = await endpoint.consumption(parsed)
      return response;
    },

    async request_call(parsed) {
      let response = null;
      response = await endpoint.calls({ ...parsed, period: this.period })
      return response;
    },

    async request_alert(parsed) {
      let response = null;
      response = await endpoint.alerts(parsed)
      return response;
    },

    async request_goal(parsed) {
      let response = null;
      response = await endpoint.goal(parsed)
      return response;
    },

    async request_last_reading_date(parsed) {
      let response = null;
      response = await endpoint.last_reading_date({
        unit_id: parsed.unit_id,
      })
      return response;
    },

    async request_resume_meta_estimate(parsed) {
      let response = null;
      response = await endpoint.resume_meta_estimate({
        unit_id: parsed.unit_id,
      })
      return response;
    },

    async request_average_before_foz(parsed) {
      let response = null;
      response = await endpoint.average_before_foz({
        unit_id: parsed.unit_id,
      })
      return response;
    },

    async request_calls_finished(params) {
      let response = null;
      response = await endpoint.calls_finished(params)
      return response;
    },

    async request_daily_consumption(parsed) {

      let start_date = await this.getNextReading(parsed)
      let end_date = start_date ? Utils.add_second(Utils.getMidnight('now'), -1) : null

      if (!start_date) {
        this.request.reading = null;
        return;
      }
      let params = {
        // type: 'unit',
        unit_id: parsed.id,
        start_date: start_date.toISOString(),
        end_date: end_date.toISOString(),
        forecast_days: 30,
      }

      let response = null;
      response = await endpoint.daily_consumption(params)
      return response;
    },

    async getNextReading(parsed) {
      let type = parsed.type;
      if (type !== 'unit') {
        this.request.historic = null
      }
      let response = await endpoint.historic({
        id: parsed.id,
      })
      let historic = response.status ? response.unit.historicos : []
      let dates = historic.map(h => Utils.instanceDate(`${h.month}-${h.day}`))
      dates = dates.sort((datePrevious, dateNext) => {
        if (datePrevious < dateNext) return -1;
        else if (datePrevious > dateNext) return 1;
        else return 0;
      });
      let lastDate = null
      if (dates.length > 0) lastDate = dates.pop()
      // Fazer a função para gerar o próximo dia
      let list_days = []
      if (lastDate) list_days = Utils.generate_list_monthly_by_day_fixed(lastDate, 12)
      let next_reading_date = null;
      let now = Utils.instanceDate('now')
      let now_month = Utils.getDateInfo(now, false)['month']
      // let previous_date = null
      if (list_days.length > 0) {
        for (let i = 1; i < list_days.length; i++) {
          let current_date = list_days[i]
          let diff = Utils.diff_days(now, current_date)
          if (diff < 1 || (diff > 28 && diff < 30)) {
            next_reading_date = Utils.add_day(Utils.getMidnight(now), -7)
            break;
          } else if (diff >= 1 && diff <= 28) {
            next_reading_date = current_date
            break;
          }
        }
      }
      return next_reading_date;
    },


    async cycle_consumption(parsed) {
      return new Promise(
        async (resolve, reject) => {
          try {
            if (!this.is_cycling) return resolve(false)
            this.set_loading_display('consumption', true)
            this.reset_display_consumption();
            this.request.consumption = await this.request_consumption(parsed)
            this.set_loading_display('consumption', false)
            this.set_display_data('consumption', {
              labels: this.request.consumption.labels,
              datasets: this.request.consumption.graphic,
              average: this.request.consumption.averageConsumption,
            })
            return resolve(true)
          } catch (error) {
            console.error(error)
            reject(error)
          }
        }
      )
    },

    async cycle_economy(parsed) {
      return new Promise(
        async (resolve, reject) => {
          try {
            if (!this.is_cycling) return resolve(false)
            this.set_loading_display('economy', true)
            this.reset_display_economy();
            await Utils.sleep(1500)
            this.set_loading_display('economy', false)
            this.set_display_data('economy', {
              consumption: this.request.consumption.consumption,
              valueCurrency: this.request.consumption.valueCurrency,
              savingCurrency: this.request.consumption.savingCurrency,
              savingConsumption: this.request.consumption.savingConsumption,
            })
            return resolve(true)
          } catch (error) {
            console.error(error)
            reject(error)
          }
        }
      )
    },

    async cycle_call(parsed) {
      return new Promise(
        async (resolve, reject) => {
          try {
            if (!this.is_cycling) return resolve(false)
            this.set_loading_display('call', true)
            this.reset_display_call();
            this.request.call = await this.request_call(parsed)
            this.set_loading_display('call', false)
            this.set_display_data('call', {
              data: this.request.call.data,
              labels: this.request.call.labels,
              total: this.request.call.total,
            })
            return resolve(true)
          } catch (error) {
            console.error(error)
            reject(error)
          }
        }
      )
    },

    async cycle_alert(parsed) {
      return new Promise(
        async (resolve, reject) => {
          try {
            if (!this.is_cycling) return resolve(false)
            this.set_loading_display('alert', true)
            this.reset_display_alert();
            this.request.alert = await this.request_alert(parsed)
            this.set_loading_display('alert', false)
            this.set_display_data('alert', {
              data: this.request.alert.data,
              labels: this.request.alert.labels,
              total: this.request.alert.total,
            })
            return resolve(true)
          } catch (error) {
            console.error(error)
            reject(error)
          }
        }
      )
    },

    // Problema
    async cycle_goal(parsed) {
      return new Promise(
        async (resolve, reject) => {
          try {
            if (!this.is_cycling) return resolve(false)
            this.reset_display_goal();
            this.set_loading_display('goal', true)

            if (parsed.type == "client") {
              let request = await this.request_calls_finished({
                id: parsed.id,
                type: parsed.type,
              })
              if (!request.status) return reject(request.message)
              let total_calls_finished = request.response.total_calls_finished

              this.set_display_data('goal', {
                total_calls_finished: total_calls_finished,
              })
            } else if (parsed.type == "unit") {
              let request = await this.request_last_reading_date(parsed)
              if (!request.status) return reject(request.message)
              let { last_reading_date } = request.response

              request = await this.request_resume_meta_estimate(parsed)
              if (!request.status) return reject(request.message)
              let { next_reading_date, total, day_ahead } = request.response
              let meta_estimate = parseFloat(((total.supplier + total.well + total.sewer) / 1000).toFixed(2))

              request = await this.request_average_before_foz(parsed)
              if (!request.status) return reject(request.message)
              let { consumption_reference, economy_reference } = request.response
              let economy_estimate = 0
              if (consumption_reference > 0) economy_estimate = ((consumption_reference - meta_estimate) / consumption_reference) * 100
              if (economy_estimate <= 0) economy_estimate = 0

              request = await this.request_calls_finished({
                id: parsed.id,
                type: parsed.type,
              })
              if (!request.status) return reject(request.message)
              let total_calls_finished = request.response.total_calls_finished

              this.set_display_data('goal', {
                last_reading_date: Utils.instanceDate(last_reading_date),
                next_reading_date: Utils.instanceDate(next_reading_date),
                end_reading_date: Utils.instanceDate(day_ahead),
                meta_estimate,
                economy_estimate: 0,
                total_calls_finished: total_calls_finished,
              })
            }

            this.set_loading_display('goal', false)

            return resolve(true)
          } catch (error) {
            console.error(error)
            reject(error)
          }
        }
      )
    },

    async cycle_progress_bar() {
      return new Promise(
        async (resolve, reject) => {
          try {
            if (!this.is_cycling) return;
            await Utils.waiting(
              () => {
                this.start_progress_bar(10000)
              },
              () => {
                return this.display.percentage >= 100;
              },
              () => {
                resolve(true)
              }
            )
            this.openSelct = false
          } catch (error) {
            console.error(error)
            reject(error)
          }
        }
      )
    },

    async cycle_start() {
      return new Promise(
        async (resolve, reject) => {
          try {
            this.parsed = null
            this.is_cycling = true;
            this.display.percentage = 0;
            this.set_loading_display('consumption', false)
            this.set_loading_display('economy', false)
            this.set_loading_display('call', false)
            this.set_loading_display('alert', false)
            this.set_loading_display('goal', false)
            this.clear_progress_bar();
            this.reset_progress_bar();
            this.parsed = this.get_next_unit();
            this.has_meta = this.parsed.type == 'unit' && this.parsed.unit_id != 'selecionar_todos' ? true : false;

            await Utils.sleep(500);

            // Setar o nome da unidade
            this.set_unit_name(this.parsed)
            return resolve(true)
          } catch (error) {
            console.error(error)
            reject(error)
          }
        }
      )
    },

    async cycle_end() {
      return new Promise(
        async (resolve, reject) => {
          try {
            await Utils.sleep(500);
            this.is_cycling = false;
            return resolve(true)
          } catch (error) {
            console.error(error)
            reject(error)
          }
        }
      )
    },

    async cycle() {
      // try {
      if (
        !(await this.cycle_start()) ||
        !(await this.cycle_consumption(this.parsed)) ||
        !(await this.cycle_economy(this.parsed)) ||
        !(await this.cycle_call(this.parsed)) ||
        !(await this.cycle_alert(this.parsed)) ||
        !(await this.cycle_goal(this.parsed)) ||
        !(await this.cycle_progress_bar(this.parsed)) ||
        !(await this.cycle_end())
      ) return false
      return true
      // } catch (error) {
      //   console.error(error)
      // }           
    },

    async execute() {
      if (this.is_executing) return;
      this.is_executing = true;
      while (this.is_executing) {
        await this.cycle();
      }
    },

    close() {
      Utils.waitingCondition(
        () => {
          this.is_cycling = false;
          this.is_executing = false;
        },
        () => {
          let status = (
            !this.get_loading_display('consumption') &&
            !this.get_loading_display('economy') &&
            !this.get_loading_display('call') &&
            !this.get_loading_display('alert') &&
            !this.get_loading_display('goal')
          )
          return status
        }
      )
    },

    start_progress_bar(totalTime = 15000) {
      if (this.progress_bar_fn !== null) return;
      this.reset_progress_bar()
      let timeperc = Math.ceil(totalTime / 100)
      this.progress_bar_fn = setInterval(
        () => {
          this.display.percentage += 1;
          if (this.display.percentage >= 100) {
            this.clear_progress_bar()
          }
        }, timeperc
      )
    },
    reset_progress_bar() {
      this.display.percentage = 0;
    },
    clear_progress_bar() {
      if (this.progress_bar_fn === null) return;
      clearInterval(this.progress_bar_fn)
      this.progress_bar_fn = null;
    },
  },
  async created() {
    this.reset_display_unit();
    this.units = (await this.getUnitsBy({
      by: this.getCurrentUserRank.toLowerCase(),
      id: this.getCurrentUserID,
    })).map(u => ({
      id: u.data.id,
      name: u.data.name,
    }));
    this.units_id_allowed_current_user = this.units.map(u => u.id)
    this.unit_ids = this.units.map(u => u.id)
    this.current_index_id = 0;
    if (this.units.length > 0) {
      Utils.waitingCondition(
        () => {
          this.close();
        },
        () => {
          return !this.is_cycling
        },
        () => {
          this.execute();
        },
      )
    }
  },
  async beforeDestroy() {
    this.close();
  },
};