import firebase from '@/firebase';
import UsersService from "@/services/firebase/Users/UsersService";
import UsersServiceServ from "@/services/server/UsersService";

import Utils from '@/scripts/Utils';
import PermissionsType from '@/utils/Permissions';
import Vuex from 'vuex';
import WaterAuthotity from './functions/WaterAuthotity';
import Waterfix from './functions/Waterfix';
import PublishSubscribePattern from './functions/PublishSubscribePattern.js';

import VuexPersist from './VuexPersist.js'


const getDefaultState = () => {
  return {
    optionsSnackBar: {
      open: false,
      text: '',
      severity: 'info',
    },
    optionsDialogAlert: {
      open: false,
      title: '',
      text: '',
      severity: 'info',
      runFunc: () => {},
      closeFunc: () => {},
    },
    paramDialogDate: {
      show: false,
      title: "Selecione uma data",
      min: null,
      max: null,
      input: null,
      type: 'date',
      change: () => { },
    },
    waterFixItemsToImport: [],
    waterFixOpenStatus: 'view',
    // ---------------------------------------------------
    // Usuário logado

    loggedIn: false,

    currentUser: undefined,

    // ---------------------------------------------------
    // .

    modems: [],


    isFirstLoadedModems: false,

    // ---------------------------------------------------
    // Usuários

    users: [],

    managers: [],

    associados: [],

    clients: [],

    superAdmins: [],

    admins: [],

    franqueados: [],

    isLoadingUsers: false,

    isFirstLoadedUsers: false,



    // ---------------------------------------------------
    // Unidades

    units: [],

    mapUnitToIndex: {},
    mapModemToUnit: {},

    isLoadingUnits: false,

    isFirstLoadedUnits: false,

    // ---------------------------------------------------
    // Consumo diário

    dailyConsumption: {},

    isLoadingDailyConsumption: false,

    isFirstLoadedDailyConsumption: false,

    // ---------------------------------------------------
    // Configurações

    ranks: {},

    // ---------------------------------------------------
    // Tempo de ultimos update para ajudar atualizar novamente

    _timeUpdate: {},

    permissions: {},

    actions: {
      dashboard: {
      },
      units: {
        newUnit: ['super_admin', 'admin'],
        editUnit: ['super_admin', 'admin'],
        deleteUnit: ['super_admin'],
      },
      users: {
        newUser: ['super_admin', 'admin'],
        editUser: ['super_admin', 'admin'],
        deleteUser: ['super_admin', 'admin'],
      },
      goals_forecasts: {
      },
    },

    isFirstLoadedConfigs: false,

    // ---------------------------------------------------
    // Atualizações

    nextUpdateUsers: 0,
    nextUpdateUnits: 0,
    nextUpdateModems: 0,

    // ---------------------------------------------------
    // Alertas
    alerts: [],

    isLoadingAlerts: false,

    // ---------------------------------------------------
    // Concessionárias
    waterAuthorities: {
      list: [],
      // companies: [],
      rankByCompany: {},
      stats: {},
      isLoading: false,
      isLoaded: false,
      isFull: false,
    },

    ...Waterfix.state,
    ...PublishSubscribePattern.state,

  }
};

export default new Vuex.Store({

  plugins: [VuexPersist.plugin],
  state: getDefaultState(),

  mutations: {
    OPEN_SNACKBAR(state, payload) {
      state.optionsSnackBar = { ...payload, open: !state.optionsSnackBar.open }
    },
    OPEN_DIALOGALERT(state, payload) {
      state.optionsDialogAlert = { ...payload, open: !state.optionsDialogAlert.open }
    },

    SET_PAGE_STATUS(state, status) {
      if (status == 'page_not_found') {
        state.isPageNotFound = true;
        state.isPageForbidden = false;
      } else if (status == 'forbidden') {
        state.isPageNotFound = false;
        state.isPageForbidden = true;
      } else if (status == 'ok') {
        state.isPageNotFound = false;
        state.isPageForbidden = false;
      }
    },

    CLEAR_STATE(state) {
      let defaultState = getDefaultState();
      Object.keys(defaultState).forEach(key => state[key] = defaultState[key]);
    },

    LOGIN(state, payload) {
      state.loggedIn = true;
      state.currentUser = payload
    },

    LOGGED_OUT(state) {
      state.loggedIn = false
      state.currentUser = null
    },

    /*
    * Setar valors que são de chaves genéricas sem precisar de nenhuma
    * lógica de filtragem ou mapeamento por exemplo
    */
    SET_VALUE(state, payload) {
      Object.keys(payload).forEach(key => {
        if (!state.hasOwnProperty(key)) state[key] = null;
        state[key] = payload[key]
      });
    },

    SET_STATE(state, payload) {
      Object.keys(payload).forEach(
        (keysString) => {
          let keys = keysString.split('.')
          let lastKey = keys.pop();
          let ref = state;
          keys.forEach(
            (key) => {
              ref = ref[key];
            }
          )
          ref[lastKey] = payload[keysString]
        }
      )
    }

  },

  actions: {
    ...Waterfix.actions,
    ...PublishSubscribePattern.actions,

    logout({
      commit
    }) {
      commit("LOGGED_OUT");
      commit("CLEAR_STATE");
    },

    snackBar({ commit }, payload) {
      commit("OPEN_SNACKBAR", payload)
    },
    dialogAlert({ commit }, payload) {
      commit("OPEN_DIALOGALERT", payload)
    },
    setDialogDate({ state, commit }, payload) {
      return new Promise(
        async (resolve, reject) => {
          try {
            commit('SET_VALUE', {
              paramDialogDate: {
                ...state.paramDialogDate,
                ...payload,
              }
            })
            resolve(true)
          } catch (error) {
            reject(error)
          }
        }
      );
    },
    openDialogDate({ dispatch }, payload) {
      return dispatch('setDialogDate', {
        ...payload,
        show: true,
      })
    },
    resetDialogDate({ dispatch }) {
      return dispatch('setDialogDate', {
        show: false,
        title: "Selecione uma data",
        min: null,
        max: null,
        input: null,
        type: 'date',
        change: () => { },
      })
    },
    closeDialogDate({ dispatch }) {
      return dispatch('setDialogDate', {
        show: false
      })
    },
    setInputDialogDate({ dispatch }, date) {
      return dispatch('setDialogDate', {
        input: date
      })
    },


    /*
    * Deletar um usuário
    */
    deleteUser({ commit, state }, user) {
      return new Promise(
        async (resolve, reject) => {
          try {
            let userID = typeof user === 'object' ? user.id : user;
            let userTarget = state.users.find(u => u.id === userID);

            switch (userTarget.data.rank.toUpperCase()) {
              case 'SUPER ADMIN':
              case 'ADMIN':
              case 'FRANQUEADO':
                if (state.clients.find(c => c.data.manager.id === userTarget.id)) throw new Error(`User has management dependency`);
                break;

              case 'CLIENTE':
                if (state.associados.find(a => a.data.client.id === userTarget.id)) throw new Error(`Client has associate dependency`);
                break;
            }
            // Deveria ser um software delete
            await firebase.deleteUser(userTarget.id);

            let users = state.users;
            users = users.filter(u => u.id !== userTarget.id);
            resolve(true);

          } catch (e) {
            reject(e);
          }
        }
      );
    },

    async loginUser({ state, commit }, { email, password }) {
          try {
            await firebase.auth.setPersistence(firebase.auth_class.Auth.Persistence.LOCAL)
            await firebase.auth.signInWithEmailAndPassword(email, password)

            let currentUser = await firebase.getCurrentUser()
            let currentUserNew = await UsersService.getCurrentUser()

            const permissions = await UsersServiceServ.findAllPermissions()

            let userToSave = {
              currentUser: {
                id: currentUserNew.id,
                name: currentUserNew.name,
                email: currentUserNew.email,
                permissions: permissions.data.data
              },
              data: {
                email: currentUser.data.email,
                metadata: currentUser.data.metadata,
                name: currentUser.data.name,
                rank: Utils.getStringRank(currentUser.data.rank),
              },
              uid: currentUser.uid
            }
            commit("LOGIN", userToSave);
            commit("SET_VALUE", { isFirstLoadedConfigs: true })
            return 
          } catch (e) {
            console.error(e)
            throw new Error(e.code)
          }
    },

    getUnitsByUser({ state, getters }, userObject) {
      return new Promise(
        (resolve) => {
          let listUnits = [];
          if (!userObject) return resolve(listUnits);
          if (userObject.hasOwnProperty('data')) userObject = userObject.data;
          if (!userObject.rank) return resolve(listUnits);
          switch (state.currentUser.data.rank.toLowerCase()) {
            case 'super admin':
            case 'admin':
            case 'franqueado':
            case 'cliente':
              listUnits = state.units.filter(u => u.data.user === userObject.id);
              break;
            case 'associado':
              let associado = state.associados.find(a => a.id === state.currentUser.uid);
              associado = associado.data ? associado.data : associado;
              if (associado) {
                let allowedIDUnits = associado.unitsAllowedByClient.map(u => typeof u === 'object' ? u.id : u);
                listUnits = state.units.filter(u => allowedIDUnits.includes(u.id)).map(u => u.data ? u.data : u);
              }
              break;
          }
          return resolve(listUnits.map(u => u.hasOwnProperty('data') ? u.data : u));
        }
      );
    },

    async getWaterAuthority({ state, commit }, company = null) {
      let list = state.waterAuthorities.list;
      let stats = state.waterAuthorities.stats;
      let result = [];
      commit('SET_STATE', {
        'waterAuthorities.isLoading': true,
      })
      let listRequest = [];
      if (company === null)
        listRequest = await WaterAuthotity.requestAll()
      else
        listRequest = await WaterAuthotity.requestByCompany(company)
      let newStats = WaterAuthotity.createStats(listRequest);
      stats = {
        ...stats,
        ...newStats
      }
      commit('SET_STATE', {
        'waterAuthorities.isLoading': false,
      })
      return listRequest;
    },

    getClientsBy({ state }, seachObject = null) {

      return new Promise(
        (resolve) => {
          let listClients = [];
          let by = null
          let id = null
          if (seachObject) {
            if (seachObject.hasOwnProperty('by')) by = seachObject.by
            if (seachObject.hasOwnProperty('id')) id = seachObject.id
          }

          if (by === null || by == 'super admin' || by == 'admin') listClients = state.clients
          else if (by == 'franqueado') {
            if (!id) throw new Error("É obrigatório o ID do franqueado para buscar os clientes")
            if (state.currentUser.data.rank === 'Franqueado') {
              listClients = state.clients.filter(
                (client) => {
                  return client.data.manager == id
                }
              )
            } else {
              listClients = state.clients.filter(
                (client) => {
                  return client.data.manager.id == id
                }
              )
            }
          } else if (by == 'associado') {
            listClients = state.clients.filter((client) => ((client.data.rank == 5) && client.data.client == id))
            return resolve(listClients)
          } else if (by == 'cliente') {
            listClients = state.clients.filter((client) => client.data.id == id)
            return resolve(listClients)
          } else {
            throw new Error("Não foi identificado a referência de qual rank para buscar os clientes")
          }
          return resolve(listClients)
        }
      );

    },

    getUnitsBy({ state, dispatch }, seachObject = null) {

      return new Promise(
        (resolve) => {
          Utils.waitingTrigger(
            () => {
              return state.isFirstLoadedUnits;
            },
            async () => {
              let listUnits = [];
              let by = null
              let id = null
              let name = null
              if (seachObject) {
                if (seachObject.hasOwnProperty('by')) by = seachObject.by.toLowerCase()
                if (seachObject.hasOwnProperty('id')) id = seachObject.id
                if (seachObject.hasOwnProperty('name')) name = seachObject.name.toLowerCase()
              }

              if (by === null && name === null) {
                listUnits = state.units
              }
              else if (by === 'franqueado') {
                if (!id) throw new Error("É preciso indicar o id do franqueado")
                let list_users = (await dispatch('getClientsBy', {
                  by: 'franqueado',
                  id: id,
                })).map(
                  (u) => {
                    let id = u.hasOwnProperty('data') ? u.data.id : u.id;
                    return id;
                  }
                )
                listUnits = state.units.filter(
                  (unit) => {
                    return list_users.includes(unit.data.user)
                  }
                );
                return resolve(listUnits)
              }
              else if (by === 'unit' && id !== null) {
                listUnits = state.units.filter(
                  (unit) => {
                    return unit.data.id === id
                  }
                );
                if (listUnits.length > 0) {
                  return resolve(listUnits[0])
                } else {
                  return resolve(null)
                }
              }
              else if (name !== null) {
                listUnits = state.units.filter(
                  (unit) => {
                    return unit.data.name === name
                  }
                );
              }
              else if (by === 'cliente') {
                if (!id) throw new Error("É obrigatório o ID do cliente para buscar as unidades")
                listUnits = state.units.filter(
                  (unit) => {
                    return unit.data.user === id
                  }
                );
              }
              else if (by === 'admin' || by === 'super admin') {
                listUnits = state.units
              } else if (by === 'associado') {
                let users = state.users.filter(
                  (user) => {
                    let user_id = user.hasOwnProperty('data') ? user.data.id : user.id;
                    return user_id === id;
                  }
                )
                let user = users[0]
                let unitsAllowedByClient = user.hasOwnProperty('data') ? user.data.unitsAllowedByClient : user.unitsAllowedByClient;
                listUnits = state.units.filter(
                  (unit) => {
                    return unitsAllowedByClient.includes(unit.data.id)
                  }
                );
              } else {
                throw new Error("Não foi identificado a referência de qual rank para buscar as unidades")
              }
              return resolve(listUnits)
            },
            () => {
              return resolve([])
            }
          );
        }
      );

    },

    getUsersBy({ state, dispatch }, seachObject = null) {

      return new Promise(
        (resolve) => {
          Utils.waitingTrigger(
            () => {
              return state.isFirstLoadedUsers;
            },

            async () => {
              let listUsers = [];
              let by = null // franqueado / cliente / associado / super admin / admin
              let ref = null // franqueado / cliente / associado / super admin / admin
              let ref_id = null // id do usuário que está sendo usado como referencia
              let search = null;
              let search_id = null;
              if (seachObject) {
                if (seachObject.hasOwnProperty('by')) by = seachObject.by.toLowerCase();
                // if (seachObject.hasOwnProperty('id')) id = seachObject.id
                if (seachObject.hasOwnProperty('ref')) ref = seachObject.ref.toLowerCase();
                if (seachObject.hasOwnProperty('ref_id')) ref_id = seachObject.ref_id
                if (seachObject.hasOwnProperty('search')) search = seachObject.search.toLowerCase();
                if (seachObject.hasOwnProperty('search_id')) search_id = seachObject.search_id;
              }

              if (ref && !ref_id) {
                throw new Error("A referênciado precisa do id")
              } else if (!ref && ref_id) {
                throw new Error("O ID da referência precisa está com o rank definido na referência")
              }

              let clientIDs = []
              let managerIDs = []

              switch (by) {
                case 'cliente':
                  switch (ref) {
                    case 'super admin':
                    case 'admin':
                      listUsers = state.clients;
                      break;

                    case 'franqueado':
                      listUsers = state.clients.filter(
                        c => {
                          return c.data.manager.id === ref_id
                        }
                      )
                      break;

                    case 'cliente':
                      listUsers = state.clients.filter(c => c.data.id === ref_id);
                      break;

                    case 'associado':
                      clientIDs = state.associados.filter(c => c.id === ref_id).map(c => c.data.client.id);
                      listUsers = state.clients.filter(c => clientIDs.includes(c.id))
                      break;

                    default:
                      listUsers = state.clients;
                  }
                  break;

                case 'franqueado':
                  switch (ref) {
                    case 'super admin':
                    case 'admin':
                      listUsers = state.franqueados;
                      break;

                    case 'franqueado':
                      listUsers = state.franqueados.filter(c => c.data.id === ref_id);
                      break;

                    case 'cliente':
                      managerIDs = state.clients.filter(c => c.data.id === ref_id).map(c => c.data.manager.id)
                      listUsers = state.franqueados.filter(c => managerIDs.includes(c.data.id));
                      break;

                    case 'associado':
                      clientIDs = state.associados.filter(c => c.id === ref_id).map(c => c.data.client.id);
                      managerIDs = state.clients.filter(c => clientIDs.includes(c.data.id)).map(c => c.data.manager.id)
                      listUsers = state.franqueados.filter(c => managerIDs.includes(c.data.id));
                      break;

                    default:
                      listUsers = state.franqueados;
                  }
                  break;
              }


              if (search && !search_id) {
                throw new Error("A busca precisa do id")
              } else if (!search && search_id) {
                throw new Error("O ID da busca precisa está com o rank definido na busca")
              }

              // O filtro de busca para os dados de usuários selecionados
              switch (by) {
                case 'cliente':
                  switch (search) {
                    case 'franqueado':
                      listUsers = listUsers.filter(u => u.data.hasOwnProperty('manager')).filter(u => u.data.manager.id === search_id)
                      break;
                  }
                  break;
              }

              // if (id) {
              //   listUsers = listUsers.filter(u => u.data.id === id)
              // }
              return resolve(listUsers)
            },

            () => {
              return resolve([])
            }
          );
        }
      );

    },

    setWaterFixItemsToImport({ commit }, items) {
      commit('SET_VALUE', {
        waterFixItemsToImport: items
      })
    },

    setWaterFixOpenStatus({ commit }, step) {
      commit('SET_VALUE', {
        waterFixOpenStatus: step
      })
    },

  },

  getters: {
    optionsSnackBar: (state) => {
      return state.optionsSnackBar
    },
    optionsDialogAlert: (state) => {
      return state.optionsDialogAlert
    },
    paramDialogDate: (state) => {
      return state.paramDialogDate
    },
    get_value: (state) => {
      return (key_path) => {
        let keys = key_path.split('.')
        let last_key = keys.pop();
        let ref = state;
        keys.forEach(
          (key) => {
            ref = ref[key];
          }
        )
        return ref[last_key];
      }
    },
    ...Waterfix.getters,
    ...PublishSubscribePattern.getters,
    isLoadingWaterAuthority: (state) => state.waterAuthorities.isLoading,
    state: state => state,
    newUser: state => obj => createUser(obj, state),
    isLoadingAlerts: state => state.isLoadingAlerts,
    isLoggedIn: state => state.loggedIn,
    getCurrentUser: state => state.currentUser,
    getCurrentUserData: state => state.currentUser.currentUser,
    getCurrentUserName: state => state.currentUser ? state.currentUser.data.name : undefined,
    getCurrentUserRank: state => state.currentUser ? state.currentUser.data.rank : undefined,
    getStatusCanDeletedDataAdmin: state => state.currentUser ? state.currentUser.data.canDeletedData : undefined,
    getCurrentUserID: state => state.currentUser.uid,
    isLoadingUsers: state => state.isLoadingUsers,
    isFirstLoadedUsers: state => state.isFirstLoadedUsers,
    getUsers: state => state.users,
    getManagers: state => state.managers,
    getFranqueados: state => state.franqueados,
    getClients: state => {
      if (!state.currentUser) return [];
      switch (state.currentUser.data.rank.toUpperCase()) {
        case 'SUPER ADMIN':
        case 'ADMIN':
          return state.clients;
        case 'FRANQUEADO':
          return state.clients.filter(c => c.data.manager === state.currentUser.uid);
        case 'CLIENTE':
          return state.clients.filter(c => c.id === state.currentUser.uid);
        case 'ASSOCIADO':
          return state.associados.filter(c => c.id === state.currentUser.uid).map(c => ({ data: c.data.client, id: c.data.client.id }));
      }
    },
    getRank: state => name => state.ranks[Utils.createSlug(name)],
    isLoadingUnits: state => state.isLoadingUnits,
    isFirstLoadedUnits: state => state.isFirstLoadedUnits,
    getUnitsByClient: state => userObject => {
      if (!userObject) return [];
      return userObject.data.dataList;
    },
    isFirstLoadedModems: state => state.isFirstLoadedModems,
    isLoadingDailyConsumption: state => state.isLoadingDailyConsumption,
    isFirstLoadedDailyConsumption: state => state.isFirstLoadedDailyConsumption,
    isPageNotFound: state => state.isPageNotFound,
    isPageForbidden: state => state.isPageForbidden,
    hasPermission: state => (name, rank) => {
      let slugName = Utils.createSlug(name);
      let slugRank = Utils.createSlug(rank);
      if (!state.permissions.hasOwnProperty(slugName)) throw new Error(`Não foi encontrado a permissão '${name}'`);
      if (!state.permissions[slugName].hasOwnProperty(slugRank)) throw new Error(`Não foi o tipo da permissão para o rank '${rank}'`);
      return state.permissions[slugName][slugRank];
    },
    isFirstLoadedConfigs: state => state.isFirstLoadedConfigs,
    getUnitByIDs: state => unitIDArray => {
      let listUnits = []
      if (unitIDArray) listUnits = state.units.filter(u => unitIDArray.includes(u.id)).map(u => u.data ? u.data : u);
      return listUnits;
    },
    getUnitsByUser: state => userObject => {
      let listUnits = []
      if (userObject) listUnits = state.units.filter(u => u.data.user === userObject.id);
      return listUnits;
    },
    /*
    * 'route' é a rota/tela,
    * 'action' é a ação a ser executada pelos rank possíveis,
    */
    hasAction: state => (route, action) => {
      if (!state.actions.hasOwnProperty(route)) return false;
      if (!state.actions[route].hasOwnProperty(action)) return false;
      let slugRank = Utils.createSlug(state.currentUser.data.rank);
      return state.actions[route][action].includes(slugRank);
    },
    waterFixItemsToImport: state => state.waterFixItemsToImport,
    waterFixOpenStatus: state => state.waterFixOpenStatus,
  }
})
