import Utils from '@/scripts/Utils';
import ConsumptionApi from '@/services/apiPdf/ConsumptionApi.js';
import UsersService from "@/services/server/UsersService";
import UnitsService from "@/services/server/UnitsService";
import ChartConsumption from "@/components/charts/ChartsConsumption/Consumption.vue";
import ConsumptionFunctions from "@/pages/Consumption/ConsumptionFunctions.js"
import SectorsService from "@/services/firebase/Sectors/SectorsService";
import UnitsFbService from "@/services/firebase/Units/UnitsService";
import MdensService from "@/services/firebase/Modens/MdensService";

var { DateTime } = require("luxon");

const PROJECT_START_YEAR = '2020'

import { generateXLSX } from "./ReportEconomyXLSX";

export default {

  name: "Consumption",

  components: {
    ChartConsumption,
  },


  data() {
    return {
      selectsLoading: {
        client: false,
        unit: false,
        sector: false
      },
      allowPrinting: false,
      monthListIndex: [],
      isDataGraphic: false,
      menuDateStart: false,
      menuDateEnd: false,
      dateSelectedEnd: '',
      data_abstract_client: {
        name: "Selecione um cliente",
        consumptionType: "-/-",
        registry: "-",
        address: "-",
        last_updated: "-"
      },
      readings_table_table1: [],
      readings_table_table2: [],
      loadButtonPdf: false,
      listClients: [],
      selectedClient: null,
      listUnits: [],
      selectedUnit: null,
      listSectors: [],
      selectedSector: null,
      readings: [],
      optionMetric: true,
      switch1: "Metros Cúbicos (m³)",
      validDayPeriodSelected: true,
      validHourPeriodSelected: true,
      validMonthPeriodSelected: true,
      consultReading: false,
      optionsToDisplay: [{ label: "Dia", value: "day" }],
      optionToDisplaySelected: "day",
      totalEstimatedConsumption: [],
      dateFormattedSelectedStart: '',
      dateFormattedSelectedEnd: '',
      dateSelectedFormatted:  Utils.formatDate(new Date(), "<dd>/<MM>/<YYYY>"),
      dateSelectedStart: '',
      dateSelected: Utils.formatDate(new Date(), "<YYYY>-<MM>-<dd>"),
      menuDate: false,
      byMonth: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
      byYear: Utils.generateYearsByInterval(PROJECT_START_YEAR, 'now').map((date) => Utils.getDateInfo(date, false)['year']),
      monthSelected: '',
      yearSelected: '',
      optionPeriod: '',
      consultGraph: false,
      consultTable: false,
      graphicFlag: false,
      isDataTable: false,
      headers_tabledays: [
        { text: 'Data', align: 'center', sortable: false, value: 'date' },
        { text: 'Medição', value: 'value', align: 'center', },
      ],
      readings_table: [],
      monthListIndex: {
        Janeiro: { month: 1 },
        Fevereiro: { month: 2 },
        Março: { month: 3 },
        Abril: { month: 4 },
        Maio: { month: 5 },
        Junho: { month: 6 },
        Julho: { month: 7 },
        Agosto: { month: 8 },
        Setembro: { month: 9 },
        Outubro: { month: 10 },
        Novembro: { month: 11 },
        Dezembro: { month: 12 }
      },
      loadingReadings: false,
      dateOptions: {
        startDate: DateTime.utc()
          .setZone("America/Sao_Paulo")
          .startOf("day")
          .toSeconds(),
        endDate: DateTime.utc().setZone("America/Sao_Paulo").toSeconds(),
        mode: "day",
      },
      chartMenuItems: ["Últimos 7 dias", "Por mês", "Por dia", "Por hora", "Por intervalo"],
      dialog: false,
      showNextReading: false,
      nextDateFormated: '',
      resume: {},
      totalDates: 0,
      permittedStartDate: null,
      permittedEndDate: null,
    };
  },

  computed: {
    allFieldsReady() {
      return this.dateOptions.mode == 'day' ? this.totalDates <= 90 : this.dateOptions.mode == 'hour' ? this.totalDates <= 24 : this.dateOptions.mode == 'month' ? this.totalDates <= 12 : false;
    },
    isCubicMetersSelected() {
      return this.switch1 == 'Metros Cúbicos (m³)'
    },
    isLitersSelected() {
      return !this.isCubicMetersSelected
    },
    unitMeasurement() {
      return this.isCubicMetersSelected ? 'cubic_meters' : 'liters'
    },
  },

  methods: {
    async setNextReading() {
      this.nextDateFormated = ''
      this.showNextReading = false;
      if (!this.selectedUnit) return;
      let historicos = []
      const historics = await UnitsFbService.getSubCollectionHistorics(this.selectedUnit)

      if (historics.length <= 0) {
        this.nextDateFormated = 'Não há histórico'
        this.showNextReading = true;
        return;
      }
      historicos = historics.map(d => {
        let day = '01';
        if (d.hasOwnProperty('day')) day = d.day;
        return { ...d, day }
      })
      let dates = historicos.map(h => Utils.instanceDate(`${h.month}-${h.day}`))
      dates = dates.sort((datePrevious, dateNext) => {
        if (datePrevious < dateNext) return -1;
        else if (datePrevious > dateNext) return 1;
        else return 0;
      });
      let lastDate = dates.pop()
      this.nextDateFormated = `${Utils.formatDate(lastDate, '<DD>')}/${Utils.formatDate('now', '<MM>/<YYYY>')}`
      this.showNextReading = true;
    },
    onKeydownSector(key) {
      if (key.code === 'Backspace') {
        this.closeInfoData();
        this.selectedSector = null;
      }
    },
    onChangeSector(sector) {
      this.readings_table = []
      for (let key in this.selectedSector) {
        if (this.selectedSector[key] == "TODOS") {
          this.selectedSector = this.listSectors.filter((i) => { return i !== "TODOS" && i !== "DESMARCAR TODOS" })
        } else {
          if (this.selectedSector[key] == "DESMARCAR TODOS") {
            this.selectedSector = false
            break
          }
        }
      }
      this.isDataGraphic = false;
      this.isDataTable = false;
      this.allowPrinting = false;
    },
    onKeydownUnit(key) {
      if (key.code === 'Backspace') {
        this.closeInfoData();
        this.selectedUnit = null;
        this.selectedSector = null;
        this.listSectors = [];
      }
    },
    async onChangeUnit(idUnit) {
      this.listSectors = [];
      this.selectsLoading.sector = true
      this.listSectors = await SectorsService.getSectorsByUnitId(idUnit)
      this.closeInfoData();
      this.setNextReading();
      this.onChangeInfoUnit(idUnit)
      this.selectedSector = null;
      this.selectsLoading.sector = false
      if (this.listSectors.length === 1) this.selectedSector = this.listSectors[0];
      else if (this.listSectors.length > 1) {
        this.listSectors.unshift("TODOS")
        this.listSectors.push("DESMARCAR TODOS")
      }

    },
    onKeydownClient(key) {
      if (key.code === 'Backspace') {
        this.closeInfoData();
        this.selectedClient = null;
        this.selectedUnit = null;
        this.listUnits = [];
        this.selectedSector = null;
        this.listSectors = [];
      }
    },
    async onChangeClient(idClient) {
      this.selectsLoading.unit = true
      this.closeInfoData();
      this.selectedUnit = null;
      this.selectedSector = null;
      this.showNextReading = false;

      const response = await UnitsService.findAllByClient(idClient)
      this.listUnits = response.data.data
      this.selectsLoading.unit = false
      if (this.listUnits.length === 1) {
        this.selectedUnit = this.listUnits[0].id;
        this.onChangeUnit(this.selectedUnit);
      }
    },
    /**
     * Está função gera um estrutura para gerar o excel baseados nos
     * dados resumidos
     */
    async exportSheets() {
      let excelData = {
        unit_name: this.selectedUnit.name,
        sectors: []
      }
      let resumeValues = Object.values(this.resume)
      for (let i = 0; i < resumeValues.length; i++) {
        let resume = resumeValues[i]
        let modemName = resume['modemName']
        let rows = []
        rows.push(modemName)
        resume.readings.forEach(
          (reading) => {
            let row = [reading.label, reading.water_liters.toFixed(0), reading.water_cubic_meters.toFixed(3)]
            rows.push(row)
          }
        )
        let lastRow = ['Total', resume.total_liters.toFixed(0), resume.total_cubic_meters.toFixed(3)]
        rows.push(lastRow)
        excelData.sectors.push(rows)
      }
      await generateXLSX(excelData)
    },
    estimatedConsumption(total, estimated) {
      let sum = total + estimated
      return sum.toFixed(2)
    },
    closeInfoData() {
      this.data_abstract_client = {
        name: "Selecione um cliente",
        consumptionType: "-/-",
        registry: "-",
        address: "-",
        last_updated: "-"
      };
      this.isDataGraphic = false;
      this.isDataTable = false;
      this.allowPrinting = false;

    },
    async onChangeInfoUnit(idUnit) {
      const unitSelected = this.listUnits.find(({ id }) => id === idUnit)
      const modensByUnit = await MdensService.getModemByIdList(this.listSectors.map(sector => sector.id_modem))
      let date = null
      if(modensByUnit.length){
        const lastReadings = modensByUnit.map(({lastReadingDate}) => (lastReadingDate?.seconds? lastReadingDate.seconds * 1000 : 0))
        this.selectedUnit = unitSelected
        date = new Date(Math.max(...lastReadings))
      }
      this.data_abstract_client = {
        name: unitSelected.name,
        consumptionType: unitSelected.tarrifsTypes? unitSelected.tarrifsTypes.water.tariffType + " / "+ unitSelected.tarrifsTypes.sewer.tariffType: "- / -",
        registry: !unitSelected.matricula ? "-" : unitSelected.matricula,
        address: !unitSelected.address ? '-' : !unitSelected.address.logradouro ? '-' : unitSelected.address.logradouro,
        last_updated: date ? Utils.formatDate(date, "<dd>/<MM>/<YYYY> às <hh>:<mm>") : "-",
      };
    },
    async startConsultTable() {
      // Ativa flags que permite fazer a impressão e também flag de carregamento dos dados (vue loading)
      this.allowPrinting = true;
      this.loadingReadings = true;
      this.readings_table = []
      this.validDayPeriodSelected = true;
      this.validHourPeriodSelected = true;
      this.validMonthPeriodSelected = true;
      await this.generateAllConsumption(false)
      if (!this.allFieldsReady) {
        this.loadingReadings = false;
        return;
      }
      this.readings_table = ConsumptionFunctions.createDataTable(this.resume)
      if (this.isCubicMetersSelected) {
        this.headers_tabledays = [
          { text: 'Data', align: 'center', sortable: false, value: 'date' },
          { text: 'Medição (m³)', value: 'value', align: 'center', },
        ]
      } else {
        this.headers_tabledays = [
          { text: 'Data', align: 'center', sortable: false, value: 'date' },
          { text: 'Medição (L)', value: 'value', align: 'center', },
        ]
      }
      this.consultReading = false;
      this.isDataGraphic = false;        // Não exibe gráfico
      this.isDataTable = true;
      this.consultGraph = false;
      this.loadingReadings = false;
    },
    getResumeData(modemId, key) {
      if (!this.resume.hasOwnProperty(modemId)) throw new Error(`Não foi encontrado o modem '${modemId}' no resumo dos dados`)
      let resumeModem = this.resume[modemId]
      if (!resumeModem.hasOwnProperty(key)) throw new Error(`Não foi encontrado o dado '${key}' no resumo dos dados`)
      return resumeModem[key] 
    },
    generateModemIds() {
      let modems = []
      if (!Array.isArray(this.selectedSector)) {
        modems.push(this.selectedSector)
      } else {
        modems = this.selectedSector
      }
      let modemIds = modems.map((modem) => modem.id_modem)
      return modemIds
    },
    async generateAllConsumption(isToPredict = true) {
      this.resume = {}
      this.readings = []
      let modems = []
      if (!Array.isArray(this.selectedSector)) {
        modems.push(this.selectedSector)
      } else {
        modems = this.selectedSector
      }
      let startDate = null
      let endDate = null
      // Se período selecionado foi 'Últimos 7 dias'
      if (this.optionPeriod == 'Últimos 7 dias') { // ok
        this.dateOptions.startDate = Utils.add_day(Utils.getMidnightToday(), -7)
        this.dateOptions.endDate = Utils.getLastDateOfDay(Utils.getMidnightToday())
        this.dateOptions.mode = "day";
        // Convertendo para o intervalo correspondente
        startDate = this.dateOptions.startDate
        endDate = this.dateOptions.endDate
      } else if (this.optionPeriod == 'Por dia') { // ok
        // Trata o mês ao qual o usuário selecionou.
        this.yearSelected = parseInt(this.yearSelected);
        this.dateOptions.startDate = Utils.instanceDate(`${this.yearSelected}-${this.monthListIndex[this.monthSelected].month}`);
        startDate = this.dateOptions.startDate
        this.dateOptions.endDate = Utils.getLastDayOfMonth(startDate)
        this.dateOptions.mode = "day";
        //`${yearSelect}-`
        endDate = Utils.getLastDateOfDay(Utils.getLastDayOfMonth(startDate))
      } else if (this.optionPeriod == 'Por mês') { // ok
        // Trata o mês ao qual o usuário selecionou.
        var yearSelect = this.yearSelected;
        // Inicializando parâmetros de data
        this.dateOptions.startDate = Utils.instanceDate(`${yearSelect}-01-01 00:00:00`);
        this.dateOptions.endDate = Utils.instanceDate(`${yearSelect}-12-31 23:59:59`);
        this.dateOptions.mode = "month";
        startDate = this.dateOptions.startDate
        endDate = this.dateOptions.endDate
      } else if (this.optionPeriod == 'Por hora') { // ok
        // Quebra data selecionada para o usuário, afim de facilitar a construção do vetor com o intervalo do gráfico.
        var dateSelect = this.dateSelected.split('-');
        // Converte cada elemento do array para inteiro.       
        const year = parseInt(dateSelect[0]);
        const month = parseInt(dateSelect[1]);
        const day = parseInt(dateSelect[2]);
        // Inicializando parâmetros de data
        this.dateOptions.startDate = Utils.instanceDate(`${year}-${month}-${day} 00:00:00`);
        this.dateOptions.endDate = Utils.instanceDate(`${year}-${month}-${day} 23:59:59`);
        this.dateOptions.mode = "hour";
        startDate = this.dateOptions.startDate
        endDate = this.dateOptions.endDate
      } else if (this.optionPeriod == 'Por intervalo') { // ok
        // Quebra data selecionada para o usuário, afim de facilitar a construção do vetor com o intervalo do gráfico.
        var dateSelectStart = this.dateSelectedStart.split('-');
        // Converte cada elemento do array para inteiro.       
        dateSelectStart[0] = parseInt(dateSelectStart[0]);
        dateSelectStart[1] = parseInt(dateSelectStart[1]);
        dateSelectStart[2] = parseInt(dateSelectStart[2]);
        // Quebra data selecionada para o usuário, afim de facilitar a construção do vetor com o intervalo do gráfico.
        var dateSelectEnd = this.dateSelectedEnd.split('-');
        // Converte cada elemento do array para inteiro.       
        dateSelectEnd[0] = parseInt(dateSelectEnd[0]);
        dateSelectEnd[1] = parseInt(dateSelectEnd[1]);
        dateSelectEnd[2] = parseInt(dateSelectEnd[2]);
        this.dateOptions.startDate = Utils.instanceDate(`${dateSelectStart[0]}-${dateSelectStart[1]}-${dateSelectStart[2]} 00:00:00`);
        this.dateOptions.endDate = Utils.instanceDate(`${dateSelectEnd[0]}-${dateSelectEnd[1]}-${dateSelectEnd[2]} 23:59:59`);
        this.dateOptions.mode = this.optionToDisplaySelected;
        startDate = this.dateOptions.startDate
        endDate = this.dateOptions.endDate
      } else {
        throw new Error("Unidentified period")
      }
      let { permittedStartDate, permittedEndDate, resume, labels } = await ConsumptionFunctions.generateConsumption(
        startDate,
        endDate,
        this.dateOptions.mode,
        modems,
        this.unitMeasurement,
        isToPredict
      )
      this.permittedStartDate = permittedStartDate;
      this.permittedEndDate = permittedEndDate;
      this.totalDates = labels.length;
      this.resume = resume;
    },
    // Filtragem do consumo pelo Gráfico. Onde serão mostrados os consumos no gráfico: 
    //    por 7 dias     -> registros dos últimos 7 dias
    //    por dia        -> registros dos dias de um mês específico
    //    por hora       -> registros por hora de um dia específico
    //    por intervalo  -> registros por hora em intervalo definido
    async consultConsumptionGraphic() {
      // Ativa flags que permite fazer a impressão e também flag de carregamento dos dados (vue loading)
      this.isDataTable = false;
      this.allowPrinting = true;
      this.loadingReadings = true;
      this.validDayPeriodSelected = true;
      this.validHourPeriodSelected = true;
      this.validMonthPeriodSelected = true;
      await this.generateAllConsumption()
      if (!this.allFieldsReady) {
        this.loadingReadings = false;
        return;
      }
      this.readings = ConsumptionFunctions.createDataset(this.resume)
      this.consultReading = false;
      this.loadingReadings = false;
      this.consultGraph = false;        // Fecha modal de gráfico
      this.isDataGraphic = true;        // Exibe gráfico  
    },
    async startPdf() {
      try {
        this.loadButtonPdf = true
        let modemIds = this.generateModemIds()
        let unitId = this.selectedUnit.id
        let dateOptions = Utils.copyObject(this.dateOptions)
        let unitMeasurement = this.unitMeasurement
        const params = {
          unitId,
          modemIds,
          unitMeasurement,
          ...dateOptions
        }
        await ConsumptionApi.generateConsumptionPdf(this.data_abstract_client.name, params)
        this.loadButtonPdf = false
      } catch {
        this.loadButtonPdf = false
        alert("Ocorreu um erro não identificado, entre em contato com nossa equipe de suporte.")
      }
    },
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
  },

  watch: {
    optionMetric() {
      if (this.optionMetric == true) {
        this.switch1 = "Metros Cúbicos (m³)";
      } else {
        this.switch1 = "Litros (L)";
      }
    },
    dateSelectedEnd() {
      this.dateFormattedSelectedEnd = this.formatDate(this.dateSelectedEnd);
    },
    dateSelectedStart() {
      this.dateFormattedSelectedStart = this.formatDate(this.dateSelectedStart);
    },
    dateSelected() {
      this.dateSelectedFormatted = this.formatDate(this.dateSelected);
    },
  },
  async created() {
    this.selectsLoading.client = true
    const response = await UsersService.findAllClients()
    this.listClients = response.data.data
    this.selectsLoading.client = false
    if (this.listClients.length === 1) {
      this.selectedClient = this.listClients[0]
      this.onChangeClient(this.selectedClient.id)
    }
  },
};