<template>
    <div style="display: flex;">
        <v-autocomplete
            dense
            v-model="selected"
            :items="list"
            :label="label"
            item-text="data.name"
            item-value="data"
            outlined
            hide-details
            :loading="isLoading"
            :disabled="isDisabled"
        >
        </v-autocomplete>
        <div v-if="addClearButton" class="text-center" style="display: flex; justify-content: center;align-items: center;padding: 2px;">
            <v-btn
                small
                color="blue"
                dark
                style="height: 100%; margin-left: 5px;"
                @click="clear_option"
            >
            X
            </v-btn>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Utils from '@/scripts/Utils';
/**
 * Será emitido a lista de clientes que ao ser escolhido um franqueado
 */
export default {
    
    data: () => ({
        label: "Clientes",

        list: [],
        // associated_items: [],
        selected: null,

        by: null,
        id: null,
        isLoadingData: false,
    }),

    props: {
        disabled: {
            default: false,
            type: Boolean,
        },
        reset: {
            default: false,
            type: Boolean,
        },
        loading: {
            default: false,
            type: Boolean,
        },
        franchiseeId: {
            default: null,
            type: String,
        },
        addClearButton: {
            default: false,
            type: Boolean,
        },
        defaultAllClient: {
            default: false,
            type: Boolean,
        }
    },

    computed: {
        ...mapGetters([
            "isFirstLoadedUsers",
            "getCurrentUserRank",
            "getCurrentUserID",
        ]),
        isLoading() {
            return !this.isFirstLoadedUsers || this.isLoadingData || this.loading
        },
        isDisabled() {
            return this.disabled || ( this.list.length > 0 ? false : true )
        }
    },

    methods: {
        ...mapActions([
            "getClientsBy",
            "getUsersBy",
            "getUsersBy",
        ]),
        clear_option() {
            this.selected = null;
        },
    },

    watch: {
        reset(){
            this.selected = null
            this.$emit("id", null);
        },
        selected(newValue) {
            if (newValue) {
                this.$emit("id", newValue.id);
            } else {
                this.$emit("id", null);
            }
        },
        async franchiseeId(newValue) {
            this.isLoadingData = true;
            if (newValue) {
                // this.list = await this.getUsersBy({
                //     by: 'cliente',
                //     // id: newValue,
                //     ref: 'franqueado',
                //     ref_id: newValue,
                // })
                this.list = await this.getUsersBy({
                    by: 'cliente',
                    ref: this.getCurrentUserRank,
                    ref_id: this.getCurrentUserID,
                    search: 'franqueado',
                    search_id: newValue,
                })
            } else if (newValue === null && !this.defaultAllClient) {
                this.list = []
            } else {
                this.list = await this.getUsersBy({
                    by: 'cliente',
                    ref: this.getCurrentUserRank,
                    ref_id: this.getCurrentUserID,
                })
            }
            this.isLoadingData = false;
        }
    },

    created() {
        
        if (this.defaultAllClient) {
            Utils.waitingTrigger(
                () => {
                    return this.isFirstLoadedUsers;
                },
                async () => {
                    // this.list = await this.getUsersBy({
                    //     by: 'cliente',
                    //     ref: this.getCurrentUserRank,
                    //     ref_id: this.getCurrentUserID,
                    // })

                    this.list = await this.getUsersBy({
                        by: 'cliente',
                        ref: this.getCurrentUserRank,
                        ref_id: this.getCurrentUserID,
                        // search: 'franqueado',
                        // search_id: 'zUaFu7ygSPMTO6DiatIjb09qkft2',
                    })
                }
            );
            // const unwatchisFirstLoadedUsers = this.$watch('isFirstLoadedUsers', async (newValue) => {
            //     if (newValue) {
            //         this.list = await this.getUsersBy({
            //             by: 'cliente',
            //             ref: this.getCurrentUserRank,
            //             ref_id: this.getCurrentUserID,
            //         })
            //         unwatchisFirstLoadedUsers()
            //     }
            // })
        }
        
    }

}
</script>


<style>
    
</style>