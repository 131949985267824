<template>
    <div style="display: flex;">
        <v-autocomplete
            dense
            v-model="selected"
            :items="list"
            :label="label"
            item-text="data.name"
            item-value="data"
            outlined
            hide-details
            :loading="isLoading"
            :disabled="isDisabled"
        >
        </v-autocomplete>
        <div v-if="addClearButton" class="text-center" style="display: flex; justify-content: center;align-items: center;padding: 2px;">
            <v-btn
                small
                color="blue"
                dark
                style="height: 100%; margin-left: 5px;"
                @click="clear_option"
            >
            X
            </v-btn>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Utils from "@/scripts/Utils";
/**
 * Será emitido a lista de clientes que ao ser escolhido um franqueado
 */
export default {
    
    data: () => ({
        label: "Franqueados",

        list: [],
        // associated_items: [],
        selected: null,

    }),

    props: {
        disabled: {
            default: false,
            type: Boolean,
        },
        addClearButton: {
            default: false,
            type: Boolean,
        },
    },

    computed: {
        ...mapGetters([
            "isFirstLoadedUsers",
            "getFranqueados",
            "getCurrentUserRank",
            "getCurrentUserID",

        ]),
        isLoading() {
            return !this.isFirstLoadedUsers
        },
        isDisabled() {
            return this.disabled || this.isLoading
        }
    },

    methods: {
        ...mapActions([
            "getUsersBy",
        ]),
        clear_option() {
            this.selected = null;
        },
    },

    watch: {
        selected(newValue) {
            if (newValue) {
                this.$emit("id", newValue.id);
            } else {
                this.$emit("id", null);
            }
        },
    },

    async created() {
        this.list = await this.getUsersBy({
            by: 'franqueado',
            ref: this.getCurrentUserRank,
            ref_id: this.getCurrentUserID,
        })
    },

}
</script>


<style>
    
</style>