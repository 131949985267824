<template>
    <div style="display: flex;">
        <v-autocomplete
            dense
            v-model="selected"
            :items="list"
            :label="label"
            item-text="data.name"
            item-value="data"
            outlined
            hide-details
            :loading="isLoading"
            :disabled="isDisabled"
        >
        </v-autocomplete>
        <div v-if="addClearButton" class="text-center" style="display: flex; justify-content: center;align-items: center;padding: 2px;">
            <v-btn
                small
                color="blue"
                dark
                style="height: 100%; margin-left: 5px;"
                @click="clear_option"
            >
            X
            </v-btn>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Utils from "@/scripts/Utils";
/**
 * Será emitido a lista de clientes que ao ser escolhido um franqueado
 */
export default {
    
    data: () => ({
        label: "Unidades",

        list: [],
        // associated_items: [],
        selected: null,
        last_selected: null,
        old_ids: [],

        client_id: null,
        franchisee_id: null,
        isLoadingData: false,

        units_id_allowed_current_user: [],
    }),

    props: {
        disabled: {
            default: false,
            type: Boolean,
        },
        clientId: {
            default: null,
            type: String,
        },
        franchiseeId: {
            default: null,
            type: String,
        },
        addClearButton: {
            default: false,
            type: Boolean,
        },
    },

    computed: {
        ...mapGetters([
            "isFirstLoadedUnits",
            "getCurrentUserRank",
            "getCurrentUserID",
        ]),
        isLoading() {
            return !this.isFirstLoadedUnits || this.isLoadingData
        },
        isDisabled() {
            return this.disabled || this.isLoading || (!this.client_id && !this.franchisee_id)
        }
    },

    methods: {
        ...mapActions([
            "getUnitsBy",
        ]),
        clear_option() {
            this.selected = null;
        },
        async request_units_by_client(id) {
            if (this.client_id === null && this.franchisee_id === null) return [];
            if (this.client_id === null && this.franchisee_id !== null) {
                return await this.request_units_by_franchisee(this.franchisee_id)
            }
            // this.clear_option();
            let list = (await this.getUnitsBy({
                by: 'cliente',
                id: id,
            })).map(
                (u) => {
                    let id = u.hasOwnProperty('data') ? u.data.id : u.id;
                    let name =   u.hasOwnProperty('data') ? u.data.name : u.name;
                    return {
                        id: id,
                        data: {
                            id: id,
                            name: name
                        }
                    };
                }
            )
            list = this.add_select_all_option(list);
            return list;
        },
        async request_units_by_franchisee(id) {
            if (this.client_id !== null) return await this.request_units_by_client(this.client_id)
            if (this.franchisee_id === null) return [];
            let list = (await this.getUnitsBy({
                by: 'franqueado',
                id: id,
            })).map(
                (u) => {
                    let id = u.hasOwnProperty('data') ? u.data.id : u.id;
                    let name =   u.hasOwnProperty('data') ? u.data.name : u.name;
                    return {
                        id: id,
                        data: {
                            id: id,
                            name: name
                        }
                    };
                }
            )
            list = this.add_select_all_option(list);
            return list;
        },
        add_select_all_option(list) {
            list.unshift({
                id: "selecionar_todos",
                data: {
                    id: "selecionar_todos",
                    name: "SELECIONAR TODOS"
                }
            })
            return list;
        }
    },

    watch: {
        selected(newValue) {
            if (newValue) {
                this.$emit("id", newValue.id);
            } else {
                this.$emit("id", null);
            }
        },
        async clientId(newValue) {
            this.isLoadingData = true;
            if (newValue) {
                this.client_id = newValue;
            } else {
                this.client_id = null;
            }
            this.list = (await this.request_units_by_client(this.client_id)).filter(u => this.units_id_allowed_current_user.includes(u.data.id) || ['selecionar_todos'].includes(u.data.id))
            if (
                (this.franchisee_id === null && this.client_id === null) || 
                ((this.selected) && this.selected.id === 'selecionar_todos') ||
                ((this.selected) && this.list.find((u, i) => u.id === this.selected.id) === undefined)
            ) this.clear_option()
            this.isLoadingData = false;
        },
        async franchiseeId(newValue) {
            this.isLoadingData = true;
            if (newValue) {
                this.franchisee_id = newValue
            } else {
                this.franchisee_id = null;
            }
            this.list = (await this.request_units_by_franchisee(this.franchisee_id)).filter(u => this.units_id_allowed_current_user.includes(u.data.id) || ['selecionar_todos'].includes(u.data.id))
            if (
                (this.franchisee_id === null && this.client_id === null) || 
                ((this.selected) && this.selected.id === 'selecionar_todos') ||
                ((this.selected) && this.list.find((u) => u.id === this.selected.id) === undefined)
            ) this.clear_option()
            this.isLoadingData = false;
        }
    },

    async created() {
        this.units_id_allowed_current_user = (await this.getUnitsBy({
            by: this.getCurrentUserRank.toLowerCase(),
            id: this.getCurrentUserID,
        })).map(u => u.data.id)
    },

}
</script>


<style>
    
</style>