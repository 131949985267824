<template>
  <v-app style="display: flex">
    <div :style="'width: ' + screenWidth + ' !important;'">
      <div v-if="isMobile">
        <v-img id="bg-mobile" src="@/assets/bg_foz_mobile.jpg"></v-img>
      </div>
      <div v-else-if="isTablet">
        <v-img id="bg-tablet" src="@/assets/bg_foz_tablet.jpg"></v-img>
      </div>
      <div v-else>
        <v-img id="bg" src="@/assets/bg_foz.jpg"></v-img>
      </div>

      <!-- :dense="isPC" -->
      <v-app-bar app color="primary" dark clipped-left v-if="showExitButton()" class="app-bar">

        <div class="d-flex align-center">
          <v-btn  small fab dark color="white" style="margin-left: -8px;" @click="handleButtonMenu()">
            <v-icon dark color="primary">
              mdi-format-list-bulleted-square
            </v-icon>
          </v-btn>
          <v-img  alt="Foz Logo" class="shrink mr-2" contain src="@/assets/logo.png" transition="scale-transition"
            width="80" style="cursor: pointer; margin-left: 10px" @click="$router.push('/consumption')" />
        </div>
        <v-spacer></v-spacer>

        <notifications />

        <v-menu bottom min-width="200px" rounded offset-y>
          <template v-slot:activator="{ on }">
            <v-btn icon x-large v-on="on">
              <v-avatar color="primary" size="48" rounded>
                <v-img v-if="
                  getCurrentUser.data.metadata.imageURL &&
                  getCurrentUser.data.metadata.imageURL.length > 0
                " :src="getCurrentUser.data.metadata.imageURL"></v-img>
                <v-img v-else src="https://cdn.pixabay.com/photo/2013/07/13/12/07/avatar-159236_640.png"></v-img>
              </v-avatar>
            </v-btn>
          </template>

          <v-card>
            <v-list-item-content class="justify-center">
              <div class="mx-auto text-center">
                <h4>{{ getCurrentUserName }}</h4>
                <h5>{{ getCurrentUserRank }}</h5>
                <p class="caption mt-1">
                  {{ getCurrentUser.data.email }}
                </p>
                <v-divider class="my-3"></v-divider>
                <v-btn depressed rounded text small @click="logOut()">
                  Sair
                  <v-icon>exit_to_app</v-icon>
                </v-btn>
              </div>
            </v-list-item-content>
          </v-card>
        </v-menu>
      </v-app-bar>

      <v-navigation-drawer app v-model="drawer" :mini-variant.sync="mini" permanent  clipped
        style="background: rgba(204, 204, 204, 0.1)" v-if="isLoggedIn" >
        <v-list nav dense>
          <v-list-group v-if="subItemsMenuFilter.length" :value="true" prepend-icon="mdi-chart-box" no-action>
            <template v-slot:activator>
              <v-list-item-title>Gestão 4.0</v-list-item-title>
            </template>

            <v-list-item v-for="subitem in subItemsMenuFilter" :key="subitem.title"
              @click="routerDuplicated(subitem.path)" link>
              <v-list-item-icon>
                <v-icon>{{ subitem.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ subitem.title }}</v-list-item-title>
            </v-list-item>

          </v-list-group>
          <v-list-item v-for="item in itemsMenuFilter" :key="item.title" @click="routerDuplicated(item.path)" link>
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-title>{{ item.title }}</v-list-item-title>

          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <v-main>
        <SnackBar v-bind="{ ...optionsSnackBar }" />
        <DialogAlert v-bind="{ ...optionsDialogAlert }" />
        <DatePickerDialog v-bind="{ ...paramDialogDate }" />
        <router-view />
      </v-main>
    </div>
  </v-app>
</template>


<script>
import store from "@/store";
import { mapActions, mapGetters, mapMutations } from "vuex";
import Notifications from "@/components/Notifications/Notifications";
import SnackBar from "@/components/SnackBar/SnackBar";
import DialogAlert from "@/components/DialogAlert/DialogAlert";
import DatePickerDialog from "@/components/DatePickerDialog/DatePickerDialog";

import { RoutesPath } from '@/router/paths.js';
import { MenuFeatures } from "@/router/features.js";

export default {
  name: "App",
  components: {
    SnackBar,
    DialogAlert,
    Notifications,
    DatePickerDialog,
  },
  data: () => ({
    drawer: true,
    mini: true,
    group: [],
    items: [
      {
        title: "Water Scan",
        icon: "mdi-domain",
        path: RoutesPath.CHOOSE_WATER_SCAN,
        feature: MenuFeatures.WATERSCAM,
      },
      {
        title: "Water Fix",
        icon: "mdi-pipe-wrench",
        path: RoutesPath.CHOOSE_WATER_FIX,
        feature: MenuFeatures.WATERFIX,
      },
      {
        title: "Soluções",
        icon: "mdi-package-variant-closed",
        path: RoutesPath.CHOOSE_SOLUTION,
        feature: MenuFeatures.SOLUTIONS,
      },
    ],
    subitems: [
      {
        title: "Dashboard",
        icon: "dashboard",
        path: RoutesPath.DASHBOARD,
        feature: MenuFeatures.DASHBOARD,
      },
      {
        title: 'Consumo',
        icon: 'mdi-water-pump',
        path: RoutesPath.CONSUMPTION,
        feature: MenuFeatures.CONSUMPTION,
      },
      {
        title: 'Alertas',
        icon: 'warning',
        path: RoutesPath.CHOOSE_ALERT,
        feature: MenuFeatures.ALERTS,
      },
      {
        title: "Chamados",
        icon: "mdi-access-point-network",
        path: RoutesPath.CALLED,
        feature: MenuFeatures.CALLEDS,
      },
      {
        title: "Unidades",
        icon: "water_damage",
        path: RoutesPath.UNITS,
        feature: MenuFeatures.UNITS,
      },
      {
        title: "Metas e Previsões",
        icon: "mdi-cash-multiple",
        path: RoutesPath.GOAL_FORECASTS,
        feature: MenuFeatures.GOAL_FORECASTS,
      },
      {
        title: "Relatório",
        icon: "mdi-file-document",
        path: RoutesPath.CHOOSE_REPORT,
        feature: MenuFeatures.REPORTS,
      },
      {
        title: "Configurações",
        icon: "settings",
        path: RoutesPath.CHOOSE_SETTING,
        feature: MenuFeatures.CONFIGS,
      },
    ],
    teste: store.state.loggedIn
  }),
  computed: {
    ...mapGetters([
      "optionsSnackBar",
      "isLoggedIn",
      "paramDialogDate",
      "getCurrentUser",
      "optionsDialogAlert",
      "getCurrentUserData",
      "getCurrentUserRank",
      "getCurrentUserName",
    ]),
    isMobile() {
      return this.$vuetify.breakpoint.name == 'xs' || this.$vuetify.breakpoint.name == 'sm'
    },
    isTablet() {
      return this.$vuetify.breakpoint.name == "md";
    },
    isPC() {
      return (
        this.$vuetify.breakpoint.name == "lg" ||
        this.$vuetify.breakpoint.name == "xl"
      );
    },
    screenWidth() {
      return this.$vuetify.breakpoint.width;
    },
    itemsMenuFilter() {
      if (this.getCurrentUserData && this.getCurrentUserData.permissions) {
        return this.items.filter(({ feature }) => this.getCurrentUserData.permissions.includes(feature))
      } else this.logOut()
    },
    subItemsMenuFilter() {
      if (this.getCurrentUserData && this.getCurrentUserData.permissions) {
        return this.subitems.filter(({ feature }) => this.getCurrentUserData.permissions.includes(feature))
      } else this.logOut()
    },
  },

  methods: {
    ...mapActions([
      "logout",
    ]),
    ...mapMutations(["CLEAR_STATE"]),

    colorItenMenu(path) {
      let path_select = this.$route.path.substring(1).split('/')[0]
      return `/${path_select}` == path ? "#DEE9F4" : ""
    },
    handleButtonMenu() {
      this.mini = !this.mini
    },
    routerDuplicated(path) {
      if (this.$route.path !== path) this.$router.push(path);
    },
    showExitButton() {

      return store.state.loggedIn
    },
    logOut() {
      localStorage.clear();
      this.logout();
      this.$router.push("/");
    },
  },
};
</script>


<style scoped>
#bg {
  position: fixed;
  right: 0;
  bottom: 0;
  top: 45px;
  min-width: 100%;
  min-height: 100%;
}

#bg-mobile {
  position: fixed;
  left: 0;
  top: -290px;
}

#bg-tablet {
  position: fixed;
  right: 0;
  bottom: 0;
  top: -190px;
  min-width: 100%;
  min-height: 100%;
}

.title-displays {
  font-size: 25px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-weight: bold;
}
</style>