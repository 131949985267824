<template>
  <div>
      <!-- <v-row>
          <v-col cols="12">
              <h3>Listagem dos itens devolvidos:</h3>
          </v-col>
      </v-row> -->
      <v-divider></v-divider>

      <!-- Dialog de novo local -->
      <v-row justify="center">
          <v-dialog transition="dialog-top-transition" v-model="compromiseModal" persistent max-width="600px">
              <v-card>
                <v-form ref="compromiseForm" lazy-validation>
                  <v-toolbar color="primary" dark> <span class="text-h5">Adicionar Local</span></v-toolbar>
                  <v-card-title>
                      <v-spacer></v-spacer>
                  </v-card-title>
                  <v-card-text style="padding-bottom: 0px;">
                        <v-row>
                            <v-col cols="12">
                                <div
                                    v-text="getLocalSelected"
                                    style="font-size: 20px;font-weight: bold;"
                                ></div>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row>
                            <v-col cols="12">
                                <v-autocomplete
                                    dense
                                    v-model="selected.questionId"
                                    :items="getAvailableQuestions"
                                    :label="'Pergunta'"
                                    item-text="question"
                                    item-value="id"
                                    outlined
                                    hide-details
                                    :loading="loading"
                                    :rules="rulesRequired"
                                >
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row>
                            <v-col cols="12">
                            <v-autocomplete
                                dense
                                v-model="selected.answerId"
                                :items="getAvailableAnswers"
                                :label="'Resposta'"
                                outlined
                                item-text="answer"
                                item-value="id"
                                hide-details
                                :loading="loading"
                                :rules="rulesRequired"
                            >
                            </v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-textarea
                                    outlined
                                    name="input-7-4"
                                    label="Comentário"
                                    v-model="selected.comments"
                                    :loading="loading"
                                    :disabled="!hasSelected || loading"
                                ></v-textarea>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="() => closeCompromiseModal()" :loading="loading" :disabled="loading">
                            Cancelar
                        </v-btn>
                        <v-btn color="blue darken-1" text @click="addCompromise" :loading="loading" :disabled="loading">Adicionar</v-btn>
                    </v-card-actions> 
                </v-form>
              </v-card>
          </v-dialog>
      </v-row>

      <v-col cols="12">
        <v-row>
          <h3>Termo de Ciência:</h3>
        </v-row>
      </v-col>

      <v-col cols="12">
        <v-row>
          <v-autocomplete
            dense
            v-model="selected.localId"
            :items="getAvailableLocals"
            :label="'Local'"
            outlined
            item-text="local"
            item-value="id"
            hide-details
            value="Teste 6"
            @keyup.backspace="() => {}"
            :loading="loading"
            @input="(e) => updateSearchLocal(e)"
          >
          </v-autocomplete>
        </v-row>
      </v-col>
      <v-divider></v-divider>
      <v-col cols="12">
        <v-row>
          <v-data-table
            v-if="proposal !== null"
            :headers="headers"
            :items="proposal.compromiseList"
            class="elevation-1"
            style="width: 100%"
          >
            <template v-slot:body="{ items }">
              <tbody>
                <tr v-for="(compromise, index) in items" :key="index">
                  <td>
                    <v-text-field       
                        readonly             
                        :value="compromise.local" 
                      >
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field    
                      readonly                
                      :value="compromise.question" 
                    >
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field   
                      readonly
                      :value="compromise.answer"
                    />
                  </td>
                  <td>
                    <v-text-field   
                      readonly
                      :value="compromise.comments"
                    />
                  </td>
                  <td>
                    <v-btn
                      elevation="2"
                      @click="confirmDelete(compromise)"
                      :loading="loading"
                      style="height: 30px; margin: 2px"
                    >
                      <v-icon color="primary" small> mdi-delete </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-row>
      </v-col>
  </div>
</template>
<script>
import Rules from "@/utils/Rules";
import ConsumptionForm from "@/components/FormsComponents/WaterFix/ConsumptionTest.vue";
import firebase from "../../../firebase";

import WaterScanStoragefrom from '@/services/storage/WaterScanStorage';
import WaterFixService from '@/services/firebase/WaterFix/WaterFixService';
import Utils from '@/scripts/Utils';
import SolutionsServices from '@/services/firebase/SolutionsServices/SolutionsServices';
import SolutionsPoint from '@/services/firebase/SolutionsPoint/SolutionsPoint';
import SolutionsGroup from '@/services/firebase/SolutionsGroup/SolutionsGroup';
import { showDeleteDialogAlert } from "@/utils/DialogAlertHelper";
import { showInfoSnackBar, showErrorSnackBar, showSuccessSnackBar } from "@/utils/SnackBartHelper";
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    requestData: {
      default: null,
    },
    requestSave: {
      default: null,
    },
    registration: {
      default: ""
    },
    viewOrEdit: {
      default: ""
    },
    validFormProp: {
      default: () => {},
    },
    closeProposed: {
      default: () => {},
    },
    
  },
  name: "addressForm",
  data: function () {
    return {
        headers: [
          { text: "Local", value: "local", width: 100, sortable: true },
          { text: "Pergunta", value: "question", width: 700, sortable: true },
          { text: "Resposta", value: "answer", width: 150, sortable: true },
          { text: "Observação", value: "comments", width: 200, sortable: true },
          { text: "Ações", value: "action", sortable: false },
        ],
        proposalId: null,
        proposal: null,
        locals: [],
        questions: [],
        answers: [],
        // --------------------------------------------
        dialog: false,
        selected: {
          localId: null,
            local: null,
            questionId: null,
            question: null,
            answerId: null,
            answer: null,
            comments: null,
        },
        selectedLocal: null,
        selectedQuestion: null,
        selectedAnswer: null,
        // --------------------------------------------
        compromiseListIds: [],
        compromiseModal: false,
        tempCompromise: null,
        // --------------------------------------------------------
        // --------------------------------------------------------
        // --------------------------------------------------------
        index: 2,
        loading: false,
        // --------------------------------------------------------
        questionsOptions: [
            {
                question: "A visita técnica foi realizada?",
                id: 'question_001',
            },
            {
                question: "Teste de vazão com e sem a solução foi realizado?",
                id: 'question_002',
            },
            {
                question: "Houveram peças instaladas?",
                id: 'question_003',
            },
            {
                question: "Houve registro fotográfico das eficiências?",
                id: 'question_004',
            },
            {
                question: "Já existiam vazamentos no local?",
                id: 'question_005',
            },
            {
                question: "Foram deixados vazamentos pela empresa?",
                id: 'question_006',
            },
            {
                question: "Os pontos avaliados foram deixados na mesma condição anterior ao serviço prestados?",
                id: 'question_007',
            },
            {
                question: "O serviço foi acompanhado por algum responsável?",
                id: 'question_008',
            },
        ],
        answerOptions: [
            {
                answer: 'Não',
                id: 'answer_000',
            },
            {
                answer: 'Sim',
                id: 'answer_001',
            },
        ],
    };
  },
  methods: {
    ...mapActions([
      'subscriberWaterfix',
    ]),
    resetCompromise() {
      this.selected = {
        localId: null,
        local: null,
        questionId: null,
        question: null,
        answerId: null,
        answer: null,
        comments: null,
      };
      this.selectedLocal = null;
      this.selectedQuestion = null;
      this.selectedAnswer = null;
    },
    updateSearchLocal(input) {
      if (input !== null) {
        this.setNewCompromise()
      }
    },
    formatNumber(number) {
      return Utils.formatNumber(parseFloat(number))
    },
    // ---------------------------------------------------------------------------
    // Métodos obrigatórios para o gerenciamento do componente pai
    isValidadePage() { return true; }, // É preciso deixar para não quebrar a lógica do componente pai
    saveAll() {}, // É preciso deixar para não quebrar a lógica do componente pai
    setLoading(status) {
      this.loading = status;
    },
    emitLoadingEvent(status) {
      this.$emit("loading", status);
    },
    confirmDelete(compromise) {
      this.tempCompromise = compromise
      showDeleteDialogAlert(`Deseja excluir esse item?\nLocal: ${compromise.local} / Pergunta: ${compromise.question}`, this.deleteCompromise)
    },
    async deleteCompromise() {
      let compromiseList = this.proposal.compromiseList.filter((item) => item.id !== this.tempCompromise.id)
      await WaterFixService.updateProposal(this.proposalId, { compromiseList })
      this.proposal.compromiseList = compromiseList
      await this.$nextTick(
        async () => {
          await this.updateProposalCycle(Utils.copyObject(this.proposal));
          this.tempDevolution = null;
        }
      )
    },
    // ---------------------------------------------------------------------------
    // ---------------------------------------------------------------------------
    // ---------------------------------------------------------------------------
    // Operações das listas de devolução, de solução e pontos
    async getProposalFromWaterFix(registration) {
      // Verificar se existe alguma proposta de water fix
      try {
        const proposalObject = await WaterFixService.getProposalByRegistration(registration)
        if (proposalObject) {
            return proposalObject;
        } else {
            showErrorSnackBar("Não foi encontrado a proposta")
            return null;
        }
      } catch (e) {
        showErrorSnackBar("Ocorreu um erro na busca da proposta para o termo de ciência")
        return null;
      }
    },
    async initializeLocals(proposalObject) {
      if (proposalObject) {
        this.proposal = proposalObject
        this.proposalId = this.proposal.id;
        let locals = Utils.removeDuplicatesCustom(this.proposal.solutionList.map(
          (item) => {
            return {
              id: Utils.createSlug(item.local),
              local: item.local,
            }
          }
        ), ['id'])
        this.locals = locals;
        return;
      } else {
        showErrorSnackBar("Ocorreu um erro na busca dos termos de ciência da proposta")
      }
    },
    // Esta função nao tem muito efeito prático mas é para seguir a logica dos locais
    // de ter uma lista separado para buscar nele posteriormente
    initializeQuestions(questionsOptions) {
        this.questions = Utils.copyObject(questionsOptions)
    },
    // Esta função nao tem muito efeito prático mas é para seguir a logica dos locais
    // de ter uma lista separado para buscar nele posteriormente
    initializeAnswers(answerOptions) {
        this.answers = Utils.copyObject(answerOptions)
    },
    async updateCompromise() {
        this.setLoading(true)
        this.emitLoadingEvent(true)
        let proposalFromWaterFix = await this.getProposalFromWaterFix(this.registration);
        if (!proposalFromWaterFix) {
            this.setLoading(false)
            this.emitLoadingEvent(false)
        }
        await this.updateProposalCycle(proposalFromWaterFix)
    },
    async updateProposalCycle(proposal) {
      this.setLoading(true)
      await this.initializeLocals(proposal);
      this.initializeQuestions(this.questionsOptions);
      this.initializeAnswers(this.answerOptions)
      this.updateCompromiseListIds()
      this.setLoading(false)
      this.emitLoadingEvent(false)
    //   await this.initializeSolutions(proposal);
    //   await this.$nextTick(
    //     () => {
    //       this.updateSearchSolutionList();
    //     }
    //   )
    },
    // ---------------------------------------------------------------------------
    // Criar a lista de checklist disponivel para consultar
    getLocalById(localId) {
      return this.locals.find((item) => item.id == localId)
    },
    getQuestionById(questionId) {
      return this.questions.find((item) => item.id == questionId)
    },
    getAnswerById(answerId) {
      return this.answers.find((item) => item.id == answerId)
    },
    // getSublocalById(sublocalId) {
    //   return this.sublocals.find((item) => item.id == sublocalId)
    // },
    // getSolutionById(solutionId) {
    //   return this.solutions.find((item) => item.id == solutionId)
    // },
    // getGroupById(groupId) {
    //   return this.groups.find((item) => item.id == groupId)
    // },
    // getPointById(pointId) {
    //   return this.points.find((item) => item.id == pointId)
    // },
    generateCompromiseId(localId, questionId) {
      return `${localId}_${questionId}`
    },
    updateCompromiseListIds() {
        this.compromiseListIds = this.proposal.compromiseList.map(
            (item) => {
                const localId = Utils.createSlug(item.local);
                const questionId = item.questionId;
                const id = this.generateCompromiseId(localId, questionId);
                return {
                    localId,
                    questionId,
                    id,
                };
            }
        ).filter((item) => item !== null)
    },
    openCompromiseModal() {
      if (!this.hasAvailableQuestions) {
        showInfoSnackBar("Todas as perguntas foram respondidas para este local");
        return;
      }
      this.compromiseModal = true;
    },
    closeCompromiseModal() {
      this.compromiseModal = false;
      this.resetForm();
      this.resetCompromise();
    },
    setNewCompromise() {
      this.openCompromiseModal()
    },
    async addCompromise() {
        this.setLoading(true)
        if (!this.getValidateForm()) {
            showErrorSnackBar("Há dado(s) inválido(s) na inserção de devolução")
            this.setLoading(false)
            return;
        }
        let selected = Utils.copyObject(this.selected)
        this.proposal.compromiseList.push({
          id: this.generateCompromiseId(this.selected.localId, this.selected.questionId),
          answer: this.selected.answer,
          answerId: this.selected.answerId,
          question: this.selected.question,
          questionId: this.selected.questionId,
          comments: this.selected.comments,
          local: this.selected.local,
          localId: this.selected.localId,
        })
        await WaterFixService.updateProposal(this.proposalId, { compromiseList: this.proposal.compromiseList })
        this.$nextTick(
          async () => {
            await this.updateProposalCycle(Utils.copyObject(this.proposal));
            showSuccessSnackBar("Um novo termo de ciência foi inserido com sucesso!");
            this.closeCompromiseModal();
            this.setLoading(false)
          }
        )
    },
    // ---------------------------------------------------------------------------
    getValidateForm() {
      if (!this.$refs.compromiseForm) return false;
      return this.$refs.compromiseForm.validate();
    },
    resetForm() {
      if (!this.$refs.compromiseForm) return;
      this.$refs.compromiseForm.reset();
    },
    async updateLocalListener(beforeId, afterId, text) {
      this.proposal.solutionList = this.proposal.solutionList.map(
        (solution) => {
          let localId = Utils.createSlug(solution.local)
          if (beforeId !== localId) return solution;
          return {
            ...solution,
            local: text,
          }
        }
      )
      this.proposal.compromiseList = this.proposal.compromiseList.map(
        (compromise) => {
          const localId = Utils.createSlug(compromise.local)
          if (beforeId !== localId) return compromise;
          return {
            ...compromise,
            local: text,
          }
        }
      )
      await WaterFixService.updateProposal(this.proposalId, { compromiseList: this.proposal.compromiseList })
      await this.updateProposalCycle(Utils.copyObject(this.proposal));
    },
  },
  computed: {
    rulesRequired() {
      return Rules.required();
    },
    getLocalSelected() {
      if (!this.selected.localId) return "";
      return Utils.capitalizeString(this.getLocalById(this.selected.localId).local)
    },
    hasSelected() {
      return (
        this.selected.localId !== null &&
        this.selected.questionId !== null && 
        this.selected.answerId !== null
      )
    },
    getAvailableLocals() {
        return this.locals;
    },
    getAvailableQuestions() {
      const questionUsedId = this.compromiseListIds.filter((item) => item.localId == this.selected.localId).map((item) => item.questionId)
      const questions = this.questions.filter((item) => !questionUsedId.includes(item.id))
      return questions;
    },
    hasAvailableQuestions() {
      return this.getAvailableQuestions.length > 0;
    },
    getAvailableAnswers() {
        return this.answers;
    },
  },
  watch: {
    "selected.localId": function (newValue) {
      if (newValue) {
        this.selectedLocal = this.getLocalById(newValue)
        this.selected.local = this.selectedLocal.local
      }
    },
    "selected.questionId": function (newValue) {
      if (newValue) {
        this.selectedQuestion = this.getQuestionById(newValue)
        this.selected.question = this.selectedQuestion.question
      }
    },
    "selected.answerId": function (newValue) {
      if (newValue) {
        this.selectedAnswer = this.getAnswerById(newValue)
        this.selected.answer = this.selectedAnswer.answer
      }
    },
  },
  async created() {
    this.subscriberWaterfix({
      channel: 'checklist-local:update',
      fn: this.updateLocalListener
    })
    await this.updateCompromise();
  },
};
</script>