<template>
  <v-container fluid>
    <v-row class="dashboardData">
      <v-col cols="12">
        <!-- Dialog de consulta de gráfico -->
        <v-dialog v-model="consultGraph" max-width="500px" v-if="consultGraph">
          <v-overlay absolute style="opacity: 0.8" :value="loadingReadings">
            <v-progress-circular indeterminate color="primary" v-if="loadingReadings"></v-progress-circular>
          </v-overlay>
          <v-card>
            <v-toolbar color="primary" dark dense>
              Consultar Gráficos

              <v-spacer></v-spacer>

              <v-btn class="litte" text @click="consultGraph = false">

                <v-icon>mdi-window-close</v-icon>

              </v-btn>
            </v-toolbar>
            <!-- Corpo do menu para visualização por Gráfico-->
            <v-card-text style="margin-top: 1rem">

              <v-select :items="chartMenuItems" label="Período" v-model="optionPeriod" item-value="item" outlined
                dense></v-select>

              <!-- Para opção 'Por dia' é necessário escolher o mês. -->
              <v-select :items="byYear" label="Ano" v-model="yearSelected" item-value="item" outlined dense
                v-if="optionPeriod == 'Por dia'"></v-select>

              <v-select :items="byMonth" label="Mês" v-model="monthSelected" item-value="item" outlined dense
                v-if="yearSelected && optionPeriod == 'Por dia'"></v-select>

              <!-- Para opção 'Por mês' é necessário escolher o ano. -->
              <v-select :items="byYear" label="Ano" v-model="yearSelected" item-value="item" outlined dense
                v-if="optionPeriod == 'Por mês'"></v-select>

              <!-- Opçao de calendario caso a opção 'Por hora' seja selecionada -->

              <v-menu v-model="menuDate" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                offset-y min-width="auto" v-if="optionPeriod == 'Por hora'">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="dateSelectedFormatted" label="Dia" prepend-icon="mdi-calendar" readonly
                    v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker class="date-picker-height" v-model="dateSelected" @input="menuDate = false"
                  locale="pt-br"></v-date-picker>
              </v-menu>


              <!-- Opçao de calendario caso a opção 'Por período' seja selecionada -->
              <v-menu v-model="menuDateStart" :close-on-content-click="false" :nudge-right="40"
                transition="scale-transition" offset-y min-width="auto" v-if="optionPeriod == 'Por intervalo'">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="dateFormattedSelectedStart" label="Data Inicial" prepend-icon="mdi-calendar"
                    readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker class="date-picker-height" v-model="dateSelectedStart" @input="menuDateStart = false"
                  locale="pt-br"></v-date-picker>
              </v-menu>

              <v-menu v-model="menuDateEnd" :close-on-content-click="false" :nudge-right="40"
                transition="scale-transition" offset-y min-width="auto" v-if="optionPeriod == 'Por intervalo'">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="dateFormattedSelectedEnd" label="Data Final" prepend-icon="mdi-calendar"
                    readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker class="date-picker-height" v-model="dateSelectedEnd" @input="menuDateEnd = false"
                  locale="pt-br"></v-date-picker>
              </v-menu>

              <v-select :items="optionsToDisplay" item-text="label" label="Exibir por" v-model="optionToDisplaySelected"
                item-value="value" v-if="optionPeriod == 'Por intervalo'" readonly></v-select>

              <v-switch v-model="optionMetric" :label="`Exibir em: ${switch1}`"></v-switch>

              <v-alert v-if="totalDates > 90 && dateOptions.mode == 'day'" dense outlined type="error">
                Selecione um período de no máximo <strong>90 dias</strong>, o intervalo permitido de <strong>{{
          permittedStartDate
        }}</strong> até <strong>{{ permittedEndDate }}</strong>
              </v-alert>
              <v-alert v-if="totalDates > 24 && dateOptions.mode == 'hour'" dense outlined type="error">
                Selecione um período de no máximo <strong>24 horas</strong>, o intervalo permitido de <strong>{{
          permittedStartDate
        }}</strong> até <strong>{{ permittedEndDate }}</strong>
              </v-alert>
              <v-alert v-if="totalDates > 12 && dateOptions.mode == 'month'" dense outlined type="error">
                Selecione um período de no máximo <strong>12 meses</strong>, o intervalo permitido de <strong>{{
          permittedStartDate
        }}</strong> até <strong>{{ permittedEndDate }}</strong>
              </v-alert>



            </v-card-text>
            <!-- Ações do menu -->
            <v-card-actions class="justify-center">
              <v-btn color="success" @click="consultConsumptionGraphic()" :disabled="!optionPeriod">
                Consultar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Dialog de consulta de leitura -->
        <v-dialog v-model="consultReading" max-width="500px" v-if="consultReading">
          <v-overlay absolute style="opacity: 0.8" :value="loadingReadings">
            <v-progress-circular indeterminate color="primary" v-if="loadingReadings"></v-progress-circular>
          </v-overlay>

          <v-card>

            <v-toolbar color="primary" dark dense>
              Consultar Leituras

              <v-spacer></v-spacer>

              <v-btn class="litte" text @click="consultReading = false">

                <v-icon>mdi-window-close</v-icon>

              </v-btn>
            </v-toolbar>

            <!-- Corpo do menu -->

            <v-card-text style="margin-top: 1rem">

              <v-select :items="chartMenuItems" label="Período" v-model="optionPeriod" item-value="item" outlined
                dense></v-select>

              <!-- Para opção 'Por dia' é necessário escolher o mês. -->
              <v-select :items="byYear" label="Ano" v-model="yearSelected" item-value="item" outlined dense
                v-if="optionPeriod == 'Por dia'"></v-select>

              <v-select :items="byMonth" label="Mês" v-model="monthSelected" item-value="item" outlined dense
                v-if="yearSelected && optionPeriod == 'Por dia'"></v-select>

              <!-- Para opção 'Por mês' é necessário escolher o ano. -->
              <v-select :items="byYear" label="Ano" v-model="yearSelected" item-value="item" outlined dense
                v-if="optionPeriod == 'Por mês'"></v-select>

              <!-- Opçao de calendario caso a opção 'Por hora' seja selecionada -->

              <v-menu v-model="menuDate" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                offset-y min-width="auto" v-if="optionPeriod == 'Por hora'">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="dateSelectedFormatted" label="Dia" prepend-icon="mdi-calendar" readonly
                    v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker class="date-picker-height" v-model="dateSelected" @input="menuDate = false"
                  locale="pt-br"></v-date-picker>
              </v-menu>


              <!-- Opçao de calendario caso a opção 'Por período' seja selecionada -->
              <v-menu v-model="menuDateStart" :close-on-content-click="false" :nudge-right="40"
                transition="scale-transition" offset-y min-width="auto" v-if="optionPeriod == 'Por intervalo'">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="dateFormattedSelectedStart" label="Data Inicial" prepend-icon="mdi-calendar"
                    readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker class="date-picker-height" v-model="dateSelectedStart" @input="menuDateStart = false"
                  locale="pt-br"></v-date-picker>
              </v-menu>

              <v-menu v-model="menuDateEnd" :close-on-content-click="false" :nudge-right="40"
                transition="scale-transition" offset-y min-width="auto" v-if="optionPeriod == 'Por intervalo'">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="dateFormattedSelectedEnd" label="Data Final" prepend-icon="mdi-calendar"
                    readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker class="date-picker-height" v-model="dateSelectedEnd" @input="menuDateEnd = false"
                  locale="pt-br"></v-date-picker>
              </v-menu>

              <v-select :items="optionsToDisplay" item-text="label" label="Exibir por" v-model="optionToDisplaySelected"
                item-value="value" v-if="optionPeriod == 'Por intervalo'" readonly></v-select>

              <v-alert v-if="!validDayPeriodSelected && optionToDisplaySelected == 'day'" dense outlined type="error">
                Selecione um período de no máximo <strong>50 dias</strong>
              </v-alert>
              <v-alert v-if="!validHourPeriodSelected && optionToDisplaySelected == 'hour'" dense outlined type="error">
                Selecione um período de no máximo <strong>24 horas</strong>
              </v-alert>
              <v-alert v-if="!validMonthPeriodSelected && optionToDisplaySelected == 'month'" dense outlined type="error">
                Selecione um período de no máximo <strong>12 meses</strong>
              </v-alert>



            </v-card-text>

            <!-- Ações do menu -->

            <v-card-actions class="justify-center">

              <v-btn color="success" @click="startConsultTable()" :disabled="!(optionPeriod) || !allFieldsReady">
                Consultar
              </v-btn>


            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- ------------------------------------ -->

        <v-card class="rounded-xl">

          <v-card-title class="title-displays">Consumo</v-card-title>

          <!-- <v-card elevation="0"> -->
          <!-- Título do card de filtragem -->
          <v-card-title
            style="align-items: center; padding-bottom: 25px; padding-right:0; padding-right:2rem; padding-left:2rem">
            <v-row style="flex-wrap: wrap; min-width: 255px;">
              <v-col style="min-width: 150px;position:relative;">
                <v-autocomplete v-model="selectedClient" :items="listClients" item-text="name" item-value="id"
                  label="Cliente" dense outlined hide-details :loading="selectsLoading.client"
                  :disabled="selectsLoading.client" @change="onChangeClient" @keydown="onKeydownClient">

                </v-autocomplete>
              </v-col>
              <v-col style="min-width: 150px;position:relative;">
                <v-autocomplete dense v-model="selectedUnit" :items="listUnits" item-text="name" item-value="id"
                  label="Unidade" outlined hide-details :loading="selectsLoading.unit"
                  :disabled="selectsLoading.unit || selectsLoading.client || !selectedClient" @change="onChangeUnit"
                  @keydown="onKeydownUnit">
                </v-autocomplete>
              </v-col>
              <v-col style="min-width: 150px;position:relative;">
                <v-autocomplete dense v-model="selectedSector" :items="listSectors" item-text="name" return-object
                  label="Setor" outlined multiple hide-details :loading="selectsLoading.sector"
                  :disabled="selectsLoading.sector || selectsLoading.unit || selectsLoading.client || !selectedUnit"
                  @change="onChangeSector" @keydown="onKeydownSector">
                </v-autocomplete>
              </v-col>

            </v-row>
          </v-card-title>

          <!-- Botões de gráfico, leitura e gerar pdf -->
          <v-card-actions class="text-center" style="padding-top: 0px; padding-right:0px;">
            <v-row>

              <v-col style="max-width: 170px;padding-left:30px;">
                <v-btn
                  @click="graphicFlag = true; isDataTable = false; consultGraph = true; consultTable = false; isDataGraphic = false; allowPrinting = false"
                  :disabled="!(selectedSector)" color="primary" style="font-weight: bold;" outlined>
                  <v-icon small>mdi-chart-bar</v-icon>
                  Gráfico
                </v-btn>
              </v-col>

              <v-col style="max-width: 140px;">
                <v-btn
                  @click="graphicFlag = false; consultReading = true; consultTable = false; consultGraph = false; isDataGraphic = false; isDataTable = false;"
                  :disabled="!(selectedSector)" color="primary" style="font-weight: bold;" outlined>
                  <v-icon small>mdi-book-open-variant</v-icon>
                  Leitura
                </v-btn>
              </v-col>

              <!-- <v-col v-if="showNextReading">
                      <div style="height: 36px;background-color: #1976d2 !important;border-radius: 5px;display: flex;justify-content: space-evenly;align-items: center;width: 270px;">
                        <span style="color: white;font-size: 15px;">Data de leitura estimada</span>
                        <span style="color: white;font-size: 15px;">{{ nextDateFormated }}</span>
                      </div>
                    </v-col> -->
              <v-spacer></v-spacer>




              <v-col style="display: flex;justify-content: end">
                <!-- Gerar PDF -->
                <!-- Botão de gerar pdf -->

                <v-col style="max-width: 160px;">
                  <v-btn color="primary" text @click="exportSheets()" style="height:100%"
                    :disabled="!(allowPrinting) || !(isDataTable)">
                    <v-img src="~@/assets/images/icon-excel.png"
                      style="width:1.5rem; float: right; margin-left: 0.5rem">
                    </v-img>
                    Gerar Planilha
                  </v-btn>
                </v-col>

                <v-col style="max-width: 170px;">
                  <v-btn color="primary" text @click="startPdf()" :disabled="!(allowPrinting) || !this.graphicFlag"
                    :loading="loadButtonPdf" style="height:100%">
                    <!-- :disabled="!(allowPrinting) || resVet.length>1" -->
                    <v-img src="~@/assets/images/icon-pdf.png" style="width:1.5rem; float: right; margin-left: 0.5rem">
                    </v-img>

                    Gerar PDF
                  </v-btn>
                </v-col>



              </v-col>

            </v-row>
            <br>
          </v-card-actions>
          <!-- </v-card> -->



        </v-card>

      </v-col>
    </v-row>

    <!-- Cabeçalho (dados resumidos do cliente) -->
    <v-row class="dashboardData">
      <v-col cols="12">
        <v-card class="layout-data-abstract" v-show="selectedClient !== null">
          <v-card-title>
            <v-row style="flex-wrap: wrap;">
              <v-col class="layout-data-abstract-fields" style="min-width: 180px;">
                <div class="card-style-data-client" style="padding: 1rem;">
                  Tipo de consumo
                  <v-divider></v-divider>
                  {{ data_abstract_client.consumptionType }}
                </div>
              </v-col>
              <v-col class="layout-data-abstract-fields" style="min-width: 180px;">
                <div class="card-style-data-client" style="padding: 1rem;">
                  Matrícula
                  <v-divider></v-divider>
                  {{ data_abstract_client.registry }}
                </div>
              </v-col>
              <v-col class="layout-data-abstract-fields" style="min-width: 180px;">
                <div class="card-style-data-client" style="padding: 1rem;">
                  Endereço
                  <v-divider></v-divider>
                  {{ data_abstract_client.address }}
                </div>
              </v-col>
              <v-col class="layout-data-abstract-fields" style="min-width: 180px;">
                <div class="card-style-data-client" style="padding: 1rem;">
                  Data de Leitura
                  <v-divider></v-divider>
                  {{ nextDateFormated }}
                </div>
              </v-col>
              <v-col class="layout-data-abstract-fields" style="min-width: 220px;">
                <div class="card-style-data-client" style="padding: 1rem;">
                  Última atualização
                  <v-divider></v-divider>
                  {{ data_abstract_client.last_updated }}
                </div>
              </v-col>
            </v-row>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>


    <!-- Gráfico -->

    <v-row class="dashboardData" align="center" justify="center" v-if="isDataGraphic">

      <div style=" min-width:100%;">
        <v-col cols="12">
          <v-row no-gutters v-for="(reading, i) in readings" :key="i">

            <v-col cols="12" style="margin-bottom: 15px;">
              <!-- // * Histórico de Consumo -->
              <v-dialog v-model="dialog">
                <template v-slot:activator="{ attrs }">
                  <v-card class="rounded-xl" outlined raised v-bind="attrs">

                    <v-row style="padding: 0 30px;">

                      <v-col>
                        <v-row style="min-width: 160px;flex-direction: column;">
                          <h2 style="color: #437EBE; font-size: 1.5em;">
                            Consumo de Água
                          </h2>
                          <h2 style="color: #696969; font-size: 100%; margin-left: 0.5rem"
                            v-if="switch1 != 'Metros Cúbicos (m³)'"> ( Medidas em L )</h2>
                          <h2 style="color: #696969; font-size: 100%; margin-left: 0.5rem"
                            v-if="switch1 == 'Metros Cúbicos (m³)'"> ( Medidas em m³ )</h2>
                        </v-row>
                      </v-col>

                    </v-row>


                    <!-- // TODO mais dados pra evidenciar a eficácia de overflow-x-auto -->
                    <ChartConsumption style="min-height: 200px" :labels="readings[i].labels"
                      :datasets="readings[i].datasets" />
                    <div style="text-align:center; width: 100%;margin-top: 10px">
                      <span style="color: #696969; font-weight: bold; font-size: 14px;">{{
          getResumeData(reading.modemId, 'modemName') }}</span>
                    </div>

                    <div class="consumption-total-style">
                      <span style="color: #696969; font-weight: bold; font-size:larger">Consumo Médio Atual: </span>
                      <span style="font-size: larger; margin-left: 1rem; font-weight: bold">{{
          (getResumeData(reading.modemId, 'average')).toFixed(2) }} {{ isCubicMetersSelected ? 'm³' : 'L'
                        }}</span>
                    </div>
                    <div class="consumption-total-style">
                      <span style="color: #696969; font-weight: bold; font-size:larger">Consumo Medido/Total Estimado:
                      </span>
                      <span style="font-size: larger; margin-left: 1rem; font-weight: bold">{{
          getResumeData(reading.modemId, 'measured_total').toFixed(2) }} {{ isCubicMetersSelected ? 'm³' :
          'L' }} / {{ getResumeData(reading.modemId, 'estimated_total').toFixed(2) }} {{
          isCubicMetersSelected ? 'm³' : 'L' }}</span>
                    </div>

                  </v-card>
                </template>

              </v-dialog>
            </v-col>
          </v-row>

        </v-col>
      </div>
    </v-row>
    <!-- Fim Gráfico -->

    <!-- Tabela -->
    <v-row class="dashboardData" align="center" justify="center" v-if="isDataTable" style="border-radius: 1rem">

      <v-card class="rounded-xl" style="border-radius: 20px;width:98%;" v-for="(reading, i) in readings_table" :key="i">
        <!-- Loading enquanto os dados estiverem sendo carregados -->
        <v-overlay absolute style="opacity: 0.8" :value="loadingReadings">
          <v-progress-circular indeterminate color="primary" v-if="loadingReadings"></v-progress-circular>
        </v-overlay>

        <v-toolbar color="primary" dark dense style="border-radius: 20px 20px 0px 0px">
          Leitura {{ getResumeData(reading.modemId, 'modemName') }}
        </v-toolbar>

        <!-- Tabela de Consumo -->
        <v-card-text>
          <div style="width:100%;text-align:center; border-radius:1rem; border: gray 1px solid">
            <v-data-table :headers="headers_tabledays" :items="reading.items" class="rounded-1"
              style="border-radius:1rem;" dense>
            </v-data-table>
            <div class="consumption-total-style">
              <span style="color: #696969; font-weight: bold; font-size:larger">Consumo Médio Atual: </span>
              <span style="font-size: larger; margin-left: 1rem; font-weight: bold">{{ (getResumeData(reading.modemId,
                'average')).toFixed(2) }} {{ isCubicMetersSelected ? 'm³' : 'L' }}</span>
            </div>
            <div class="consumption-total-style">
              <span style="color: #696969; font-weight: bold; font-size:larger">Consumo Medido:</span>
              <span style="font-size: larger; margin-left: 1rem; font-weight: bold">{{ getResumeData(reading.modemId,
                'measured_total').toFixed(2) }} {{ isCubicMetersSelected ? 'm³' : 'L' }}</span>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-row>
    <v-row>
      <v-btn v-show="false" small color="primary" text>Baixar Planilha Completa</v-btn>
    </v-row>


  </v-container>

</template>

<script>
import ConsumptionScript from './ConsumptionScript.js';
export default ConsumptionScript;
</script>

<style lang="scss">
.litte {
  left: 10px;
}

.consumption-total-style {
  display: flex;
  justify-content: center;
  margin: 10px 0px 10px 0px;
}

.date-picker-height {
  height: 450px
}

.style-div {

  display: flex;
  //justify-content: space-evenly;
  justify-content: flex-start;

}



.layout-data-abstract-fields {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.layout-data-abstract {
  background: #1976d2 !important;
  border-radius: 1rem !important;


}

.style-card-city-name {
  background: #3298ed !important;

  text-align: center !important;
  border-radius: 1rem;
  padding: 1rem;
  font-size: 1rem;
  word-break: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  span {
    color: white;
    font-style: italic;
  }
}

.v-divider {
  border-color: white;
  border-width: 0.05rem;
}

.card-style-data-client {
  color: white !important;
  background: #1976d2 !important;
  text-align: center !important;
  border-style: none !important;
  font-size: 1rem !important;
}

.v-text-field-bar-class {
  border-style: none !important;
}

.text-large {
  font-size: 225%;
  font-weight: 500;
}

.cards {
  padding-top: 5px !important;
  padding-bottom: 4px !important;
  padding-left: 15px !important;
  padding-right: 10px !important;
}

.ticketCards {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.cardsLG {
  margin-bottom: 5px !important;
  padding-left: 6px !important;
  padding-right: 6px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.ticketCardsLG {
  margin-bottom: 5px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.cardsBigger {
  padding-bottom: 4px !important;
  padding-left: 15px !important;
  padding-right: 10px !important;
}

.cardsBiggerLG {
  margin-bottom: 5px !important;
  padding-left: 6px !important;
  padding-right: 6px !important;
}
</style>
