<template>
  <v-form 
    ref="form" 
    v-model="validForm" 
    lazy-validation
  >
    <v-row justify="center">
      <v-dialog 
          transition="dialog-top-transition" 
          v-model="dialog" 
          v-if="dialog" 
          persistent 
          max-width="700px"
        >
        <v-card style="background-color: white !important;">
          <v-toolbar color="primary" dark> <span class="text-h5">Edição da Solução</span></v-toolbar>
          <v-card-title>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text style="padding-bottom: 0px;">
            <v-row>
              <h3> Informações do Ponto</h3>
            </v-row>
            <v-row>
              <v-col cols="12" :sm="6">
                <LocalField 
                  :loading="loadings.local"
                  :disabled="disablements.local"
                  :proposal="proposal"
                  :id="values.local.id"
                  @loading:input="(status) => setLoading('local', status)"
                  @disabled:input="(status) => setDisabled('local', status)"
                  @change:input="(value) => setValue('local', value)"
                  />
              </v-col>
              <v-col cols="12" :sm="6">
                <SublocalField 
                  :loading="loadings.sublocal"
                  :disabled="disablements.sublocal"
                  :proposal="proposal"
                  :id="values.sublocal.id"
                  @loading:input="(status) => setLoading('sublocal', status)"
                  @disabled:input="(status) => setDisabled('sublocal', status)"
                  @change:input="(value) => setValue('sublocal', value)"
                  />
              </v-col>
              <v-col cols="12" :sm="4">
                <PointOptionsField
                  :loading="loadings.pointOptions"
                  :disabled="disablements.pointOptions"
                  :items="pointsOptions"
                  :id="values.pointOptions.id"
                  :noRule="true"
                  @loading:input="(status) => setLoading('pointOptions', status)"
                  @disabled:input="(status) => setDisabled('pointOptions', status)"
                  @change:input="(value) => setValue('pointOptions', value)"
                  />
              </v-col>
              <v-col cols="12" :sm="4">
                <SolutionOptionsField
                  :loading="loadings.solutionOptions"
                  :disabled="disablements.solutionOptions"
                  :items="solutionsOptionsList"
                  :id="values.solutionOptions.id"
                  :noRule="true"
                  @loading:input="(status) => setLoading('solutionOptions', status)"
                  @disabled:input="(status) => setDisabled('solutionOptions', status)"
                  @change:input="(value) => setValue('solutionOptions', value)"
                  />
              </v-col>
              <v-col cols="12" :sm="4">
                <TextField
                  :loading="loadings.amountSolution"
                  :disabled="disablements.amountSolution"
                  :value="values.amountSolution"
                  :label="'Qtnd.'"
                  :type="'Number'"
                  @loading:input="(status) => setLoading('amountSolution', status)"
                  @disabled:input="(status) => setDisabled('amountSolution', status)"
                  @change:input="(value) => setValue('amountSolution', value)"
                  :rules="rulesValue"
                  />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" :sm="4">
                <TextField
                  :loading="loadings.flowRateBefore"
                  :disabled="disablements.flowRateBefore"
                  :value="values.flowRateBefore"
                  :label="'Vazão antes'"
                  :type="'Number'"
                  :suffix="'L/min'"
                  @loading:input="(status) => setLoading('flowRateBefore', status)"
                  @disabled:input="(status) => setDisabled('flowRateBefore', status)"
                  @change:input="(value) => setValue('flowRateBefore', value)"
                  :rules="rulesValue"
                  />
              </v-col>
              <v-col cols="12" :sm="4">
                <TextField
                  :loading="loadings.flowRateAfter"
                  :disabled="disablements.flowRateAfter"
                  :value="values.flowRateAfter"
                  :label="'Vazão depois'"
                  :type="'Number'"
                  :suffix="'L/min'"
                  @loading:input="(status) => setLoading('flowRateAfter', status)"
                  @disabled:input="(status) => setDisabled('flowRateAfter', status)"
                  @change:input="(value) => setValue('flowRateAfter', value)"
                  :rules="values.statusOptions == 'Instalado' ? rulesValueRequired : rulesValue"
                  />
              </v-col>
              <v-col cols="12" :sm="4">
                <v-text-field v-model="values.economy" outlined label="Economia" readonly suffix="%">
                </v-text-field>
              </v-col>
              <v-col cols="12" :sm="6">
                <DateField
                  :loading="loadings.date"
                  :disabled="disablements.date"
                  :value="values.date"
                  :noRule="true"
                  @loading:input="(status) => setLoading('date', status)"
                  @disabled:input="(status) => setDisabled('date', status)"
                  @change:input="(value) => setValue('date', value)"
                />
              </v-col>
              <v-col cols="12" :sm="6">
                <StatusOptionsField
                  :loading="loadings.statusOptions"
                  :disabled="disablements.statusOptions"
                  :value="values.statusOptions"
                  :noRule="true"
                  @loading:input="(status) => setLoading('statusOptions', status)"
                  @disabled:input="(status) => setDisabled('statusOptions', status)"
                  @change:input="(value) => setValue('statusOptions', value)"
                  />
              </v-col>
              <v-col cols="12" :sm="12">
                <TextAreaField
                  :loading="loadings.comments"
                  :disabled="disablements.comments"
                  :value="values.comments"
                  :label="'Observação'"
                  :type="'text'"
                  :noRule="true"
                  @loading:input="(status) => setLoading('comments', status)"
                  @disabled:input="(status) => setDisabled('comments', status)"
                  @change:input="(value) => setValue('comments', value)"
                  />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                <Photos 
                  ref="before_photo"
                  :title="'Foto Antes'"
                  :path="`${this.folderProposal}/solutions/before`"
                  :photos="values.photos.before"
                  :max="1"
                  @photos:loading="(status) => setLoading('photos', status)"
                />
              </v-col>
              <v-col cols="6">
                <Photos 
                  ref="after_photo"
                  :title="'Foto Depois'"
                  :path="`${this.folderProposal}/solutions/after`"
                  :photos="values.photos.after"
                  :max="1"
                  @photos:loading="(status) => setLoading('photos', status)"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <Photos 
                  ref="extras_photos"
                  :title="'Fotos Extras'"
                  :path="`${this.folderProposal}/solutions/extras`"
                  :photos="values.photos.extras"
                  :multiple="true"
                  @photos:loading="(status) => setLoading('photos', status)"
                />
              </v-col>
            </v-row>

          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeModal()" :loading="loadings._cancel" :disabled="disablements._cancel">
              Cancelar
            </v-btn>
            <v-btn  color="blue darken-1" text @click="saveModal()" :loading="loadings._save" :disabled="disablements._save"> Salvar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-form>
</template>

<script>
import { mapActions } from "vuex";
import Rules from "@/utils/Rules";
import { showDeleteDialogAlert } from "@/utils/DialogAlertHelper";
import { showErrorSnackBar, showSuccessSnackBar } from "@/utils/SnackBartHelper"
import ObjectValidator from '@/scripts/ObjectValidator';
import Utils from '@/scripts/Utils';
import LocalField from "@/components/Waterfix/Fields/LocalField.vue";
import SublocalField from "@/components/Waterfix/Fields/SublocalField.vue";
import PointOptionsField from "@/components/Waterfix/Fields/PointOptionsField.vue";
import SolutionOptionsField from "@/components/Waterfix/Fields/SolutionOptionsField.vue";
import CategoryOptionsField from "@/components/Waterfix/Fields/CategoryOptionsField.vue";
import StatusOptionsField from "@/components/Waterfix/Fields/StatusOptionsField.vue";
import TextField from "@/components/Waterfix/Fields/TextField.vue";
import TextAreaField from "@/components/Waterfix/Fields/TextAreaField.vue";
import DateField from "@/components/Waterfix/Fields/DateField.vue";
import Photos from "@/components/FormsComponents/Photos.vue";
import VueHorizontal from 'vue-horizontal';

export default {
  components: {
    LocalField,
    SublocalField,
    PointOptionsField,
    SolutionOptionsField,
    CategoryOptionsField,
    StatusOptionsField,
    TextField,
    TextAreaField,
    VueHorizontal,
    Photos,
    DateField,
  },
  props: {
    dialog: {
      default: false,
      type: Boolean,
    },
    proposalId: {
      type: String,
      required: true,
    },
    pointsOptions: {
      type: Array,
      required: true,
      default: () => [],
    },
    solutionsOptions: {
      type: Array,
      required: true,
      default: () => [],
    },
    solution: {
      type: Object,
      required: true,
      default: () => ObjectValidator.create('waterfix_checklist_solution'),
    },
    proposal: {
      required: true,
    },
  },

  data: () => ({
    validForm: true,
    // -------------------------------------------------------
    registration: '',
    id: '',
    loadings: {
      local: false,
      sublocal: false,
      pointOptions: false,
      solutionOptions: false,
      amountSolution: false,
      flowRateBefore: false,
      flowRateAfter: false,
      date: false,
      statusOptions: false,
      comments: false,
      photos: false,
    },
    disablements: {
      local: false,
      sublocal: false,
      pointOptions: false,
      solutionOptions: false,
      amountSolution: false,
      flowRateBefore: false,
      flowRateAfter: false,
      date: false,
      statusOptions: false,
      comments: false,
      photos: false,
    },
    values: {
      local: {
        id: null,
        text: null,
      },
      sublocal: {
        id: null,
        text: null,
      },
      pointOptions: {
        id: null,
        name: null,
      },
      solutionOptions: {
        id: null,
        name: null,
      },
      amountSolution: null,
      flowRateBefore: null,
      flowRateAfter: null,
      economy: null,
      date: null,
      statusOptions: null,
      comments: null,
      photos: {
        before: [],
        after: [],
        extras: [],
      },
    },
    folderProposal: '',
  }),
  methods: {
    ...mapActions([
        'publishChannel',
    ]),
    setLoading(key, status) {
      this.loadings[key] = status
    },
    setAllLoading(status) {
      Object.keys(this.loadings).forEach(key => this.setLoading(key, status))
    },
    setDisabled(key, status) {
      this.disablements[key] = status
    },
    setAllDisabled(status) {
      Object.keys(this.disablements).forEach(key => this.setDisabled(key, status))
    },
    setValue(key, value) {
      this.values[key] = value
    },
    resetSolutionReport() {
      this.loadings = {
        local: false,
        sublocal: false,
        pointOptions: false,
        solutionOptions: false,
        amountSolution: false,
        flowRateBefore: false,
        flowRateAfter: false,
        date: false,
        statusOptions: false,
        comments: false,
        photos: false,
      }
      this.disablements = {
        local: false,
        sublocal: false,
        pointOptions: false,
        solutionOptions: false,
        amountSolution: false,
        flowRateBefore: false,
        flowRateAfter: false,
        date: false,
        statusOptions: false,
        comments: false,
        photos: false,
      },
      this.values = {
        local: {
          id: null,
          text: null,
        },
        sublocal: {
          id: null,
          text: null,
        },
        pointOptions: {
          id: null,
          name: null,
        },
        solutionOptions: {
          id: null,
          name: null,
        },
        amountSolution: null,
        flowRateBefore: null,
        flowRateAfter: null,
        economy: null,
        date: null,
        statusOptions: null,
        comments: null,
        photos: {
          before: [],
          after: [],
          extras: [],
        },
      }
    },
    saveSolution() {
      var solution = {}
      solution.local = this.values.local.text
      solution.sub_local = this.values.sublocal.text
      solution.type = this.values.pointOptions.id
      solution.solution = this.values.solutionOptions.id
      solution.amountSolution = this.values.amountSolution
      solution.flowRateBefore = this.values.flowRateBefore
      solution.flowRateAfter = this.values.flowRateAfter
      solution.economy = this.values.economy
      // solution.category = this.values.categoryOptions
      solution.date = this.values.date
      solution.status = this.values.statusOptions
      solution.comments = this.values.comments
      // -------------------------------------------------------------------------
      // Buscar as fotos
      for (let photo of this.$refs.before_photo.getRemovedPhotos()) {
        this.values.photos.before = this.values.photos.before.filter((f) => f.name !== photo.name)
      }
      for (let photo of this.$refs.before_photo.getNewPhotos()) {
        this.values.photos.before.push(photo)
      }

      for (let photo of this.$refs.after_photo.getRemovedPhotos()) {
        this.values.photos.after = this.values.photos.after.filter((f) => f.name !== photo.name)
      }
      for (let photo of this.$refs.after_photo.getNewPhotos()) {
        this.values.photos.after.push(photo)
      }

      for (let photo of this.$refs.extras_photos.getRemovedPhotos()) {
        this.values.photos.extras = this.values.photos.extras.filter((f) => f.name !== photo.name)
      }
      for (let photo of this.$refs.extras_photos.getNewPhotos()) {
        this.values.photos.extras.push(photo)
      }
      solution.photos = this.values.photos
      this.publishChannel({
        channel: 'waterfix',
        event: `single-solutions`,
        value: Utils.copyObject(solution),
      })
    },
    async publishSolutions() {
      this.setAllLoading(true)
      this.setAllDisabled(true)
      this.saveSolution()
      this.publishCloseModal()
      this.setAllLoading(false)
      
    },
    publishCloseModal() {
      this.publishChannel({
        channel: 'waterfix',
        event: `single-solutions:modal`,
        value: false,
      })
      this.resetSolutionReport()
    },
    saveModal() {
      let validate = this.$refs.form.validate()
      if (!validate) return
      this.publishSolutions()
    },
    async closeModal() {
      // Só verificar se pode salva a proposta caso é uma proposta que tenha os dados obrigatório
      if (this.values.local.id !== null && this.values.sublocal.id !== null) {
        if (
          this.$refs.before_photo.getRemovedPhotos().length > 0 || 
          this.$refs.before_photo.getNewPhotos().length > 0 ||
          this.$refs.after_photo.getRemovedPhotos().length > 0 || 
          this.$refs.after_photo.getNewPhotos().length > 0 ||
          this.$refs.extras_photos.getRemovedPhotos().length > 0 || 
          this.$refs.extras_photos.getNewPhotos().length > 0
        ) {
          this.saveSolution()
        }
      } else { // Caso seja uma nova proposta, então remova todas as referencias das fotos
        await this.$refs.before_photo.removeAllPhotos()
        await this.$refs.after_photo.removeAllPhotos()
        await this.$refs.extras_photos.removeAllPhotos()
      }
      this.publishCloseModal()
    },
    setEconomy(flowRateAfter, flowRateBefore) {
      if (!flowRateAfter) flowRateAfter = 0
      if (!flowRateBefore) flowRateBefore = 0
      if (flowRateBefore == 0) return 0
      let economy = 100 - ((flowRateAfter / flowRateBefore) * 100)
      economy = parseFloat(Math.abs(economy).toFixed(2))
      this.values.economy = economy
    }
  },
  computed: {
    rulesRequired() {
      return Rules.required();
    },
    rulesValueRequired() {
        return Rules.number(true, 0)
    },
    rulesValue() {
        return Rules.number(false, 0)
    },
    solutionsOptionsList() {
      return this.solutionsOptions.filter(({pointId}) => pointId === this.values.pointOptions.id)
    },
    hasLoading() {
      return Object.values(this.loadings).some(status => status)
    },
    hasDisabled() {
      return Object.values(this.disablements).some(status => status)
    },
  },
  watch: {
    solution: {
      immediate: true,
      handler: async function (newValue) {
        if (newValue) {
          this.values.local = {
            id: Utils.createSlug(newValue.local),
            text: newValue.local,
          }
          this.values.sublocal = {
            id: Utils.createSlug(newValue.sub_local),
            text: newValue.sub_local,
          }

          const point = this.pointsOptions.find(({ id }) => id === newValue.type)
          if (point) {
            this.values.pointOptions = {
              id: newValue.type,
              text: point.name,
            }
          } else {
            this.values.pointOptions = {
              id: null,
              text: null,
            }
          }
          
          const solution = this.solutionsOptions.find(({ id }) => id === newValue.solution)
          if (solution) {
            this.values.solutionOptions = {
              id: newValue.solution,
              text: solution.solution,
            }
          } else {
            this.values.solutionOptions = {
              id: null,
              text: null,
            }
          }

          this.values.amountSolution = newValue.amountSolution
          this.values.flowRateBefore = newValue.flowRateBefore
          this.values.flowRateAfter = newValue.flowRateAfter
          this.values.economy = newValue.economy
          this.values.date = newValue.date
          this.values.statusOptions = newValue.status
          this.values.comments = newValue.comments
          this.values.photos = newValue.photos
        }
      }
    },
    'values.flowRateBefore': function (newVal) {
      this.setEconomy(this.values.flowRateAfter, newVal)
    },
    'values.flowRateAfter': function (newVal) {
      this.setEconomy(newVal, this.values.flowRateBefore)
    },
    proposal: {
      immediate: true,
      handler: function (newValue) {
        if (newValue) {
          this.registration = newValue.registration
          this.id = newValue.id
          this.folderProposal  = `water_fix_imgs/${this.registration}/${this.id}`
        }
      }
    },
  },
  async created() {
    this.resetSolutionReport()
  }
};
</script>