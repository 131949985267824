import { db } from "@/firebase"

async function checkRegistration(registration) {
    try {
      const querySnapshot = await db.collection("water_fix")
        .where("registration", "==", registration)
        .get();
      return (!querySnapshot.empty);
    } catch (error) {
      return (error)
    }
}

async function createProposal(Proposal) {
    try {
        const doc_ref = await db.collection("water_fix").add(Proposal);
        let id = doc_ref.id;
        await db
            .collection("water_fix")
            .doc(id)
            .update({ id });
        return id;
    } catch (error) {
        return (error)
    }
}

async function updateProposal(id, data) {
    try {
        await db
            .collection("water_fix")
            .doc(id)
            .update(data);
        return true;
    } catch (e) {
        console.error("updateProposal", e)
        return e;
    }
}

async function getProposalByRegistration(registration) {
    try {
        let response = null
        let querySnapshot = await db.collection("water_fix")
          .where("registration", "==", registration)
          .get();
        querySnapshot.forEach((doc) => {
          response = doc.data();
        });
        return (response);
    } catch (error) {
        return (error)
    }
}

async function getProposalById(proposalId) {
  try {
      let response = null
      let querySnapshot = await db.collection("water_fix")
        .where("id", "==", proposalId)
        .get();
      querySnapshot.forEach((doc) => {
        response = doc.data();
      });
      return (response);
  } catch (error) {
      return (error)
  }
}

async function updateSolutionListById(dataList,idDoc) {
    try {
        await db
            .collection("water_fix")
            .doc(idDoc)
            .update({
                solutionList: dataList
            });

    } catch (e) {
        console.error("updateSolutionListById", e)
    }
}

async function getWaterFixProposalByRegister(register) {
    try {
        let proposal = []
        let ref = await db
            .collection("water_fix")
            .where("registration", "==", register)
            .get();
            
        ref.forEach((doc) => proposal = doc.data());

        return proposal
    } catch (e) {
        console.error("getWaterFixProposalByRegister", e)
    }
}

async function deleteProposal(registration) {
    try {
      let ref = await db
        .collection("water_fix")
        .where("registration", "==", registration)
        .get();
  
      let id = ''
      ref.forEach((doc) => {
        id = doc['id']
      });
      await db
        .collection("water_fix")
        .doc(id)
        .delete();
      return 0;
    } catch (error) {
      console.error(error);
      return 1;
    }
  }

export default {
    checkRegistration,
    createProposal,
    updateProposal,
    getProposalByRegistration,
    getWaterFixProposalByRegister,
    updateSolutionListById,
    deleteProposal,
    getProposalById,
}
