<template>
    <div>
        <BatchSolutions
            :dialog="batch_dialog"
            :proposalId="id"
            :pointsOptions="pointsData"
            :solutionsOptions="solutionsData"
            :proposal="proposal"
        />
        <SingleSolutions
            :dialog="single_dialog"
            :proposalId="id"
            :pointsOptions="pointsData"
            :solutionsOptions="solutionsData"
            :solution="single_solution"
            :proposal="proposal"
        />

        <div
            id="itemAnimado"
            class="item-escondido"
            style="
                position: fixed;
                width: 100%;
                bottom: 5vh;
                display: flex;
                justify-content: center;
                z-index: 1000;
            "
        >
            <div
                style="
                    background-color: rgb(25, 118, 210);
                    border-radius: 5px;
                    box-shadow: rgba(0, 0, 0, 0.8) 5px 5px 10px 2px;
                    height: 60px;
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    padding: 6px;
                "
            >
                <v-btn 
                    @click="() => openSingleRegisterModal()"
                    v-if="showAddButton"
                    style="
                        margin: 0px 5px;
                    "
                >
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
                
                <v-btn 
                    @click="openBatchEditModal"
                    v-if="showEditButton"
                    style="
                        margin: 0px 5px;
                    "
                >
                    <v-icon>edit</v-icon>
                </v-btn>

                <v-btn
                    @click="openAlertRemoveBatchSolutions" 
                    v-if="showDeleteButton"
                    style="
                        margin: 0px 5px;
                    "
                >
                    <v-icon> mdi-delete </v-icon>
                </v-btn>
            </div>
        </div>

        <v-row>
            <v-toolbar id="solution-list" flat class="rounded-lg">
                <v-toolbar-title>Consumo das Soluções</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    class="mb-2"
                    text
                    @click="() => openSingleRegisterModal()"
                >
                  <v-icon>add</v-icon>
                </v-btn>
                <v-btn
                    color="primary"
                    class="mb-2"
                    text
                    @click="openBatchEditModal"
                    :disabled="!batch_edit_mode"
                >
                    <v-icon>edit</v-icon>
                </v-btn>
                <v-btn
                    color="primary"
                    class="mb-2"
                    text
                    @click="openAlertRemoveBatchSolutions"
                    :disabled="!batch_edit_mode"
                >
                    <v-icon> mdi-delete </v-icon>
                </v-btn>
            </v-toolbar>
        </v-row>

        <div
            style="
                display: flex;
                padding: 0 20px;
            "
        >
            <div
                style="
                    flex: 1;
                    display: flex;
                "
            >
                <div 
                    style="
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-right: 10px;
                    "
                >
                    <v-icon
                        style="
                            font-size: 40px;
                        "
                    >mdi-select-all</v-icon>
                </div>
                <div>
                    <v-switch
                        v-model="batch_all_locals"
                        :label="`Selecionar todas as soluções`"
                        @change="(status) => catchBatchAllLocalEdit(status)"
                    ></v-switch>
                </div>
            </div>
    
            <div
                style="
                    flex: 1;
                    display: flex;
                "
            >
                <div
                    style="
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-right: 10px;
                    "
                >
                    <v-icon
                        style="
                            font-size: 40px;
                        "
                    >mdi-arrow-expand-all</v-icon>
                </div>
                <div>
                    <v-switch
                        v-model="expand_all"
                        :label="`Expandir todos locais`"
                        @change="(status) => expandAll(status)"
                    ></v-switch>
                </div>
            </div>
        </div>

        <v-container fluid>
            <v-row
                align="center"
            >
                <v-col cols="12">
                    <v-autocomplete
                        v-model="values_locals"
                        :items="locals"
                        outlined
                        dense
                        chips
                        small-chips
                        label="Local"
                        multiple
                    ></v-autocomplete>
                </v-col>
            </v-row>
        </v-container>

        <v-row>
            <v-expansion-panels
                v-model="localPanels"
                multiple
            >
                <v-expansion-panel
                    v-for="({ local, isViewLocal, sub_locals }, i) in list"
                    :key="i"
                    style="background-color: rgba(0, 0, 0, .05);"
                >
                    <v-expansion-panel-header v-if="isViewLocal"><b>{{ local }}</b></v-expansion-panel-header>
                    <v-expansion-panel-content v-if="isViewLocal">
                        <OSList
                            :proposal="proposal"
                            :local="local"
                        />

                        <div
                            style="
                                display: flex;
                                padding: 0 20px;
                            "
                        >
                            <div
                                style="
                                    flex: 1;
                                    display: flex;
                                "
                            >
                                <div 
                                    style="
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        margin-right: 10px;
                                    "
                                >
                                    <v-icon
                                        style="
                                            font-size: 40px;
                                        "
                                    >mdi-select-all</v-icon>
                                </div>
                                <div>
                                    <v-switch
                                        v-model="status_local[local].batchEdit"
                                        :disabled="status_local[local].disableBatch"
                                        :label="`Selecionar todas as soluções`"
                                        @change="(status) => catchBatchLocalEdit(local, status)"
                                    ></v-switch>
                                </div>
                            </div>
                    
                            <div
                                style="
                                    flex: 1;
                                    display: flex;
                                "
                            >
                                <div
                                    style="
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        margin-right: 10px;
                                    "
                                >
                                    <v-icon
                                        style="
                                            font-size: 40px;
                                        "
                                    >mdi-arrow-expand-all</v-icon>
                                </div>
                                <div>
                                    <v-switch
                                        v-model="sublocal_expand_all[i]"
                                        :label="`Expandir todos sublocais`"
                                        @change="(status) => expandAllSublocals(i, status)"
                                    ></v-switch>
                                </div>
                            </div>
                        </div>
                        <!-- Os dados apareceram aqui -->
                        <v-container fluid>
                            <v-row
                                align="center"
                            >
                                <v-col cols="12">
                                    <v-autocomplete
                                        v-model="values_sub_locals_by_local[local]"
                                        :items="sub_locals_by_local[local]"
                                        outlined
                                        dense
                                        chips
                                        small-chips
                                        label="Sub Locais"
                                        multiple
                                        @change="() => changeSubLocal(local)"
                                    ></v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-container>
                        
                        <v-row justify="center">
                            <v-expansion-panels 
                                v-model="sublocalPanels[i]" 
                                multiple
                            >
                                <v-expansion-panel
                                    v-for="({ sub_local, isViewSublocal, items }, j) in sub_locals"
                                    :key="j"
                                >
                                    <v-expansion-panel-header v-if="isViewSublocal"><b>{{ sub_local }}</b></v-expansion-panel-header>
                                    <v-expansion-panel-content v-if="isViewSublocal">
                                        <div
                                            style="
                                                display: flex;
                                                padding: 0 20px;
                                            "
                                        >
                                            <div
                                                style="
                                                    flex: 1;
                                                    display: flex;
                                                "
                                            >
                                                <div 
                                                    style="
                                                        display: flex;
                                                        justify-content: center;
                                                        align-items: center;
                                                        margin-right: 10px;
                                                    "
                                                >
                                                    <v-icon
                                                        style="
                                                            font-size: 40px;
                                                        "
                                                    >mdi-select-all</v-icon>
                                                </div>
                                                <div>
                                                    <v-switch
                                                        v-model="status_sublocal_by_local[local][sub_local].batchEdit"
                                                        :disabled="status_sublocal_by_local[local][sub_local].disableBatch"
                                                        :label="`Selecionar todas as soluções`"
                                                        @change="(status) => catchBatchSublocalEdit(local, sub_local, status)"
                                                    ></v-switch>
                                                </div>
                                            </div>
                                        </div>
                                        <v-data-table
                                            :headers="headers"
                                            :items="items"
                                            class="elevation-1"
                                            style="width: 100%"
                                            :items-per-page="-1"
                                            >
                                            <template v-slot:body="{ items }">
                                                <tbody>
                                                    <tr v-for="(report, index) in items" :key="index">
                                                        <td>
                                                            <v-checkbox
                                                                v-model="status_solutions_by_sublocal[local][sub_local][index].batchEdit"
                                                                :disabled="status_solutions_by_sublocal[local][sub_local][index].disableBatch"
                                                                @change="(status) => catchBatchSolutionEdit(local, sub_local, index, status)"
                                                            ></v-checkbox>
                                                        </td>
                                                        <td>
                                                        <v-text-field   
                                                            disabled
                                                            :value="convertNameType(report.type)"
                                                        />
                                                        </td>
                                                        <td>
                                                        <v-text-field
                                                            disabled
                                                            :value="convertSolutionName(report.solution)"
                                                        />
                                                        </td>
                                                        <td>
                                                        <v-text-field 
                                                            disabled
                                                            :value="report.category"
                                                        >
                                                        </v-text-field>
                                                        </td>
                                                        <td>
                                                        <v-text-field 
                                                            disabled
                                                            :value="report.status"
                                                        >
                                                        </v-text-field>
                                                        </td>
                                                        <td>
                                                        <v-text-field 
                                                            disabled
                                                            type="Number" 
                                                            v-model="report.amountSolution"
                                                        >
                                                        </v-text-field>
                                                        </td>
                                                        <td>
                                                        <v-text-field
                                                            disabled
                                                            type="Number"
                                                            v-model="report.flowRateBefore"
                                                            suffix="L/min"
                                                        >
                                                        </v-text-field>
                                                        </td>
                                                        <td>
                                                        <v-text-field
                                                            disabled
                                                            type="Number"
                                                            v-model="report.flowRateAfter"
                                                            suffix="L/min"
                                                        >
                                                        </v-text-field>
                                                        </td>
                                                        <td width="90px">
                                                        <v-text-field
                                                            disabled
                                                            type="Number"
                                                            v-model="report.economy"
                                                            suffix="%"
                                                        >
                                                        </v-text-field>
                                                        </td>
                                                        <td>
                                                        <v-text-field disabled v-model="report.comments"> </v-text-field>
                                                        </td>
                                                        <td>
                                                        <v-icon :color="changeColorIconListImg(report)" small> mdi-image </v-icon>
                                                        </td>
                                                        <td>
                                                        <v-btn
                                                            elevation="2"
                                                            @click="() => openSingleEditModal(local, sub_local, index, report)"
                                                            :disabled="batch_edit_mode"
                                                            style="height: 30px; margin: 2px"
                                                        >
                                                            <v-icon color="primary" small> mdi-pencil </v-icon>
                                                        </v-btn>
                                                        <v-btn
                                                            elevation="2"
                                                            @click="() => openAlertRemoveSingleSolutions(local, sub_local, index)"
                                                            :disabled="batch_edit_mode"
                                                            style="height: 30px; margin: 2px"
                                                        >
                                                            <v-icon color="primary" small> mdi-delete </v-icon>
                                                        </v-btn>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-data-table>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-row>

                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>

        </v-row>
        <v-divider style="margin-top: 8px"></v-divider>
    </div>
  </template>
  <script>
import { mapActions } from "vuex";
import SolutionsServices from '@/services/firebase/SolutionsServices/SolutionsServices';
import SolutionsPoint from '@/services/firebase/SolutionsPoint/SolutionsPoint';
import { showDeleteDialogAlert } from "@/utils/DialogAlertHelper";
import Utils from "@/scripts/Utils";

import BatchSolutions from './ConsumptionSolutions/BatchSolutions.vue';
import SingleSolutions from './ConsumptionSolutions/SingleSolutions.vue';
import OSList from "./ConsumptionSolutions/OSList.vue";
import ObjectValidator from '@/scripts/ObjectValidator';
import StorageFile from '@/services/storage/StorageFile';


const findItemIndex = (items, key, name) => items.findIndex((item) => item[key] == name)

export default {
    name: "consumptionSolutions",
    components: {
        BatchSolutions,
        SingleSolutions,
        OSList,
    },
    props: {
        proposal: {
            required: true,
        },
    },
    data: function () {
        return {

            localPanels: [],
            sublocalPanels: {},
            id: "",
            key: 'solutionList',
            solutionList: [],
            list: [],
            
            locals: [],
            values_locals: [],
            status_local: {},
            batch_all_locals: false,
            batch_edit_mode: false,
            batch_dialog: false,
            is_edit_batch: false,
            is_remove_batch: false,

            expand_all: false,
            sublocal_expand_all: {},
            select_all: false,
            sublocal_select_all: {},
            
            sub_locals_by_local: {},
            values_sub_locals_by_local: {},
            status_sublocal_by_local: {},
            status_solutions_by_sublocal: {},

            solution_list_map: [],
            solution_list_edit: [],

            single_dialog: false,
            single_edit_mode: false,
            is_register_single: false,
            is_edit_single: false,
            is_remove_single: false,
            single_solution: ObjectValidator.create('waterfix_checklist_solution'),

            headers: [
                { text: "Selecionar", value: "action", width: 100, sortable: false },
                { text: "Tipo", value: "name", width: 130, sortable: false },
                { text: "Solução", value: "solution", width: 200, sortable: false },
                { text: "Categoria", value: "category", width: 150, sortable: false },
                { text: "Status", value: "status", width: 150, sortable: false },
                { text: "Quantidade", value: "amount", width: 100, sortable: false },
                { text: "Vazão Antes", value: "flowRateBefore", width: 130, sortable: false },
                { text: "Vazão Depois", value: "flowRateAfter", width: 130, sortable: false },
                { text: "Economia (%) ", value: "economy", width: 115, sortable: false },
                { text: "Observação", value: "comments", sortable: false },
                { text: "Img", value: "Img", sortable: false },
                { text: "Ações", value: "action", width: 180, sortable: false },
            ],
            pointsData: [],
            solutionsData: [],

            interval: null,
            showCheckListButtons: false,
            showEditButton: false,
            showAddButton: false,
            showDeleteButton: false,
        };
    },
    computed: {

    },
    methods: {
        ...mapActions([
            'subscribeChannel',
            'publishChannel',
        ]),
        closeMenuBatch() {
            this.catchBatchAllLocalEdit(false)
        },
        async expandAll(status) {
            this.publishChannel({
                channel: 'waterfix',
                event: `consumption-solutions:loading`,
                value: true,
            })
            await Utils.sleep(250)
            if (status) {
                await this.openAllLocals()
            } else {
                this.closeAllLocals()
            }
            this.publishChannel({
                channel: 'waterfix',
                event: `consumption-solutions:loading`,
                value: false,
            })
        },
        async expandAllSublocals(localIndex, status) {
            this.publishChannel({
                channel: 'waterfix',
                event: `consumption-solutions:loading`,
                value: true,
            })
            await Utils.sleep(250)
            if (status) {
                this.openAllSublocals(localIndex)
            } else {
                this.closeAllSublocals(localIndex)
            }
            this.publishChannel({
                channel: 'waterfix',
                event: `consumption-solutions:loading`,
                value: false,
            })
        },
        convertNameType(pointId) {
            const type = this.pointsData.filter(({id}) => id == pointId)
            return type.length > 0? type[0].name : "Não Existe"
        },
        convertSolutionName(solutionId) {
            const solutionFilter = this.solutionsData.filter(({id}) => id == solutionId)
            return solutionFilter.length > 0 ? solutionFilter[0].solution : "Não Existe"
        },
        changeColorIconListImg(line) {
            return !line.hasOwnProperty('photos') ? "" : (line.photos.hasOwnProperty('before') && line.photos.before.length > 0) || (line.photos.hasOwnProperty('after') && line.photos.after.length > 0) || (line.photos.hasOwnProperty('extras') && line.photos.extras.length > 0) ? "primary" : ""
        },
        async listSolutions(){
            this.solutionsData =  await SolutionsServices.GetSolutions()
        },
        async listPoints(){
            let pointIds = this.solutionsData.map((item) => item.type == 'Produto' ? item.pointId : null).filter((item) => item !== null && item !== undefined)
            let response =  await SolutionsPoint.GetSolutionsPointByIds(pointIds)
            this.pointsData = Object.values(response)
        },
        sanitizeName(name) {
            if (!name) return ""
            return Utils.defaultUpper(Utils.defaultSpace(Utils.clearAccents(name)))
        },
        parseSolutionList() {
            let _list = []
            this.status_local = {}
            this.status_sublocal_by_local = {}
            this.status_solutions_by_sublocal = {}
            this.solution_list_map = []
            this.solutionList.forEach(
                (item, solutionIndex) => {
                    let local = this.sanitizeName(item.local)
                    let local_index = findItemIndex(_list, 'local', local)
                    let sub_local = this.sanitizeName(item.sub_local)
                    let sub_local_index = -1
                    if (local_index < 0) {
                        _list.push({
                            local,
                            isViewLocal: true,
                            sub_locals: []
                        })
                        local_index = _list.length - 1
                        _list[local_index].sub_locals.push({
                            sub_local,
                            isViewSublocal: true,
                            items: []
                        })
                        sub_local_index = _list[local_index].sub_locals.length - 1
                    } else {
                        sub_local_index = findItemIndex(_list[local_index].sub_locals, 'sub_local', sub_local)
                        if (sub_local_index < 0) {
                            _list[local_index].sub_locals.push({
                                sub_local,
                                isViewSublocal: true,
                                items: []
                            })
                            sub_local_index = _list[local_index].sub_locals.length - 1
                        }
                    }
                    _list[local_index].sub_locals[sub_local_index].items.push({
                        ...item,
                        local,
                        sub_local,
                    })
                    this.solution_list_map.push({
                        local,
                        sub_local,
                        index: _list[local_index].sub_locals[sub_local_index].items.length - 1,
                        solutionIndex,
                    })

                    if (!this.locals.includes(local)) {
                        this.locals.push(local)
                        this.sub_locals_by_local[local] = []
                        this.values_sub_locals_by_local[local] = []
                    }
                    if (!this.sub_locals_by_local[local].includes(sub_local)) {
                        this.sub_locals_by_local[local].push(sub_local)
                    }

                    if (!this.status_local.hasOwnProperty(local)) {
                        this.status_local[local] = {
                            batchEdit: false,
                            disableBatch: false,
                        }
                        this.status_sublocal_by_local[local] = {}
                        this.status_solutions_by_sublocal[local] = {}
                    }
                    if (!this.status_sublocal_by_local[local].hasOwnProperty(sub_local)) {
                        this.status_sublocal_by_local[local][sub_local] = {
                            batchEdit: false,
                            disableBatch: false,
                        }
                        this.status_solutions_by_sublocal[local][sub_local] = {}
                    }
                    this.status_solutions_by_sublocal[local][sub_local][_list[local_index].sub_locals[sub_local_index].items.length-1] = {
                        batchEdit: false,
                        disableBatch: false,
                    }
                }
            );
            this.list = _list
        },
        showLocals(locals = []) {
            if (locals.length > 0) {
                this.list = this.list.map(
                    (item) => {
                        if (locals.includes(item.local)) {
                            return {
                                ...item,
                                isViewLocal: true
                            }
                        } else {
                            return {
                                ...item,
                                isViewLocal: false
                            }
                        }
                    }
                )
            } else {
                this.list = this.list.map(
                    (item) => {
                        return {
                            ...item,
                            isViewLocal: true
                        }
                    }
                )
            }
        },
        showSublocals(local, sublocals = []) {
            if (sublocals.length > 0) {
                this.list = this.list.map(
                    (localItem) => {
                        let _localItem = localItem
                        if (local === _localItem.local) {
                            _localItem.sub_locals = _localItem.sub_locals.map(
                                (sublocalItem) => {
                                    if (sublocals.includes(sublocalItem.sub_local)) {
                                        return {
                                            ...sublocalItem,
                                            isViewSublocal: true
                                        }
                                    } else {
                                        return {
                                            ...sublocalItem,
                                            isViewSublocal: false
                                        }
                                    }
                                }
                            )
                        }
                        return _localItem
                    }
                )
            } else {
                this.list = this.list.map(
                    (localItem) => {
                        let _localItem = localItem
                        if (local === _localItem.local) {
                            _localItem.sub_locals = _localItem.sub_locals.map(
                                (sublocalItem) => {
                                    return {
                                        ...sublocalItem,
                                        isViewSublocal: true
                                    }
                                }
                            )
                        }
                        return _localItem
                    }
                )
            }
        },
        changeSubLocal(local) {
            this.showSublocals(local, this.values_sub_locals_by_local[local])
        },
        catchBatchAllLocalEdit(status) {
            this.solution_list_edit = []
            Object.keys(this.status_local).forEach(
                (local) => {
                    this.status_local[local].disableBatch = false
                    this.status_local[local].batchEdit = status
                    Object.keys(this.status_sublocal_by_local[local]).forEach(
                        (sublocal) => {
                            this.status_sublocal_by_local[local][sublocal].disableBatch = false
                            this.status_sublocal_by_local[local][sublocal].batchEdit = status
                            Object.keys(this.status_solutions_by_sublocal[local][sublocal]).forEach(
                                (index) => {
                                    this.status_solutions_by_sublocal[local][sublocal][index].disableBatch = false
                                    this.status_solutions_by_sublocal[local][sublocal][index].batchEdit = status
                                    if (status) {
                                        this.solution_list_edit.push({
                                            local,
                                            sub_local: sublocal,
                                            index,
                                        })
                                    }
                                }
                            )
                        }
                    )
                }
            )
            this.status_local = {...this.status_local}
            this.status_sublocal_by_local = {...this.status_sublocal_by_local}
            this.status_solutions_by_sublocal = {...this.status_solutions_by_sublocal}
            this.checkBatchEditMode()
            this.$forceUpdate();

        },  
        catchBatchLocalEdit(local, status) {
            // --------------------------------------------------------------------------
            // Editando os status
            Object.keys(this.status_sublocal_by_local[local]).forEach(
                (sublocal) => {
                    this.status_sublocal_by_local[local][sublocal].disableBatch = false
                    this.status_sublocal_by_local[local][sublocal].batchEdit = status
                    Object.keys(this.status_solutions_by_sublocal[local][sublocal]).forEach(
                        (index) => {
                            this.status_solutions_by_sublocal[local][sublocal][index].disableBatch = false
                            this.status_solutions_by_sublocal[local][sublocal][index].batchEdit = status
                        }
                    )
                    this.status_solutions_by_sublocal = {...this.status_solutions_by_sublocal}
                }
            )
            this.status_sublocal_by_local = {...this.status_sublocal_by_local}
            // --------------------------------------------------------------------------
            // Editando a lista de item para editar
            this.solution_list_edit = this.solution_list_edit.filter((item) => item.local !== local)
            if (status) {
                let local_index = findItemIndex(this.list, 'local', local)
                this.list[local_index].sub_locals.forEach(
                    ({sub_local, items}) => {
                        items.forEach(
                            (_, index) => {
                                this.solution_list_edit.push({
                                    local,
                                    sub_local,
                                    index
                                })
                            }
                        )
                    }
                )
            }
            this.checkBatchEditMode()
            this.$forceUpdate();
        },
        catchBatchSublocalEdit(local, sublocal, status) {
            // --------------------------------------------------------------------------
            // Editando os status
            Object.keys(this.status_solutions_by_sublocal[local][sublocal]).forEach(
                (index) => {
                    this.status_solutions_by_sublocal[local][sublocal][index].disableBatch = false
                    this.status_solutions_by_sublocal[local][sublocal][index].batchEdit = status
                    
                }
            )
            this.status_solutions_by_sublocal = {...this.status_solutions_by_sublocal}
            // --------------------------------------------------------------------------
            // Editando a lista de item para editar
            this.solution_list_edit = this.solution_list_edit.filter(
                (item) => 
                    (item.local !== local) || 
                    (item.local === local && item.sub_local !== sublocal)
            )
            if (status) {
                let local_index = findItemIndex(this.list, 'local', local)
                let sub_local_index = findItemIndex(this.list[local_index].sub_locals, 'sub_local', sublocal)
                this.list[local_index].sub_locals[sub_local_index].items.forEach(
                    (_, index) => {
                        this.solution_list_edit.push({
                            local,
                            sub_local: sublocal,
                            index
                        })
                    }
                )
            }
            this.checkBatchEditMode()
            this.$forceUpdate();
        },
        catchBatchSolutionEdit(local, sublocal, index, status) {
            // --------------------------------------------------------------------------
            // Editando a lista de item para editar
            this.solution_list_edit = this.solution_list_edit.filter(
                (item) => 
                    (item.local !== local) || 
                    (item.sub_local !== sublocal) ||
                    (item.local === local && item.sub_local === sublocal && item.index !== index)
            )
            if (status) {
                this.solution_list_edit.push({
                    local,
                    sub_local: sublocal,
                    index
                })
            }
            this.checkBatchEditMode()
        },
        checkBatchEditMode() {
            let status = false
            Object.keys(this.status_solutions_by_sublocal).forEach(
                (local) => {
                    Object.keys(this.status_solutions_by_sublocal[local]).forEach(
                        (sublocal) => {
                            Object.keys(this.status_solutions_by_sublocal[local][sublocal]).forEach(
                                (index) => {
                                    status ||= this.status_solutions_by_sublocal[local][sublocal][index].batchEdit
                                }
                            )
                        }
                    )
                }
            )
            this.batch_edit_mode = status
            return status
        },
        openBatchEditModal() {
            this.batch_dialog = true
            this.is_edit_batch = true
        },
        calculatePercentageEconomy(flowRateBefore, flowRateAfter) {
            let percentage = ((flowRateBefore - flowRateAfter) / flowRateBefore) * 100;
            percentage = percentage > 0 ? Math.abs(percentage).toFixed(2) : 0;
            return percentage
        },
        mergeEditBatchSolutions(solution) {
            this.solution_list_edit.forEach(
                ({ local, sub_local, index }) => {
                    let solution_map = this.solution_list_map.find((item) => item.local === local && item.sub_local === sub_local && parseInt(item.index) == parseInt(index))
                    if (!solution_map) return
                    let solutionIndex = solution_map.solutionIndex
                    let _solution = this.solutionList[solutionIndex]

                    // ----------------------------------------------------
                    // Edição dos campos
                    if (solution.amountSolution) {
                        _solution.amountSolution = solution.amountSolution
                    }
                    if (solution.date) {
                        _solution.date = solution.date
                    }
                    if (solution.comments) {
                        _solution.comments = solution.comments
                    }
                    if (solution.local) {
                        _solution.local = solution.local
                    }
                    if (solution.solution) {
                        _solution.solution = solution.solution
                    }
                    if (solution.status) {
                        _solution.status = solution.status
                    }
                    if (solution.sub_local) {
                        _solution.sub_local = solution.sub_local
                    }
                    if (solution.type) {
                        _solution.type = solution.type
                    }
                    let flowRateBefore = -1
                    let flowRateAfter = -1
                    if (solution.flowRateBefore) {
                        flowRateBefore = parseFloat(solution.flowRateBefore)
                    }
                    if (solution.flowRateAfter) {
                        flowRateAfter = parseFloat(solution.flowRateAfter)
                    }
                    if (flowRateBefore < 0) {
                        flowRateBefore = parseFloat(_solution.flowRateBefore)
                    }
                    if (flowRateAfter < 0) {
                        flowRateAfter = parseFloat(_solution.flowRateAfter)
                    }
                    _solution.flowRateBefore = flowRateBefore
                    _solution.flowRateAfter = flowRateAfter
                    _solution.economy = this.calculatePercentageEconomy(flowRateBefore, flowRateAfter)

                    this.solutionList[solutionIndex] = _solution
                }
            )
            this.parseSolutionList()
            this.checkBatchEditMode()
            this.is_edit_batch = false
            this.is_remove_batch = false
            this.batch_all_locals = false
            this.solution_list_edit = []
            this.publishChannel({
                channel: 'waterfix',
                event: `consumption-solutions`,
                value: Utils.copyObject(this.solutionList),
            })
        },
        openAlertRemoveBatchSolutions() {
            showDeleteDialogAlert(`Deseja excluir as soluções?`, this.removeBatchSolutions)
        },
        removeBatchSolutions() {
            let solution_indexes = []
            this.solution_list_edit.forEach(
                ({ local, sub_local, index }) => {
                    let solution_map = this.solution_list_map.find((item) => item.local === local && item.sub_local === sub_local && parseInt(item.index) == parseInt(index))
                    if (!solution_map) return
                    let solutionIndex = solution_map.solutionIndex
                    solution_indexes.push(solutionIndex)
                }
            )
            this.solutionList = this.solutionList.filter(
                (_, index) => {
                    return !solution_indexes.includes(index)
                }
            )
            this.parseSolutionList()
            this.checkBatchEditMode()
            this.is_edit_batch = false
            this.is_remove_batch = false
            this.batch_all_locals = false
            this.solution_list_edit = []
            this.publishChannel({
                channel: 'waterfix',
                event: `consumption-solutions`,
                value: Utils.copyObject(this.solutionList),
            })
        },
        openSingleEditModal(local, sub_local, index, solution) {
            this.solution_list_edit = []
            this.solution_list_edit.push({
                local,
                sub_local,
                index,
            })
            // Verificação de estrutura da solução para as fotos
            if (solution.hasOwnProperty('imgNameList')) {
                delete solution['imgNameList']
            }
            if (!solution.hasOwnProperty('photos')) {
                solution['photos'] = {}
            }
            if (!solution.photos.hasOwnProperty('before')) {
                solution.photos['before'] = []
            }
            if (!solution.photos.hasOwnProperty('after')) {
                solution.photos['after'] = []
            }
            if (!solution.photos.hasOwnProperty('extras')) {
                solution.photos['extras'] = []
            }
            this.single_solution = Utils.copyObject(solution)
            this.single_edit_mode = true
            this.single_dialog = true
            this.is_edit_single = true
        },
        openSingleRegisterModal() {
            this.single_solution = ObjectValidator.create('waterfix_checklist_solution'),
            this.single_edit_mode = true
            this.single_dialog = true
            this.is_register_single = true
        },
        mergeEditSingleSolutions(solution) {
            if (this.is_edit_single) {
                let indexes = []
                this.solution_list_edit.forEach(
                    ({ local, sub_local, index }) => {
                        let solution_map = this.solution_list_map.find((item) => item.local === local && item.sub_local === sub_local && parseInt(item.index) == parseInt(index))
                        if (!solution_map) return
                        let solutionIndex = solution_map.solutionIndex
                        indexes.push(solutionIndex)
                    }
                )
                this.solutionList = this.solutionList.map(
                    (_solution, index) => {
                        if (!indexes.includes(index)) {
                            return _solution
                        } else {
                            return solution
                        }
                    }
                )
            } else if (this.is_register_single) {
                this.solutionList.push(solution)
            }
            this.parseSolutionList()
            this.single_edit_mode = false
            this.is_edit_single = false
            this.is_remove_single = false
            this.is_register_single = false
            this.solution_list_edit = []
            this.publishChannel({
                channel: 'waterfix',
                event: `consumption-solutions`,
                value: Utils.copyObject(this.solutionList),
            })
        },
        openAlertRemoveSingleSolutions(local, sub_local, index) {
            this.solution_list_edit = []
            this.solution_list_edit.push({
                local,
                sub_local,
                index,
            })
            showDeleteDialogAlert(`Deseja excluir a solução?`, this.removeSingleSolutions)
        },
        async removeSingleSolutions() {
            let indexes = []
            this.solution_list_edit.forEach(
                ({ local, sub_local, index }) => {
                    let solution_map = this.solution_list_map.find((item) => item.local === local && item.sub_local === sub_local && parseInt(item.index) == parseInt(index))
                    if (!solution_map) return
                    let solutionIndex = solution_map.solutionIndex
                    indexes.push(solutionIndex)
                }
            )
            // Remover as fotos
            for (let index in indexes) {
                let { photos } = this.solutionList[index]
                if (!photos) continue
                let filepaths = []
                if (photos.hasOwnProperty("before")) {
                    for (let { filename, path } of photos.before) {
                        filepaths.push(`${path}/${filename}`)
                    }
                }
                if (photos.hasOwnProperty("after")) {
                    for (let { filename, path } of photos.after) {
                        filepaths.push(`${path}/${filename}`)
                    }
                }
                if (photos.hasOwnProperty("extras")) {
                    for (let { filename, path } of photos.extras) {
                        filepaths.push(`${path}/${filename}`)
                    }
                }
                for (let filepath of filepaths) {
                    await StorageFile.deleteImg(filepath)
                }
            }
            this.solutionList = this.solutionList.filter(
                (_, index) => {
                    return !indexes.includes(index)
                }
            )
            this.parseSolutionList()
            this.single_edit_mode = false
            this.is_edit_single = false
            this.is_remove_single = false
            this.solution_list_edit = []
            this.publishChannel({
                channel: 'waterfix',
                event: `consumption-solutions`,
                value: Utils.copyObject(this.solutionList),
            })
        },
        async openAllLocals() {
            this.localPanels = this.list.map((_, index) => index)
            for (let i = 0; i < this.list.length; i++) {
                this.localPanels = this.list.slice(0, i + 1).map((_, index) => index)
                await Utils.sleep(50)
            }
        },
        closeAllLocals() {
            this.localPanels = []
        },
        async openAllSublocals(localIndex) {
            for (let i = 0; i < this.list[localIndex].sub_locals.length; i++) {
                this.sublocalPanels[localIndex] = this.list[localIndex].sub_locals.slice(0, i + 1).map((_, index) => index)
                await Utils.sleep(50)
            }
        },
        closeAllSublocals(localIndex) {
            this.sublocalPanels[localIndex] = []
        },

    },
    watch: {
        proposal(newVal) {
            if (newVal) {
                this.id = newVal.id
                try {
                    this.solutionList = Utils.getting(newVal, this.key)
                    this.solutionList = this.solutionList.map((solution) => ObjectValidator.verification('waterfix_checklist_solution', solution))
                } catch (e) {
                    this.solutionList = []
                }
                this.parseSolutionList()
            }
        },
        values_locals(newVal) {
            if (newVal) {
                this.showLocals(newVal)
            }
        },
        list(newVal) {
            if (newVal) {
                for (let i = 0; i < this.list.length; i++) {
                    // Adicionar a reatividade
                    if (!this.sublocalPanels.hasOwnProperty(i)) {
                        this.$set(this.sublocalPanels, i, [])
                        this.$set(this.sublocal_expand_all, i, false)
                    }
                }
            }
        },
        batch_edit_mode(newVal) {
            if (newVal) {
                this.showEditButton = true
                this.showDeleteButton = true
            } else {
                this.showEditButton = false
                this.showDeleteButton = false
            }
        },
        showAddButton(newVal) {
            this.showCheckListButtons = newVal || this.showEditButton || this.showDeleteButton
        },
        showEditButton(newVal) {
            this.showCheckListButtons = newVal || this.showAddButton || this.showDeleteButton
        },
        showDeleteButton(newVal) {
            this.showCheckListButtons = newVal || this.showEditButton || this.showAddButton
        },
        showCheckListButtons(newVal) {
            var item = document.getElementById('itemAnimado');
            if (newVal) {
                item.classList.remove('item-escondido');
                item.classList.add('item-visivel');
            } else {
                item.classList.remove('item-visivel');
                item.classList.add('item-escondido');
            }
        },
    },
    async created() {
        await this.listSolutions()
        await this.listPoints()

        this.subscribeChannel({
            channel: 'waterfix',
            events: [
                {
                    event: 'batch-solutions',
                    fn: (solution) => {
                        this.mergeEditBatchSolutions(solution)
                        this.closeMenuBatch()
                    }
                },
                {
                    event: 'batch-solutions:modal',
                    fn: (modal) => {
                        this.batch_dialog = modal
                    }
                },
                {
                    event: 'single-solutions',
                    fn: (solution) => {
                        this.mergeEditSingleSolutions(solution)
                    }
                },
                {
                    event: 'single-solutions:modal',
                    fn: (modal) => {
                        this.single_dialog = modal
                        this.solution_list_edit = []
                    }
                },
            ]
        })
    },
    mounted() {
        this.interval = setInterval(
            () => {
                const el = document.querySelector("#solution-list")
                const { top } = el.getBoundingClientRect();
                if (top <= -35) {
                    this.showAddButton = true
                } else {
                    this.showAddButton = false
                }
                this.showCheckListButtons = this.showAddButton || this.showEditButton || this.showDeleteButton
            },
            100
        )
    },
    beforeDestroy() {
        clearInterval(this.interval)
        this.interval = null
    }
};
</script>

<style scoped>
.item-escondido {
    transform: translateY(100vh); /* Posiciona o item fora da tela na parte inferior */
    transition: transform 0.5s ease-out; /* Suaviza a transição */
}
.item-visivel {
    transform: translateY(0); /* Move o item para sua posição final */
}
</style>