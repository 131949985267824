import Utils from '@/scripts/Utils';
import firebase from "@/firebase";

const createLabels = (startDate, endDate, mode) => {
    let labels = []
    let dates = []
    switch (mode) {
      case 'day':
        dates = Utils.generateDaysByInterval(startDate, endDate)
        labels = dates.map((date) => ({date, liters: 0, label: Utils.formatDate(date, '<dd>/<MM>')}))
      break;
      case 'month':
        dates = Utils.generateMonthsByInterval(startDate, endDate)
        labels = dates.map((date) => ({date, liters: 0, label: Utils.formatMonthDate(date, '<MM>/<YY>')}))
      break;
      case 'hour':
        dates = Utils.generateHoursByInterval(startDate, endDate)
        labels = dates.map((date) => ({date, liters: 0, label: Utils.formatDate(date, '<hh>:<mm>')}))
      break;
    }
    return labels;
}

const checkPeriod = (labels, mode) => {

    let permittedStartDate = null;
    let permittedEndDate = null;
    switch (mode) {
      case 'day':
        if (labels.length > 90) {
          permittedStartDate = labels[0].label
          permittedEndDate = labels[89].label
        }
      break;
      case 'hour':
        if (labels.length > 24) {
          permittedStartDate = labels[0].label
          permittedEndDate = labels[23].label
        }
      break;
      case 'month':
        if (labels.length > 12) {
          permittedStartDate = labels[0].label
          permittedEndDate = labels[11].label
        }
      break;
    }
    return {
        permittedStartDate,
        permittedEndDate
    }
}

const getReadingsFromModems = async (modemIds, startDate, endDate, mode) => {
    let results = {}
    for (let key in modemIds) {
        let modemId = modemIds[key]
        try {
        let response = await firebase.getReadingsFromModem(
            startDate, 
            endDate, 
            mode, 
            modemId
        )
        results[modemId] = response
        }  catch (e) {
        console.error(e)
        }
    }
    return results;
}

const createResumeConsumption = (rawReadings, modems, labels, unitMeasurement, isToPredict = true) => {
    let resume = {}
    // Cálcular a estimativa caso exista data futura
    let nowHourOclock   = Utils.getHourOclock('now')
    let nextHourOclock  = Utils.add_hour(nowHourOclock)
    let isCubicMetersSelected = unitMeasurement == 'cubic_meters' ? true : false;
    for (let modemId in rawReadings) {
      resume[modemId] = {
        modemId,
        total_liters: 0,
        total_cubic_meters: 0,
        average_liters: 0,
        average_cubic_meters: 0,
        estimative_total_liters: 0,
        estimative_total_cubic_meters: 0,
        measured_total: 0,
        estimated_total: 0,
        average: 0,
        readings: labels.map((item) => ({...item, isEstimative: false})),
        labels: labels.map((item) => item.label),
        nowHourOclock,
        nextHourOclock,
        modem: null,
        modemName: modems.find((modem)=> modem.id_modem === modemId).name,
        unitMeasurement,
      }
    }
    for (let modemId in resume) {
      let existingLitersTotal = 0
      let modem = modems.find((m) => m.id == modemId)
      if (modem) {
        resume[modemId].modem = modem
        resume[modemId].modemName = modem.name
      }
      resume[modemId].readings = resume[modemId].readings.map(
        (reading) => {
          let label = reading.label
          let liters = reading.liters
          let date = reading.date
          let rawReading = rawReadings.hasOwnProperty(modemId) ? rawReadings[modemId].find((raw) => raw.time.getTime() === date.getTime()) : undefined
          let isEstimative = reading.date.getTime() >= nextHourOclock.getTime()
          if (isEstimative && !isToPredict) return null;
          if (rawReading) {
            liters = rawReading.liters
          } else if (isEstimative) {
            liters = resume[modemId].average_liters
          }
          if (!isEstimative) {
            existingLitersTotal++
            resume[modemId].total_liters += liters
            resume[modemId].total_cubic_meters += liters/1000
            resume[modemId].average_liters = resume[modemId].total_liters/(existingLitersTotal > 0 ? existingLitersTotal : 1)
            resume[modemId].average_cubic_meters = resume[modemId].total_cubic_meters/(existingLitersTotal > 0 ? existingLitersTotal : 1)
            resume[modemId].average = !isCubicMetersSelected ? resume[modemId].average_liters : resume[modemId].average_cubic_meters
            resume[modemId].measured_total += !isCubicMetersSelected ? liters : liters/1000
          }
          resume[modemId].estimative_total_liters += liters
          resume[modemId].estimative_total_cubic_meters += liters/1000
          resume[modemId].estimated_total += !isCubicMetersSelected ? liters : liters/1000
          return {
            label,
            water: isCubicMetersSelected ? liters/1000 : liters,
            water_liters: liters,
            water_cubic_meters: liters/1000,
            date,
            isEstimative
          }
        }
      ).filter(
        (reading) => {
          return reading !== null;
        }
      )
    }
    return resume;
}

const loadReadings = async (startDate, endDate, mode, modemIds) => {
    return await getReadingsFromModems(modemIds, startDate, endDate, mode)
}
/**
 * 
 * @param {Date} startDate = indica a data inicial (inclusivo)
 * @param {Date} endDate indica a data final (exclusivo)
 * @param {String} mode indica o modo de leitura se é hour = por hora, day = por dia ou month = por mês
 * @param {Array} modems Lista dos modens ao qual será gerado o consumo
 * @param {String} unitMeasurement indica qual unidade de medição do consumo que é liters = litros ou cubic_meters = metros cúbicos
 * @param {Boolean} isToPredict indica se é preciso fazer a predição de resultado para datas futuras escolhido no intervalo
 * @returns objeto com todos os dados da data inicial permitida, a data final permitida, o resumo do consumo por modem e as labels que serão usados para gerar os gráficos
 */
const generateConsumption = async (startDate, endDate, mode, modems, unitMeasurement, isToPredict = true) => {
    let labels = createLabels(startDate, endDate, mode)
    const { permittedStartDate, permittedEndDate } = checkPeriod(labels, mode)
    let modemIds = modems.map((modem) => modem.id_modem)
    let rawReadings = await loadReadings(startDate, endDate, mode, modemIds)
    let resume = createResumeConsumption(rawReadings, modems, labels, unitMeasurement, isToPredict)
    return {
        permittedStartDate,
        permittedEndDate,
        resume,
        labels,
    }
}

const createDataset = (resume) => {
    const consumptionDatasetBarConfig = {
      label: "Medido",
      data: [],
      type: "bar",
      backgroundColor: "#3474cb",
      borderWidth: 2,
      order: 1,
      stack: 'Stack 0',
    };
    let consumptionDatasetEstimativeLineConfig = {
      label: "Média",
      type: "line",
      data: [],
      borderColor: "#96c1ff",
      fill: true,
      borderWidth: 2,
      tension: 0.4,
      pointRadius: 0,
      pointHoverRadius: 5
    };
    const consumptionDatasetEstimativeBarConfig = {
      label: "Estimado",
      data: [],
      backgroundColor: "green",
      borderColor: "green",
      borderWidth: 2,
      order: 1,
      stack: 'Stack 0',
    };
    let readingList = Object.values(resume)
    let modemIds = Object.keys(resume)
    let readings = []
    readingList.forEach(
      (reading, index) => {
        let datasets = []
        datasets.push({
          ...consumptionDatasetBarConfig,
          data: reading.readings.map((item) => !item.isEstimative ? item.water.toFixed(2) : 0)
        })
        datasets.push({
          ...consumptionDatasetEstimativeBarConfig,
          data: reading.readings.map((item) => !item.isEstimative ? 0 : item.water.toFixed(2))
        })
        datasets.push({
          ...consumptionDatasetEstimativeLineConfig,
          data: reading.readings.map((item) => reading.average.toFixed(2))
        })
        readings.push({
          labels: reading.labels,
          datasets,
          modemId: modemIds[index]
        });
      }
    )
    return readings;
}

const createDataTable = (resume) => {
    let readings_table = []
    let readingList = Object.values(resume)
    let modemIds = Object.keys(resume)
    readingList.forEach(
      (reading, index) => {
        let items = []
        items = reading.readings.filter((item) => !item.isEstimative).map((item) => ({date: item.label, value: item.water.toFixed(2)}))
        readings_table.push({
          items,
          modemId: modemIds[index]
        })
      }
    )
    return readings_table;
}

export default {
    createLabels,
    checkPeriod,
    getReadingsFromModems,
    createResumeConsumption,
    loadReadings,
    generateConsumption,
    createDataset,
    createDataTable,
}