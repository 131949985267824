<template>
  <v-container fluid style="padding: 0">
    <v-dialog
      v-model="startDateDialog"
      max-width="338px"
      @click="startDateDialog = !startDateDialog"
    >
      <v-card>
        <v-card-title>
          <span>Intervalo de Datas</span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-date-picker
            v-model="startDatePicker"
            type="month"
            locale="pt-br"
            @change="(newDate) => selectDateCalendar(newDate)"
            range
          ></v-date-picker>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="clearDateCalendar()">
            Limpar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="startDateDialog = !startDateDialog"
          >
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row
      style="
        background-color: white;
        border-radius: 16px;
        margin: 10px 10px 0px 10px;
      "
    >
      <v-col
        cols="12"
        style="
          background-color: #1976d2;
          border-radius: 16px 16px 0px 0px;
          height: 4.8vh;
          padding: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <v-row>
          <v-col style="text-align: center">
            <span
              style="
                font-weight: bold;
                letter-spacing: 2px;
                color: white;
                font-size: 2vh;
              "
            >
              {{ display.unit_name }}
            </span>
          </v-col>

          <v-col cols="auto">
            <v-btn
              @click="openSelct = !openSelct"
              text
              color="white"
              style="margin: -12px"
            >
              <v-icon>mdi-format-list-bulleted-square</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" style="padding: 0">
        <div>
          <v-progress-linear
            color="light-blue"
            height="10"
            :value="display.percentage"
            striped
            style="border-radius: 0px 0px 16px 16px"
          ></v-progress-linear>
        </div>
      </v-col>
      <v-row v-show="openSelct">
        <v-col cols="1" style="    padding: 19px 0px 0px 40px;">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon     
                     
                v-bind="attrs"
                v-on="on"
                big
                color="primary"
                @click="startDateDialog = true"
                >mdi-calendar</v-icon
              >
            </template>
            <span>Selecionar Data </span>
          </v-tooltip>
        </v-col>
        <v-col cols="12" md="4">
          <select-franchisee-dashboard
            @id="catch_franchisee_id"
            :add-clear-button="true"
          />
        </v-col>
        <v-col cols="12" md="4">
          <select-client-dashboard
            @id="catch_client_id"
            :franchisee-id="franchisee_id"
            :add-clear-button="true"
            :default-all-client="true"
          />
        </v-col>

        <v-col cols="12" md="3">
          <select-unit-all-option
            @id="catch_unit_id"
            :client-id="client_id"
            :franchisee-id="franchisee_id"
            :add-clear-button="true"
          />
        </v-col>
      </v-row>
    </v-row>
    <v-col cols="12" style="padding-top: 0px">
      <v-row>
        <v-col lg="5" md="4" cols="12">
          <ChartConsumption
            class="boxChartsAutoHeighFirstLine"
            :title="'Consumo da Concessionária'"
            :labels="display.consumption.data.labels"
            :datasets="display.consumption.data.datasets"
            :average="display.consumption.data.average"
          />
        </v-col>
        <v-col lg="4" md="4" sm="7" cols="12">
          <ChartAlert 
            :amount="display.alert.data.total"
            :labels="display.alert.data.labels"
            :datasets="display.alert.data.data"
            class="boxChartsAutoHeighFirstLine" 
          />
        </v-col>
        <v-col lg="3" cols="4">
          <GeneralDetails
            :lastReading="get_last_reading_date"
            :savingConsumption="display.economy.data.savingConsumption"
            :savingCurrency="display.economy.data.savingCurrency"

            :nextReadingDate="get_next_reading_date"
            :endReadingDate="get_end_reading_date"
            :metaEstimate="get_meta_estimate"
            :economyEstimate="get_economy_estimate"

            :totalAlerts="get_total_alerts"
            :totalCalls="get_total_calls_finished"
            class="boxChartsAutoHeighFirstLine"
          />
        </v-col>
        <v-col cols="12" lg="6">
          <economy
            class="boxChartsAutoHeigh"
            :consumption="display.economy.data.consumption"
            :valueCurrency="display.economy.data.valueCurrency"
            :savingConsumption="display.economy.data.savingConsumption"
            :savingCurrency="display.economy.data.savingCurrency"
          />
        </v-col>
        <v-col cols="12" lg="6">
          <ChartCall 
            :amount="display.call.data.total"
            :data="display.call.data.data"
            :labels="display.call.data.labels"
            class="boxChartsAutoHeigh" />
        </v-col>
      </v-row>
    </v-col>
  </v-container>
</template>
<script>
import DashboardScript from "./DashboardScript";
export default DashboardScript;
</script>
<style scoped>
.boxChartsAutoHeighFirstLine {
  border: 1px solid #9fa8da75;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  height: calc(100vh - 53vh);
  min-height: 300px;
  background-color: white;
  border-radius: 16px;
  overflow: hidden;
  padding: 15px;
}
.boxChartsAutoHeigh {
  border: 1px solid #9fa8da75;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  height: calc(100vh - 69vh);
  min-height: 250px;
  background-color: white;
  border-radius: 16px;
  overflow: hidden;
  padding: 15px;
}
</style>
