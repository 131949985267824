<template>
  <div style="position: relative; display: block">
    <h3 style="position: absolute; top: 15px; left: 15px">Consumo da Concessionária</h3>
    <canvas id="Newbar2"></canvas>
  </div>
</template>
<script>
import Chart from "chart.js";
export default {
  props: {
    labels: {
      type: Array,
      default: [],
    },
    datasets: {
      type: Array,
      default: [],
    },
    average: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      colorBarWater: "#3474cb",
      colorBarSewer: "#697e9d",
      colorLineAverage: "#2b82ff",
      gradientBg: null,
      myChart: null,
      options: {
        scaleShowValues: true,
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            display: false,
          },
        },
        legend: {
          display: true,
          labels: {
            usePointStyle: true,
          }
        },
        title: {
          display: true,
          text: "",
        },
      },
    };
  },
  mounted() {
    let ctx = document.getElementById("Newbar2").getContext('2d');
    const gradientBg = ctx.createLinearGradient(0,0,0,500)
    gradientBg.addColorStop(0,this.colorLineAverage)
    gradientBg.addColorStop(0.5,"white")

    this.gradientBg = gradientBg
    this.myChart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: this.labels,
        datasets: [],
      },
      options: this.options,
    });
  },
  watch: {
    datasets() {
      if (this.datasets.length > 0) {
        let avaregeDataset = {
          label: "Média",
          type: "line",
          data: [],
          borderColor: "#96c1ff",
          backgroundColor: this.gradientBg,
          fill: true,
          borderWidth: 2,
          tension: 0.4,
          pointRadius: 0,
          pointHoverRadius: 5
        };
        
        let sum = 0
        this.myChart.data.labels = this.labels
        if (this.datasets[1]) {
          sum = this.datasets[1].data.reduce((soma, i) => soma + i);         
    
          if (sum > 0) {
            this.myChart.data.datasets = [
              avaregeDataset,
              this.datasets[0] ? this.datasets[0] : {},
              this.datasets[1],
            ];
          } else {
            this.myChart.data.datasets = [
              avaregeDataset,
              this.datasets[0] ? this.datasets[0] : {},
            ];
          }
        }
        if (this.average > 0) {
          let avaregeVet = sum > 0?
          new Array(this.labels.length).fill(
            (this.average).toFixed(2)
          ): new Array(this.labels.length).fill(
            this.average.toFixed(2)
          )
          avaregeDataset.data = avaregeVet
        }
        this.myChart.update();

      }
    },
  },
  computed: {},
};
</script>