<template>
    <div>
        <v-row justify="center">
            <v-dialog transition="dialog-top-transition" v-model="dialog" v-if="dialog" persistent max-width="600px">
                <v-card>
                    <v-toolbar color="primary" dark> <span class="text-h5">Adicionar Local</span></v-toolbar>
                    <v-card-title>
                        <v-spacer></v-spacer>
                    </v-card-title>
                    <v-card-text style="padding-bottom: 0px;">
                        <v-col cols="12">
                            <v-autocomplete
                                dense
                                v-model="selected"
                                :items="options"
                                :label="label"
                                outlined
                                hide-details
                            >
                            </v-autocomplete>
                        </v-col>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDialog()">
                            Cancelar
                        </v-btn>
                        <v-btn color="blue darken-1" text @click="add()">Adicionar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>

        <v-col cols="12">
          <h3>Locais:<v-btn
              color="primary"
              rounded
              text  
              @click="() => dialog = true"              
            >
              <v-icon>add</v-icon>
            </v-btn>
          </h3>
        </v-col>
    </div>
</template>

<script>
import Utils from '@/scripts/Utils';
export default {
  props: {
    // dialog: {
    //   default: false,
    //   type: Boolean,
    // },
    // saveFunc: {},
    options: {
        default: () => [],
        type: Array,
    },
    // allSelectedOptions: {
    //     default: () => [],
    //     type: Array,
    // }
  },

  data: () => ({
    cols: "12",
    mdApt: "6",
    lgApt: "6",
    xlApt: "6",
    smApt: "6",

    dialog: false,
    selected: null,

    // validForm: true,
    // bomb: {
    //   locale: null,
    //   reservoir: null,
    //   watt: null,
    //   hour_day_use: null,
    //   value_kwh: null
    // },
    // optionsBomb: ["Poço", "Recalque"],
    // rawFiles: []
  }),
  methods: {
    add() {
        this.$emit('local:add', Utils.copyObject(this.selected))
    },


    closeDialog() {
        this.dialog = false;
    //   this.saveFunc("bomb", false);
    //   this.bomb = {
    //     locale: null,
    //     reservoir: null,
    //     watt: null,
    //     hour_day_use: null,
    //     value_kwh: null
    //   }
    //   this.$emit("status:save", false)
    },

    // async save() {
    //   if (this.$refs.form && !this.$refs.form.validate()) return
    //   this.bomb.imgList = this.rawFiles
    //   this.saveFunc("bomb", false, this.bomb);
    //   this.bomb = {
    //     locale: null,
    //     reservoir: null,
    //     watt: null,
    //     hour_day_use: null,
    //     value_kwh: null
    //   }
    //   this.$emit("status:save", true)
    // },
  },
  computed: {

  },
  watch: {
  },
  created() {

  },
};
</script>