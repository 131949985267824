import apiService, { HOST } from "./BaseApi";
import store from '@/store/index'
import Utils from "@/scripts/Utils";

// const ENDPOINT_INSPECTION_GENERAL_PATH = 'waterfix/inspection-term-general'
// const ENDPOINT_INSPECTION_LOCAL_PATH = 'waterfix/inspection-term-local'

const generatePdf = async (endpoint, params) => {
    let response = await apiService.post(endpoint, { ...params, env: Utils.getEnv(), currentUserId: store.state.currentUser?.uid })
    return response;
}

const generateURL = (fileName, newName) => {  
    newName = newName.split(" ").join("_");
    const URL = HOST + `download/v2/${fileName}/${newName}`
    return URL
}

const downloadPdf = async (fileName, newName) => { 
    window.open(generateURL(fileName, newName))
}

const WaterFixApi = {
    downloadProposal: async (pdfName, params, onlyURL = false) => {
        let response = await generatePdf('/waterfix/proposal', params)
        if (!onlyURL) downloadPdf(response.data.fileName, `${pdfName}_PROPOSTA_GERAL`)
        else return generateURL(response.data.fileName, `${pdfName}_PROPOSTA_GERAL`)
    },
    downloadReturnTerm: async (pdfName, params, onlyURL = false) => {
        let response = await generatePdf('/waterfix/return-term', params)
        // downloadPdf(response.data.fileName, `${pdfName}_TERMO_DE_DEVOLUCAO`)
        if (!onlyURL) downloadPdf(response.data.fileName, `${pdfName}_TERMO_DE_DEVOLUCAO`)
        else return generateURL(response.data.fileName, `${pdfName}_TERMO_DE_DEVOLUCAO`)
    },
    downloadCompromiseTerm: async (pdfName, params, onlyURL = false) => {
        let response = await generatePdf('/waterfix/compromise-term', params)
        // downloadPdf(response.data.fileName, `${pdfName}_TERMO_DE_CIENCIA`)
        if (!onlyURL) downloadPdf(response.data.fileName, `${pdfName}_TERMO_DE_CIENCIA`)
        else return generateURL(response.data.fileName, `${pdfName}_TERMO_DE_CIENCIA`)
    },
    downloadInstallationTerm: async (pdfName, params, onlyURL = false) => {
        let response = await generatePdf('/waterfix/installation-term', params)
        // downloadPdf(response.data.fileName, `${pdfName}_TERMO_DE_INSTALACAO`)
        if (!onlyURL) downloadPdf(response.data.fileName, `${pdfName}_TERMO_DE_INSTALACAO`)
        else return generateURL(response.data.fileName, `${pdfName}_TERMO_DE_INSTALACAO`)
    },
    downloadInspectionTerm: async (pdfName, params, onlyURL = false) => {
        let response = await generatePdf('/waterfix/inspection-term', params)
        // downloadPdf(response.data.fileName, `${pdfName}_TERMO_DE_VISTORIA`)
        if (!onlyURL) downloadPdf(response.data.fileName, `${pdfName}_TERMO_DE_VISTORIA`)
        else return generateURL(response.data.fileName, `${pdfName}_TERMO_DE_VISTORIA`)
    },
    downloadServiceOrder: async (pdfName, params, onlyURL = false) => {
        let response = await generatePdf('/waterfix/service-order', params)
        // downloadPdf(response.data.fileName, `${pdfName}_ORDEM_DE_SERVICO`)
        if (!onlyURL) downloadPdf(response.data.fileName, `${pdfName}_ORDEM_DE_SERVICO`)
        else return generateURL(response.data.fileName, `${pdfName}_ORDEM_DE_SERVICO`)
    },
};

export default WaterFixApi;
