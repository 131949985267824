<template>
  <div style="padding-right: 22px;">
    <v-col cols="12" class="cardsDashboard" style="background: rgb(33,145,219);padding: 5px 10px 5px 10px;font-size: 15px;text-transform: uppercase;text-align: center;display: flex;justify-content: space-between;border-radius: 5px;">
      <span>última Leitura</span> <span>{{ lastReading }}</span>
    </v-col>

    <v-col cols="12" class="cardsDashboard" style="background: rgb(33,145,219);background: linear-gradient(90deg, rgba(33,145,219,1) 0%, rgba(56,115,192,1) 50%);">
      <v-col cols="12" style="font-size: 20px;text-transform:uppercase;padding: 5px 10px;"><span>Economia</span></v-col>
      <v-col style="background: #3267a380;font-size: 17px;padding: 5px 15px;"><span>{{ savingCurrency.formatTotal }}</span></v-col>
      <v-col style="font-size: 17px;padding: 5px 15px;"><span>{{ savingConsumption.formatTotal }} m³</span></v-col>
    </v-col>

    <v-col cols="12" class="cardsDashboard" style="background: #4c8c40;padding: 5px 10px 5px 10px;font-size: 15px;text-transform: uppercase;text-align: center;display: flex;justify-content: space-between;border-radius: 5px;flex-direction: column;">
      <div style="display: flex;justify-content: space-between;padding: 5px 0;border-bottom: 2px solid #70bc62;"><span>Início da Previsão</span> <span>{{ nextReadingDate }}</span></div>
      <div style="display: flex;justify-content: space-between;padding: 5px 0;"><span>Fim da Previsão</span> <span>{{ endReadingDate }}</span></div>
    </v-col>

    <v-col cols="12" class="cardsDashboard" style="background: rgb(55,187,36);background: linear-gradient(90deg, rgba(55,187,36,1) 0%, rgba(84,157,64,1) 50%);">
      <v-col cols="12" style="font-size: 20px;text-transform:uppercase;padding: 5px 10px;"><span>Previsão Mensal</span></v-col>
      <v-col style="background: #437f3780;font-size: 17px;padding: 5px 15px;"><span>{{ metaEstimate }} m³</span></v-col>
      <v-col style="font-size: 17px;padding: 5px 15px;"><span>{{ economyEstimate }}%</span></v-col>
    </v-col>
    
    <v-row style="margin-left: 1px; text-align: center;">
      <v-col cols="" class="cardsDashboard" style="background: #742827;margin-right: 15px">
        <h4 style="padding: 0px 10px 0px 10px;">ALERTAS</h4>
        <h1 style="background: #6a2424;padding: 0px 10px 0px 10px;font-size: 17px;">{{totalAlerts}}</h1>
      </v-col>
      <v-col cols="" class="cardsDashboard" style="background: #3d7e2e;margin-right: 10px;">
        <h4 style="padding: 0px 10px 0px 10px;">CHAMADOS</h4>
        <h1 style="background: #377029;padding: 0px 10px 0px 10px;font-size: 17px;">{{ totalCalls }}</h1>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Utils from "@/scripts/Utils";

export default {
   props: {
    lastReading:{
       type: String,
    },
    nextReadingDate: {
      type: String,
    },
    endReadingDate: {
      type: String,
    },
    totalAlerts: {
      type: Number,
      default: 0,
    },
    totalCalls: {
      type: Number,
      default: 0
    },
    metaEstimate: {
      type: String,
    },
    economyEstimate: {
      type: String,
    },
    savingConsumption: {
      type: Object,
      default: {
        average: 0,
        min: 0,
        max: 0,
        total: 0,
        formatAverage: Utils.formatNumber(0),
        formatMin: Utils.formatNumber(0),
        formatMax: Utils.formatNumber(0),
        formatTotal: Utils.formatNumber(0),
      },
    },
    savingCurrency: {
      type: Object,
      default: {
        average: 0,
        min: 0,
        max: 0,
        total: 0,
        formatAverage: Utils.formatCurrency(0),
        formatMin: Utils.formatCurrency(0),
        formatMax: Utils.formatCurrency(0),
        formatTotal: Utils.formatCurrency(0),
      },
    },
  },
  data: () => ({
    color: "red",
  }),
  computed: {},
  methods: {
    check_number(value) {
      if (value === null) return 0;
      return value;
    },
    formatNumber: Utils.formatNumber,
  },
  watch:{}
};
</script>


<style scoped>
.cardsDashboard {
  margin-bottom: 15px;
  overflow: hidden;
  color: white;
  padding: 0px;
  border-radius: 5px;
}
.no-margin {
  margin: 0 !important;
}
</style>