import Utils from '@/scripts/Utils';
import Rules from "@/utils/Rules";
import SolutionsPointServices from "@/services/firebase/SolutionsPoint/SolutionsPoint";
import { showErrorSnackBar, showSuccessSnackBar } from "@/utils/SnackBartHelper";
import { showDeleteDialogAlert } from "@/utils/DialogAlertHelper";

export default {
  name: "SolutionsPoint",
  data: () => ({
    page: 1,
    newItem: {
      name: "",
      description: "",
    },
    dataSolutionsPoint: [],
    validForm: true,
    saveButtonLoading: false,
    isLoadingSolutionPoint: false,
    isLoadingSolutionPointForm: false,
    dialogSolutionPoint: false,
    columnsToChoose: [{ text: "Ponto", value: "name" },{ text: "Tipo", value: "type" }],
    columnToSeach: "name",
    headers: [
      { text: "Ponto", value: "name" },
      { text: "Descrição", value: "description"},
      { text: "Ações", sortable: false},
    ],
    searchText: "",
    formTitle: "Adicionar Grupo",
    deleteItem: null,
  }),
  methods: {
    closeDialog() {
      this.dialogSolutionPoint = false
    },
    openSolutionPointDialog(item = null) {
      this.dialogSolutionPoint = true
      this.newItem = {}
      if (item) {
        this.formTitle = "Editar Ponto"
        this.newItem = item
      } else {
        this.formTitle = "Adicionar Ponto"
      }
    },
    async saveSolutionPoint() {
      if (this.$refs.addSolutionPointForm && !this.$refs.addSolutionPointForm.validate()) return;
      this.isLoadingSolutionPointForm = true
      try {
        if (this.formTitle === "Editar Ponto") {
          await this.editSolutionPoint()
        } else if (this.formTitle === "Adicionar Ponto") {
          await this.createSolution()
        }
      } catch (err) {
        console.error(err)
        showErrorSnackBar("Ocorreu um erro interno!. Entre em contato com nossa equipe.")
      } finally {
        this.closeDialog();
        this.isLoadingSolutionPointForm = false
      }
    },
    async listSolutionsPoint() {
      this.isLoadingSolutionPoint = true
      this.dataSolutionsPoint = await SolutionsPointServices.getSolutionsPoint()
      this.isLoadingSolutionPoint = false
    },
    async createSolution() {
      try {
        const newItemId = await SolutionsPointServices.addSolutionPoint({ ...this.newItem })
        this.dataSolutionsPoint.unshift({ ...this.newItem, id: newItemId })
        showSuccessSnackBar("Ponto cadastrada com sucesso!")
      } catch (err) {
        throw "Ocorreu um erro ao Criar Ponto!"
      }
    },
    async editSolutionPoint() {
      try {
        await SolutionsPointServices.updateSolutionsPoint(this.newItem)
        this.dataSolutionsPoint.forEach((solution) => solution === this.newItem ? solution = this.newItem : '')
        showSuccessSnackBar("Ponto editada com sucesso!")
      } catch (err) {
        throw "Ocorreu um erro ao Editar Ponto!"
      }
    },
    async confirmDeleteSolutionsPoint(item) {
      this.deleteItem = item
      showDeleteDialogAlert(`Deseja excluir essa Ponto ${item.name}?`, this.deleteSolutionPoint)
    },
    async deleteSolutionPoint() {
      try {
        await SolutionsPointServices.deleteSolutionPoint(this.deleteItem.id)
        this.dataSolutionsPoint = this.dataSolutionsPoint.filter((solution) => this.deleteItem != solution)
      } catch (err) {
        console.error(err)
        showErrorSnackBar("Ocorreu um erro interno!. Entre em contato com nossa equipe.")
      }
      this.deleteItem = null
    },
  },
  computed: {
    filterSoluitionsPoint() {
      let text = Utils.defaultSpace(this.searchText)
      let list = this.dataSolutionsPoint
      list = list.filter((solutionPoint) => {
        let value = solutionPoint[this.columnToSeach]
        if (value) return (Utils.defaultSpace(value).toLowerCase().indexOf(text.toLowerCase()) > -1);
      });
      return list
    },
    rulesRequired() {
      return Rules.required();
    },
  },
  mounted() {
    this.listSolutionsPoint();
  },
};