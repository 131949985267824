import DatePickerDialog from "@/components/DatePickerDialog/DatePickerDialog.vue";
import FeaturesService from "@/services/firebase/Features/FeaturesService";
import UsersService from "@/services/firebase/Users/UsersService";
import RolesFeaturesService from "@/services/firebase/RolesFeatures/RolesFeaturesService";
import RolesService from "@/services/firebase/Roles/RolesService";


import { showDeleteDialogAlert } from "@/utils/DialogAlertHelper"
import { showErrorSnackBar, showSuccessSnackBar, showInfoSnackBar } from "@/utils/SnackBartHelper";
import Utils from '@/scripts/Utils';
import Rules from "@/utils/Rules";
import FunctionSelector from './FeaturesSelector.vue';

export default {
  components: {
    DatePickerDialog,
    FunctionSelector
  },
  name: "Roles",

  data: () => ({
    featuresData: [],
    rolesData: [],
    alertData: [],
    usersData: [],
    sectorsData: [],
    unitsData: [],
    sectorsAttachAlert: [],
    unitsAttachAlert: [],
    usersAttachWhatsappAlert: [],
    featuresByRole: [],
    unitsByAlertData: [],
    selectedFeatures: [],
    headers: [
      { text: "Nome", value: "name" },
      { text: 'Ações', value: 'actions', sortable: false },
    ],
    desserts: [],
    modalRole: false,
    columnsToChoose: [{ value: "name", text: "Nome" }],
    listActions: [
      { value: "edit", title: "Editar" },
      { value: "delete", title: "Excluir" },
    ],
    month: Array(30).fill(1).map((value, index) => (index + 1)),
    endMonth: [],
    endWeek: [],
    menuStart: false,
    menuEnd: false,

    columnToSearch: "name",
    alerTypeSelected: null,
    modalTitle: "Adicionar Perfil",
    searchText: "",
    startDateDialog: false,
    startDatePicker: null,
    minStartDate: null,
    maxStartDate: null,
    avaregeDays: 30,
    percentageAverage: 100,
    consumptionType: null,
    pointsList: [],
    editedPointsList: {},
    editedItem: {
      name: '',
      deletedAt: null
    },
    loading: {
      usersList: false,
      modalRole: false,
      mainTable: false,
      unitsList: false,
      sectorList: false,
      buttonList: false,
    },
    validForm: true
  }),

  methods: {

    async selectAction(item, value) {
      switch (value) {
        case "edit":
          this.editRole(item)
          break;
        case "delete":
          this.editedItem = { ...item }
          const usersByIdRoleList = await UsersService.getUsersByIdRole(this.editedItem.id_doc)
          usersByIdRoleList.length ? 
          showInfoSnackBar("Perfil vinculado a usuários mude o perfil dos usuários referentes antes de excluir."):
          showDeleteDialogAlert(`Deseja excluir o perfil "${item.name}"?`, this.deleteItemConfirm)
          break;
        default:
          break;
      }
    },
    async addAttachFeatures(idRole, listFeatures) {
      if (listFeatures.length) {
        const saveList = listFeatures.map(id_feature => ({ id_feature, id_role: idRole }))
        await RolesFeaturesService.createRoleFeaturesList(saveList)
      }
    },

    async deletAttachRoleFeatures(listDelete) {
      if (listDelete.length) await RolesFeaturesService.deleteRoleFeaturesList(listDelete)
    },
    async editAttachRoleFeatures(idAlert, listFeatures) {
      let deleteds = []
      if (listFeatures.length) {
        deleteds = this.featuresByRole.filter(roleFeature => !listFeatures.find(i => roleFeature.id_feature === i))
        const addeds = listFeatures.filter(id_feature => !this.featuresByRole.find(roleFeature => id_feature === roleFeature.id_feature))
        this.addAttachFeatures(idAlert, addeds)
        this.deletAttachRoleFeatures(deleteds)
      } else if (listFeatures.length === 0 && this.featuresByRole.length) {
        deleteds = this.featuresByRole
        this.deletAttachRoleFeatures(deleteds)
      }
    },
    async deleteItemConfirm() {
      this.loading.buttonList = true

      await this.deletAttachRoleFeatures(this.featuresByRole)
      await RolesService.editRole(this.editedItem.id_doc, { deletedAt: new Date() })

      showSuccessSnackBar("Perfil deletado!")
      this.rolesData = this.rolesData.filter((role) => role.id_doc != this.editedItem.id_doc)
      this.closeRoleModal()
    },
    async editRole(item) {
      this.editedItem = { ...item }
      this.featuresByRole = await RolesFeaturesService.getRoleFeaturesByIdRole(this.editedItem.id_doc)
      this.selectedFeatures = this.featuresByRole.map(({ id_feature }) => id_feature)
      this.modalTitle = "Editar Perfil"
      this.modalRole = true
    },

    async save() {
      try {
        if ((this.$refs.form) && !this.$refs.form.validate()) return;
        this.loading.modalRole = true

        if (this.modalTitle === "Adicionar Perfil") {
          const newRoleId = await RolesService.createRole(this.editedItem)
          this.addAttachFeatures(newRoleId, this.selectedFeatures)
          showSuccessSnackBar("Perfil criado!")
          this.rolesData.unshift({ ...this.editedItem, id_doc: newRoleId })
        } else {
          await RolesService.editRole(this.editedItem.id_doc, this.editedItem)
          this.editAttachRoleFeatures(this.editedItem.id_doc, this.selectedFeatures)
          showSuccessSnackBar("Perfil editado!")
          this.rolesData = this.rolesData.map((role) => role.id_doc === this.editedItem.id_doc ? this.editedItem : role)
        }
        this.loading.modalRole = false
        this.closeRoleModal()
      } catch (error) {
        this.loading.modalRole = false
        console.error('Error save: ' + error)
      }
    },

    addNewAlert() {
      this.modalTitle = "Adicionar Perfil"
      this.modalRole = true
    },
    closeRoleModal() {
      this.loading.buttonList = false
      this.modalRole = false
      this.sectorsAttachAlert = []
      this.unitsAttachAlert = []
      this.editedItem = {
        name: '',
        deletedAt: null

      }
    },
  },
  computed: {
    filteredItems() {
      return this.rolesData.filter(i => 
        i[this.columnToSearch].toLowerCase().includes(this.searchText.toLowerCase())
      );
    },
    rulesRequired() {
      return Rules.required();
    },

  },
  watch: {

  },
  async mounted() {
    this.loading.mainTable = true
    this.rolesData = await RolesService.getRoles()
    this.loading.mainTable = false
    this.featuresData = await FeaturesService.getFeatures()


  },
};