<template>
  <v-card>
    <v-card-title>
      Seleção de Funções
      <v-spacer></v-spacer>
      <v-btn text @click="toggleSelectAll">{{ selectAllLabel }}</v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <div v-for="(features, type) in groupedFeatures" :key="type">
        <v-subheader>{{ type }}</v-subheader>
        <v-row>
          <v-col cols="12" md="6" v-for="feature in features" :key="feature.id_doc" class="checkbox-col">
            <v-checkbox
              :label="feature.tag"
              v-model="localSelectedFeatures"
              :value="feature.id_doc"
              class="small-checkbox"
            ></v-checkbox>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'FunctionSelector',
  props: {
    features: {
      type: Array,
      required: true
    },
    selectedFeatures: {
      type: Array,
      required: true
    }
  },
  computed: {
    localSelectedFeatures: {
      get() {
        return this.selectedFeatures;
      },
      set(value) {
        this.$emit('update:selectedFeatures', value);
      }
    },
    selectAllLabel() {
      return this.localSelectedFeatures.length === this.features.length ? 'Apagar Seleção' : 'Selecionar Todos';
    },
    groupedFeatures() {
      return this.features.reduce((acc, feature) => {
        if (!acc[feature.type]) {
          acc[feature.type] = [];
        }
        acc[feature.type].push(feature);
        return acc;
      }, {});
    }
  },
  methods: {
    toggleSelectAll() {
      if (this.localSelectedFeatures.length === this.features.length) {
        this.localSelectedFeatures = [];
      } else {
        this.localSelectedFeatures = this.features.map(feature => feature.id_doc);
      }
    }
  }
};
</script>

<style>
.checkbox-col {
  margin-bottom: 4px;
}
.small-checkbox .v-input--selection-controls__input {
  margin-bottom: 4px;
}
</style>
