<template>
  <div>
      <!-- <v-row>
          <v-col cols="12">
              <h3>Listagem dos itens devolvidos:</h3>
          </v-col>
      </v-row> -->
      <v-divider></v-divider>

      <!-- Dialog de novo local -->
      <v-row justify="center">
          <v-dialog transition="dialog-top-transition" v-model="devolutionModal" persistent max-width="600px">
              <v-card>
                <v-form ref="devolutionForm" lazy-validation>
                  <v-toolbar color="primary" dark> <span class="text-h5">Adicionar Local</span></v-toolbar>
                  <v-card-title>
                      <v-spacer></v-spacer>
                  </v-card-title>
                  <v-card-text style="padding-bottom: 0px;">
                      <v-row>
                        <v-col cols="12">
                            <div
                              v-text="getLocalSelected"
                              style="font-size: 20px;font-weight: bold;"
                            ></div>
                        </v-col>
                      </v-row>
                      <v-divider></v-divider>
                      <v-row>
                        <v-col cols="12">
                          <v-autocomplete
                            dense
                            v-model="selected.sublocalId"
                            :items="getAvailableSubLocals()"
                            :label="'Sub Local'"
                            item-text="sublocal"
                            item-value="id"
                            outlined
                            hide-details
                            :loading="loading"
                            :rules="rulesRequired"
                          >
                          </v-autocomplete>
                        </v-col>
                      </v-row>
                      <v-divider></v-divider>
                      <v-row>
                        <v-col cols="12">
                          <v-autocomplete
                            dense
                            v-model="selected.solutionId"
                            :items="getAvailableSolutions()"
                            :label="'Solução'"
                            outlined
                            item-text="solution"
                            item-value="id"
                            hide-details
                            :loading="loading"
                            :rules="rulesRequired"
                          >
                          </v-autocomplete>
                        </v-col>
                      </v-row>
                      <v-divider></v-divider>
                      <v-row>
                        <v-col cols="6">
                          <v-autocomplete
                            dense
                            v-model="selected.groupId"
                            :items="getAvailableGroups()"
                            :label="'Grupo'"
                            outlined
                            item-text="group"
                            item-value="id"
                            hide-details
                            :loading="loading"
                            :rules="rulesRequired"
                          >
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="6">
                          <v-autocomplete
                            dense
                            v-model="selected.pointId"
                            :items="getAvailablePoints()"
                            :label="'Ponto'"
                            outlined
                            item-text="point"
                            item-value="id"
                            hide-details
                            :loading="loading"
                            :rules="rulesRequired"
                          >
                          </v-autocomplete>
                        </v-col>
                      </v-row>
                      <v-divider></v-divider>
                      <v-row>
                        <v-col cols="3">
                          <v-text-field 
                              label="Quantidade"
                              type="number"
                              v-model="selected.amountSolutionUsed"
                              :disabled="!hasSelected || loading || !validateForm"
                              :rules="rulesAmountSolution"
                            >
                          </v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field 
                              readonly
                              label="Total Disponível"
                              :value="selected.maxAmountSolutionAllowed"
                              disabled
                            >
                          </v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field 
                              label="Preço"           
                              prefix="R$"
                              type="number"
                              v-model="selected.price"
                              :disabled="!hasSelected || loading || !validateForm"
                              :rules="rulesPrice"
                            >
                          </v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field 
                              readonly
                              label="Total"           
                              prefix="R$"
                              :value="formatNumber(selected.total)"
                              disabled
                            >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-divider></v-divider>
                      <v-row>
                        <v-col cols="6">
                          <v-text-field
                            readonly
                            label="Tipo"
                            :value="selected.type"
                            disabled
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-select
                            :items="returnedOptions"
                            label="Foi devolvido?"
                            outlined
                            v-model="selected.wasReturned"
                            :loading="loading"
                            :disabled="!hasSelected || loading || !validateForm"
                            :rules="rulesRequired"
                          ></v-select>
                        </v-col>
                        <v-col cols="12">
                          <v-textarea
                            outlined
                            name="input-7-4"
                            label="Comentário"
                            v-model="selected.comments"
                            :loading="loading"
                            :disabled="!hasSelected || loading || !validateForm"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                  </v-card-text>
                  <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="() => closeDevolutionModal()" :disabled="loading">
                          Cancelar
                      </v-btn>
                      <v-btn color="blue darken-1" text @click="addDevolution" :disabled="loading">Adicionar</v-btn>
                  </v-card-actions> 
                </v-form>
              </v-card>
          </v-dialog>
      </v-row>

      <v-col cols="12">
        <v-row>
          <h3>Termo de Devolução:</h3>
        </v-row>
      </v-col>

      <v-col cols="12">
        <v-row>
          <v-autocomplete
            dense
            v-model="selected.localId"
            :items="getAvailableLocals()"
            :label="'Local'"
            outlined
            item-text="local"
            item-value="id"
            hide-details
            value="Teste 6"
            @keyup.backspace="() => {}"
            :loading="loading"
            :disabled="!hasAvailableSolutionList()"
            @input="(e) => updateSearchLocal(e)"
          >
          </v-autocomplete>
        </v-row>
      </v-col>
      <v-divider></v-divider>
      <v-col cols="12">
        <v-row>
          <v-data-table
            v-if="proposal !== null"
            :headers="headers"
            :items="proposal.devolutionList"
            class="elevation-1"
            style="width: 100%"
          >
            <template v-slot:body="{ items }">
              <tbody>
                <tr v-for="(devolution, index) in items" :key="index">
                  <td>
                    <v-text-field       
                        readonly             
                        :value="devolution.local" 
                      >
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field    
                      readonly                
                      :value="devolution.sub_local" 
                    >
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field   
                      readonly
                      :value="devolution.solution"
                    />
                  </td>
                  <td>
                    <v-text-field   
                      readonly
                      :value="devolution.type"
                    />
                  </td>
                  <td>
                    <v-text-field 
                      readonly
                      :value="devolution.amountSolutionUsed"
                    >
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field 
                      readonly
                      :value="formatNumber(devolution.price)"
                      prefix="R$"
                    >
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field 
                      readonly
                      :value="devolution.wasReturned"
                    >
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      readonly
                      :value="devolution.group"
                    />
                  </td>
                  <td>
                    <v-btn
                      elevation="2"
                      @click="confirmDelete(devolution)"
                      :loading="loading"
                      style="height: 30px; margin: 2px"
                    >
                      <v-icon color="primary" small> mdi-delete </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-row>
      </v-col>
  </div>
</template>
<script>
import Rules from "@/utils/Rules";
import ConsumptionForm from "@/components/FormsComponents/WaterFix/ConsumptionTest.vue";
import firebase from "../../../firebase";

import WaterScanStoragefrom from '@/services/storage/WaterScanStorage';
import CardsWaterScan from "@/components/Forms/CardsWaterScan/CardsWaterScan.vue";
import DialogHydrometer from "@/components/FormsComponents/DialogCheckList/Hydrometer.vue";
import DialogBombs from "@/components/FormsComponents/DialogCheckList/Bombs.vue";
import DialogReservoir from "@/components/FormsComponents/DialogCheckList/Reservoir.vue";
import WaterFixService from '@/services/firebase/WaterFix/WaterFixService';
import Utils from '@/scripts/Utils';
import SolutionsServices from '@/services/firebase/SolutionsServices/SolutionsServices';
import SolutionsPoint from '@/services/firebase/SolutionsPoint/SolutionsPoint';
import SolutionsGroup from '@/services/firebase/SolutionsGroup/SolutionsGroup';
import { showDeleteDialogAlert } from "@/utils/DialogAlertHelper";
import Local from "@/components/FormsComponents/WaterFixSteps/Devolution/Local.vue";
import { showInfoSnackBar, showErrorSnackBar, showSuccessSnackBar } from "@/utils/SnackBartHelper";
import { mapActions, mapGetters } from "vuex";


export default {
  props: {
    requestData: {
      default: null,
    },
    requestSave: {
      default: null,
    },
    registration: {
      default: ""
    },
    viewOrEdit: {
      default: ""
    },
    validFormProp: {
      default: () => {},
    },
    closeProposed: {
      default: () => {},
    },
    
  },
  name: "addressForm",
  components: {
    ConsumptionForm,
    CardsWaterScan,
    DialogHydrometer,
    DialogBombs,
    DialogReservoir,
    Local,
  },
  data: function () {
    return {
        headers: [
          { text: "Local", value: "local", width: 100, sortable: true },
          { text: "Sub local", value: "sub_local", width: 150, sortable: true },
          { text: "Solução", value: "solution", width: 500, sortable: true },
          { text: "Descrição", value: "type", width: 150, sortable: true },
          { text: "Quantidade", value: "amountSolutionUsed", width: 120, sortable: true },
          { text: "Preço", value: "price", width: 120, sortable: true },
          { text: "Resposta", value: "wasReturned", width: 120, sortable: true },
          { text: "Grupo", value: "group", sortable: true },
          { text: "Ações", value: "action", sortable: false },
        ],
        // --------------------------------------------
        /*
          solution<Object of solutions collection>
          group<Object of solutions_group collection>
          point<Object of solutions_point collection>
          ------------------------------------------------------
          proposal<Object of water_fix collection>:
            solutionList<Array of Object>
            devolutionList<Array of Object>: 
             local<String>: solutionList[index].local
             subLocal<String>: solutionList[index].sub_local
             type<String>: solutionList[index].type
             solutionId<String>: solution.id
             pointId<String>: solution.pointId
             groupId<String>: solution.groupId
          ------------------------------------------------------
        */
        proposalId: null,
        proposal: null,
        locals: [],
        sublocals: [],
        solutions: [],
        groups: [],
        points: [],
        // --------------------------------------------
        dialog: false,
        selected: {
          localId: null,
          sublocalId: null,
          solutionId: null,
          groupId: null,
          pointId: null,
          amountSolutionUsed: 0,
          maxAmountSolutionAllowed: 0,
          total: 0,
          price: 0,
          comments: null,
          type: null,
          wasReturned: 'Não',
        },
        selectedLocal: null,
        selectedSublocal: null,
        selectedSolution: null,
        selectedGroup: null,
        selectedPoint: null,
        // --------------------------------------------
        searchSolutionList: [],
        devolutionListIds: [],
        devolutionModal: false,
        validateForm: true,
        tempDevolution: null,
        returnedOptions: ['Sim', 'Não'],
        totalAvailableLocation: 0,
        // --------------------------------------------------------
        // --------------------------------------------------------
        // --------------------------------------------------------
        index: 2,
        loading: false,
    };
  },
  methods: {
    ...mapActions([
      'subscriberWaterfix',
    ]),
    updateSearchLocal(input) {
      if (input !== null) {
        this.setNewDevolution()
      }
    },
    formatNumber(number) {
      return Utils.formatNumber(parseFloat(number))
    },
    // ---------------------------------------------------------------------------
    // Métodos obrigatórios para o gerenciamento do componente pai
    isValidadePage() { return true; }, // É preciso deixar para não quebrar a lógica do componente pai
    saveAll() {}, // É preciso deixar para não quebrar a lógica do componente pai
    setLoading(status) {
      this.loading = status;
    },
    emitLoadingEvent(status) {
      this.$emit("loading", status);
    },
    confirmDelete(devolution) {
      this.tempDevolution = devolution
      showDeleteDialogAlert(`Deseja excluir esse item?\nLocal: ${devolution.local} / Sub Local: ${devolution.sub_local} / Solução: ${devolution.solution} / Grupo: ${devolution.group} / Ponto: ${devolution.point}`, this.deleteDevolution)
    },
    async deleteDevolution() {
      // const tempId = this.generateSearchSolutionList(this.tempDevolution.localId, this.tempDevolution.sublocalId, this.tempDevolution.solution.id, this.tempDevolution.group.id, this.tempDevolution.point.id)
      let devolutionList = this.proposal.devolutionList.filter(
        (item) => {
          return (
            `${item.local}${item.sub_local}${item.solutionId}${item.groupId}${item.pointId}` 
            !== 
            `${this.tempDevolution.local}${this.tempDevolution.sub_local}${this.tempDevolution.solutionId}${this.tempDevolution.groupId}${this.tempDevolution.pointId}`
          )
        }
      )
      await WaterFixService.updateProposal(this.proposalId, { devolutionList })
      this.proposal.devolutionList = devolutionList
      await this.$nextTick(
        async () => {
          await this.updateProposalCycle(Utils.copyObject(this.proposal));
          this.tempDevolution = null;
        }
      )
    },
    // ---------------------------------------------------------------------------
    // ---------------------------------------------------------------------------
    // ---------------------------------------------------------------------------
    // Operações das listas de devolução, de solução e pontos
    async getProposalFromWaterFix(registration) {
      // Verificar se existe alguma proposta de water fix
      return await WaterFixService.getProposalByRegistration(registration)
    },
    async initializeProposal(proposalObject) {
      if (proposalObject) {
        this.proposal = proposalObject
        this.proposalId = this.proposal.id;
        let locals = Utils.removeDuplicatesCustom(this.proposal.solutionList.map(
          (item) => {
            return {
              id: Utils.capitalizeString(Utils.clearAccents(item.local)),
              local: item.local,
            }
          }
        ), ['id'])
        this.locals = locals;
        let sublocals = Utils.removeDuplicatesCustom(this.proposal.solutionList.map(
          (item) => {
            return {
              id: Utils.capitalizeString(Utils.clearAccents(item.sub_local)),
              sublocal: item.sub_local,
            }
          }
        ), ['id'])
        this.sublocals = sublocals;
        return;
      } else {
        showErrorSnackBar("Ocorreu um erro na busca das devoluções da proposta")
      }
    },
    async initializeSolutions(proposalObject) {
      if (proposalObject) {
          let solutionIds = []
          let groupIds    = []
          let pointIds    = []
          if (proposalObject.solutionList) {
            solutionIds = proposalObject.solutionList.map((item) => item.solution)
          }
          if (solutionIds.length > 0) {
            solutionIds = solutionIds.filter(v => v !== null)
            let solutions = await SolutionsServices.GetSolutionsByIds(solutionIds)
            this.solutions = Object.values(solutions).filter((item) => item !== null)
            groupIds = this.solutions.map((item) => item.groupId)
            pointIds = this.solutions.map((item) => item.pointId)
          }
          if (groupIds.length > 0) {
            let groups = await SolutionsGroup.GetSolutionsGroupByIds(groupIds)
            this.groups = Object.values(groups)
          }
          if (pointIds.length > 0) {
            let points = await SolutionsPoint.GetSolutionsPointByIds(pointIds)
            this.points = Object.values(points)
          }
          return;
      } else {
          showErrorSnackBar("Ocorreu um erro na busca das soluções na devolução da proposta")
      }
    },
    async updateDevolutions() {
        this.setLoading(true)
        this.emitLoadingEvent(true)
        let proposalFromWaterFix = await this.getProposalFromWaterFix(this.registration);
        await this.updateProposalCycle(proposalFromWaterFix)
    },
    async updateProposalCycle(proposal) {
      this.setLoading(true)
      await this.initializeProposal(proposal);
      await this.initializeSolutions(proposal);
      await this.$nextTick(
        () => {
          this.updateSearchSolutionList();
        }
      )
    },
    // ---------------------------------------------------------------------------
    // Criar a lista de checklist disponivel para consultar
    getLocalById(localId) {
      return this.locals.find((item) => item.id == localId)
    },
    getSublocalById(sublocalId) {
      return this.sublocals.find((item) => item.id == sublocalId)
    },
    getSolutionById(solutionId) {
      return this.solutions.find((item) => item.id == solutionId)
    },
    getGroupById(groupId) {
      return this.groups.find((item) => item.id == groupId)
    },
    getPointById(pointId) {
      return this.points.find((item) => item.id == pointId)
    },
    generateSearchSolutionList(localId, sublocalId, solutionId, groupId, pointId) {
      return `${localId}_${sublocalId}_${solutionId}_${groupId}_${pointId}`
    },
    updateDevolutionListIds() {
      this.devolutionListIds = this.proposal.devolutionList.map(
        (item) => {
          const localId = Utils.capitalizeString(Utils.clearAccents(item.local))
          const sublocalId = Utils.capitalizeString(Utils.clearAccents(item.sub_local))
          const solution = this.getSolutionById(item.solutionId)
          if (!solution) return null;
          const group = this.getGroupById(solution.groupId)
          if (!group) return null;
          const point = this.getPointById(solution.pointId)
          if (!point) return null;
          const tempId = this.generateSearchSolutionList(localId, sublocalId, solution.id, group.id, point.id)
          return tempId;
        }
      ).filter((item) => item !== null)
    },
    updateSearchSolutionList() {
      this.updateDevolutionListIds();
      this.searchSolutionList = this.proposal.solutionList.map(
        (item) => {
          const localId = Utils.capitalizeString(Utils.clearAccents(item.local))
          const sublocalId = Utils.capitalizeString(Utils.clearAccents(item.sub_local))
          const local = this.getLocalById(localId)
          if (!local) return null;
          const sublocal = this.getSublocalById(sublocalId)
          if (!sublocal) return null;
          const solution = this.getSolutionById(item.solution)
          if (!solution) return null;
          const group = this.getGroupById(solution.groupId)
          if (!group) return null;
          const point = this.getPointById(solution.pointId)
          if (!point) return null;
          item['comments'] = item.hasOwnProperty('comments') ? item.comments : null;
          const tempId = this.generateSearchSolutionList(localId, sublocalId, solution.id, group.id, point.id)
          return {
            data: item,
            tempId,
            local,
            sublocal,
            solution,
            group,
            point,
            isUsed: this.devolutionListIds.includes(tempId),
          }
        }
      ).filter((item) => item !== null)
      this.setLoading(false)
      this.emitLoadingEvent(false)
    },
    getSearchSolutionList(localId, sublocalId, solutionId, groupId, pointId) {
      const tempId = this.generateSearchSolutionList(localId, sublocalId, solutionId, groupId, pointId)
      const solution = this.searchSolutionList.find(
        (item) => {
          return item.tempId == tempId
        }
      )
      return solution;
    },
    hasAvailableSolutionList() {
      return (this.searchSolutionList.filter((item) => !item.isUsed)).length > 0
    },
    getAvailableLocals() {
      const locals = this.searchSolutionList.filter(
        (item) => {
          return !item.isUsed
        }
      ).map(
        (item) => {
          return {
            local: item.local.local,
            id: item.local.id,
          }
        }
      )
      this.totalAvailableLocation = (Utils.removeDuplicates(locals.map((item) => item.id))).length;
      if (locals.length == 1) {
        if (this.selected.localId !== locals[0].id) {
          this.selected.localId = locals[0].id
        }
      }
      return locals
    },
    getAvailableSubLocals() {
      const sublocals = this.searchSolutionList.filter(
        (item) => {
          return (
            !item.isUsed && 
            item.local.id == this.selected.localId
          )
        }
      ).map(
        (item) => {
          return {
            sublocal: item.sublocal.sublocal,
            id: item.sublocal.id,
          }
        }
      )
      if (sublocals.length == 1) {
        if (this.selected.sublocalId !== sublocals[0].id) {
          this.selected.sublocalId = sublocals[0].id
        }
      }
      return sublocals
    },
    getAvailableSolutions() {
      const solutions = this.searchSolutionList.filter(
        (item) => {
          return (
            !item.isUsed && 
            item.local.id == this.selected.localId &&
            item.sublocal.id == this.selected.sublocalId
          )
        }
      ).map(
        (item) => {
          return {
            solution: item.solution.solution,
            id: item.solution.id
          }
        }
      )
      if (solutions.length == 1) {
        if (this.selected.solutionId !== solutions[0].id) {
          this.selected.solutionId = solutions[0].id
        }
      }
      return solutions;
    },
    getAvailableGroups() {
      const groups = this.searchSolutionList.filter(
        (item) => {
          return (
            !item.isUsed && 
            item.local.id == this.selected.localId &&
            item.sublocal.id == this.selected.sublocalId &&
            item.solution.id == this.selected.solutionId &&
            (this.selected.pointId !== null ? (item.point.id == this.selected.pointId) : true)
          )
        }
      ).map(
        (item) => {
          return {
            group: item.group.name,
            id: item.group.id
          }
        }
      )
      if (groups.length == 1) {
        if (this.selected.groupId !== groups[0].id) {
          this.selected.groupId = groups[0].id
        }
      }
      return groups;
    },
    getAvailablePoints() {
      const points = this.searchSolutionList.filter(
        (item) => {
          return (
            !item.isUsed && 
            item.local.id == this.selected.localId &&
            item.sublocal.id == this.selected.sublocalId &&
            item.solution.id == this.selected.solutionId &&
            (this.selected.groupId ? (item.group.id == this.selected.groupId) : true)
          )
        }
      ).map(
        (item) => {
          return {
            point: item.point.name,
            id: item.point.id
          }
        }
      )
      if (points.length == 1) {
        if (this.selected.pointId !== points[0].id) {
          this.selected.pointId = points[0].id
        }
      }
      return points;
    },
    resetDevolution() {
      this.selected = {
        localId: null,
        sublocalId: null,
        solutionId: null,
        groupId: null,
        pointId: null,
        amountSolutionUsed: 0,
        maxAmountSolutionAllowed: 0,
        total: 0,
        price: 0,
        comments: null,
        type: null,
        wasReturned: 'Não',
      };
      this.selectedLocal = null;
      this.selectedSublocal = null;
      this.selectedSolution = null;
      this.selectedGroup = null;
      this.selectedPoint = null;
    },
    openDevolutionModal() {
      this.devolutionModal = true;
    },
    closeDevolutionModal() {
      this.devolutionModal = false;
      this.resetForm();
      this.resetDevolution();
    },
    setNewDevolution() {
      this.openDevolutionModal()
    },
    async addDevolution() {
      this.setLoading(true)
      if (!this.getValidateForm()) {
        showErrorSnackBar("Há dado(s) inválido(s) na inserção de devolução")
        this.setLoading(false)
        return;
      }
      let selected = Utils.copyObject(this.selected)
      this.proposal.devolutionList.push({
        local: this.selectedLocal.local,
        sub_local: this.selectedSublocal.sublocal,
        solution: this.selectedSolution.solution,
        solutionId: this.selectedSolution.id,
        group: this.selectedGroup.name,
        groupId: this.selectedGroup.id,
        point: this.selectedPoint.name,
        pointId: this.selectedPoint.id,
        amountSolutionUsed: this.selected.amountSolutionUsed,
        price: this.selected.price,
        total: this.selected.total,
        comments: this.selected.comments,
        type: this.selected.type,
        wasReturned: this.selected.wasReturned,
      })
      await WaterFixService.updateProposal(this.proposalId, { devolutionList: this.proposal.devolutionList })
      await this.updateProposalCycle(Utils.copyObject(this.proposal));
      showSuccessSnackBar("Uma nova devolução foi inserida com sucesso!")
      this.closeDevolutionModal();
      this.setLoading(false)
    },
    // ---------------------------------------------------------------------------
    getValidateForm() {
      if (!this.$refs.devolutionForm) return false;
      return this.$refs.devolutionForm.validate();
    },
    resetForm() {
      if (!this.$refs.devolutionForm) return;
      this.$refs.devolutionForm.reset();
    },
    calculateTotal(amountSolutionUsed, price) {
      if ((!amountSolutionUsed) || amountSolutionUsed < 0) amountSolutionUsed = 0
      if ((!price) || price < 0) price = 0
      this.selected.total = amountSolutionUsed * price
    },
    async updateLocalListener(beforeId, afterId, text) {
      this.proposal.solutionList = this.proposal.solutionList.map(
        (solution) => {
          let localId = Utils.createSlug(solution.local)
          if (beforeId !== localId) return solution;
          return {
            ...solution,
            local: text,
          }
        }
      )
      this.proposal.devolutionList = this.proposal.devolutionList.map(
        (devolution) => {
          const localId = Utils.createSlug(devolution.local)
          if (beforeId !== localId) return devolution;
          return {
            ...devolution,
            local: text,
          }
        }
      )
      await WaterFixService.updateProposal(this.proposalId, { devolutionList: this.proposal.devolutionList })
      await this.updateProposalCycle(Utils.copyObject(this.proposal));
    },
    async updateSublocalListener(beforeId, afterId, text) {
      this.proposal.solutionList = this.proposal.solutionList.map(
        (solution) => {
          let sublocalId = Utils.createSlug(solution.sub_local)
          if (beforeId !== sublocalId) return solution;
          return {
            ...solution,
            sub_local: text,
          }
        }
      )
      this.proposal.devolutionList = this.proposal.devolutionList.map(
        (devolution) => {
          const sublocalId = Utils.createSlug(devolution.sub_local)
          if (beforeId !== sublocalId) return devolution;
          return {
            ...devolution,
            sub_local: text,
          }
        }
      )
      await WaterFixService.updateProposal(this.proposalId, { devolutionList: this.proposal.devolutionList })
      await this.updateProposalCycle(Utils.copyObject(this.proposal));
    },
  },
  computed: {
    rulesRequired() {
      return Rules.required();
    },
    rulesCEP() {
      return Rules.cep();
    },
    rulesLogradouro() {
      return Rules.string(false, null, 128);
    },
    rulesCity() {
      return Rules.string(false, null, 64);
    },
    rulesDistrict() {
      return Rules.string(false, null, 64);
    },
    rulesAdditionalAddress() {
      return Rules.string(false, null, 64);
    },
    rulesHouseNumber() {
      return Rules.string(false, null, 16);
    },
    rulesAmountSolution() {
      return Rules.number(true, 1, this.selected.maxAmountSolutionAllowed);
    },
    rulesPrice() {
      return Rules.number(false, 0);
    },
    hasLocalSelected() {
      return this.selected.localId !== null;
    },
    getLocalSelected() {
      if (!this.selected.localId) return "";
      return Utils.capitalizeString(this.getLocalById(this.selected.localId).local)
    },
    hasSelected() {
      return (
        this.selected.localId !== null &&
        this.selected.sublocalId !== null &&
        this.selected.solutionId !== null &&
        this.selected.groupId !== null &&
        this.selected.pointId !== null
      )
    },
  },
  watch: {
    "selected.localId": function (newValue) {
      if (newValue) {
        this.selectedLocal = this.getLocalById(newValue)
      }
    },
    "selected.sublocalId": function (newValue) {
      if (newValue) {
        this.selectedSublocal = this.getSublocalById(newValue)
      }
    },
    "selected.solutionId": function (newValue) {
      if (newValue) {
        this.selectedSolution = this.getSolutionById(newValue)
        this.selected.price = parseFloat(this.selectedSolution.price);
      }
    },
    "selected.groupId": function (newValue) {
      if (newValue) {
        this.selectedGroup = this.getGroupById(newValue)
      }
    },
    "selected.pointId": function (newValue) {
      if (newValue) {
        this.selectedPoint = this.getPointById(newValue)
        this.selected.type = this.selectedPoint.name;
      }
    },
    "selected.amountSolutionUsed": function (newValue) {
      this.calculateTotal(newValue, this.selected.price)
    },
    "selected.price": function (newValue) {
      this.calculateTotal(this.selected.amountSolutionUsed, newValue)
    },
    hasSelected(newValue) {
      if (newValue) {
        const solution = this.getSearchSolutionList(
          this.selected.localId,
          this.selected.sublocalId,
          this.selected.solutionId,
          this.selected.groupId,
          this.selected.pointId
        )
        const amountSolution = parseInt(solution.data.amountSolution)
        this.selected.maxAmountSolutionAllowed = amountSolution;
        this.selected.amountSolutionUsed = amountSolution <= 0 ? 0 : 1;
        this.selected.comments = solution.data.comments;
      }
    }
  },
  async created() {
    this.subscriberWaterfix({
      channel: 'checklist-local:update',
      fn: this.updateLocalListener
    })
    this.subscriberWaterfix({
      channel: 'checklist-sublocal:update',
      fn: this.updateSublocalListener
    })
    await this.updateDevolutions();
  },
};
</script>