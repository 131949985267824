<template>
  <div style=" display: flex">
    <v-col cols="12" md="6">
      <table style="
          border-radius: 5px;
          border-collapse: collapse;
          width: 100%;
          overflow: hidden;
          height: 85%;
        ">
        <caption class="TitleChecklist" style="background: #0a517f; color: white">
          <strong> CONSUMO </strong>
        </caption>
        <tr>
          <td class="TitleChecklist column-statitcs-info" style="background: #0e76bc; color: white">
            MÉDIA
          </td>
          <td class="borderTDandTH" style="background-color: #dddddd30">
            {{ consumption.formatAverage }} m³
          </td>
          <td class="borderTDandTH" style="background-color: #dddddd30">
            {{ valueCurrency.formatAverage }}
          </td>
        </tr>
        <tr>
          <td class="TitleChecklist column-statitcs-info" style="background: #0c68a5; color: white">
            MÁXIMO
          </td>
          <td class="borderTDandTH" style="background-color: #dddddd80">
            {{ consumption.formatMax }} m³
          </td>
          <td class="borderTDandTH" style="background-color: #dddddd80">
            {{ valueCurrency.formatMax }}
          </td>
        </tr>
        <tr>
          <td class="TitleChecklist column-statitcs-info" style="background: #0e76bc; color: white">
            MÍNIMO
          </td>
          <td class="borderTDandTH" style="background-color: #dddddd30">
            {{ consumption.formatMin }} m³
          </td>
          <td class="borderTDandTH" style="background-color: #dddddd30">
            {{ valueCurrency.formatMin }}
          </td>
        </tr>
        <tr>
          <td class="TitleChecklist column-statitcs-info" style="background: #0a517f; color: white">
            TOTAL
          </td>
          <td class="borderTDandTH" style="background-color: #0e76bc; color: white">
            {{ consumption.formatTotal }} m³
          </td>
          <td class="borderTDandTH" style="background-color: #0e76bc; color: white">
            {{ valueCurrency.formatTotal }}
          </td>
        </tr>
      </table>
    </v-col>
    <v-col cols="12" md="6">
      <table style="
          border-radius: 5px;
          border-collapse: collapse;
          width: 100%;
          overflow: hidden;
          height: 85%;
        ">
        <caption class="TitleChecklist" style="background: #218c24; color: white">
          <strong> ECONOMIA </strong>
        </caption>
        <tr>
          <td class="TitleChecklist column-statitcs-info" style="background: #3ab436; color: white">
            MÉDIA
          </td>
          <td class="borderTDandTH" style="background-color: #dddddd30">
            {{ savingConsumption.formatAverage }} m³
          </td>
          <td class="borderTDandTH" style="background-color: #dddddd30">
            {{ savingCurrency.formatAverage }}
          </td>
        </tr>
        <tr>
          <td class="TitleChecklist column-statitcs-info" style="background: #218c24; color: white">
            MÁXIMO
          </td>
          <td class="borderTDandTH" style="background-color: #dddddd80">
            {{ savingConsumption.formatMax }} m³
          </td>
          <td class="borderTDandTH" style="background-color: #dddddd80">
            {{ savingCurrency.formatMax }}
          </td>
        </tr>
        <tr>
          <td class="TitleChecklist column-statitcs-info" style="background: #3ab436; color: white">
            MÍNIMO
          </td>
          <td class="borderTDandTH" style="background-color: #dddddd30">
            {{ savingConsumption.formatMin }} m³
          </td>
          <td class="borderTDandTH" style="background-color: #dddddd30">
            {{ savingCurrency.formatMin }}
          </td>
        </tr>
        <tr>
          <td class="TitleChecklist column-statitcs-info" style="background: #218c24; color: white">
            TOTAL
          </td>
          <td class="borderTDandTH" style="background-color: #3ab436; color: white">
            {{ savingConsumption.formatTotal }} m³
          </td>
          <td class="borderTDandTH" style="background-color: #3ab436; color: white">
            {{ savingCurrency.formatTotal }}
          </td>
        </tr>
      </table>
    </v-col>
  </div>
</template>

<script>
import Utils from "@/scripts/Utils";

export default {
  data: () => ({
    color: "red",
  }),

  props: {
    consumption: {
      type: Object,
      default: {
        average: 0,
        min: 0,
        max: 0,
        total: 0,
        formatAverage: Utils.formatNumber(0),
        formatMin: Utils.formatNumber(0),
        formatMax: Utils.formatNumber(0),
        formatTotal: Utils.formatNumber(0),
      },
    },
    valueCurrency: {
      type: Object,
      default: {
        average: 0,
        min: 0,
        max: 0,
        total: 0,
        formatAverage: Utils.formatCurrency(0),
        formatMin: Utils.formatCurrency(0),
        formatMax: Utils.formatCurrency(0),
        formatTotal: Utils.formatCurrency(0),
      },
    },
    savingConsumption: {
      type: Object,
      default: {
        average: 0,
        min: 0,
        max: 0,
        total: 0,
        formatAverage: Utils.formatNumber(0),
        formatMin: Utils.formatNumber(0),
        formatMax: Utils.formatNumber(0),
        formatTotal: Utils.formatNumber(0),
      },
    },
    savingCurrency: {
      type: Object,
      default: {
        average: 0,
        min: 0,
        max: 0,
        total: 0,
        formatAverage: Utils.formatCurrency(0),
        formatMin: Utils.formatCurrency(0),
        formatMax: Utils.formatCurrency(0),
        formatTotal: Utils.formatCurrency(0),
      },
    },
  },

  computed: {
  },

  methods: {
    formatCurrency(value) {
      return Utils.formatCurrency(value);
    },
    formatNumber(value) {
      return Utils.formatNumber(value);
    },
  },
};
</script>


<style scoped>
.borderTDandTH {
  font-size: 14px;
  padding: 8px;
  text-align: center;
}

.TitleChecklist {
  font-size: 16px;
  padding: 8px;
  text-align: center;
}

.column-statitcs-info {
  width: 30%;
}

.checkedAlert {
  margin: 2px;
  background-color: #79c9ff;
  color: #79c9ff;
  border-radius: 10px;
}
</style>