import { db } from "@/firebase"

async function createProposal(proposal, idProposal) {
    try {
        proposal.id = idProposal;
        await db
            .collection("water_scan")
            .doc(idProposal)
            .set(proposal);
        return proposal;
    } catch (e) {
        console.error(e)
        throw e;
    }
}
async function generateIdDocRef() {
    try {
        const doc = db.collection("water_scan").doc();
        return doc.id;
    } catch (error) {
        console.error(error);
        throw error;
    }
}


async function checkRegistration(registration) {
    try {
        const querySnapshot = await db.collection("water_scan")
            .where("registration", "==", registration)
            .get();
        return (!querySnapshot.empty);
    } catch (error) {
        throw error;
    }
}

async function getProposalByRegistration(registration) {
    try {
        let response = null
        let querySnapshot = await db.collection("water_scan")
            .where("registration", "==", registration)
            .get();
        querySnapshot.forEach((doc) => {
            response = doc.data();
        });
        return (response);
    } catch (error) {
        throw error;
    }
}

async function getProposalTotalByRegistration(registration) {
    try {
        const collectionRef = db.collection('water_scan');
        const querySnapshot = await collectionRef.where("registration", "==", registration).get();
        const total = querySnapshot.size; // Obtém o número de documentos retornados
        return total;
    } catch (error) {
        console.error('Error in getProposalTotalByRegistration:', error);
        throw error;
    }
}

async function getProposalById(idProposal) {
    try {
        const response = await db.collection("water_scan")
            .doc(idProposal)
            .get();
        return response.data();
    } catch (error) {
        console.error("getProposalById: " + error)
    }
}

async function updateSolutionListById(dataList, idDoc) {
    try {
        await db
            .collection("water_scan")
            .doc(idDoc)
            .update({
                solutionList: dataList
            });

    } catch (e) {
        console.error("updateSolutionListById", e)
    }
}

async function updateInvestorsById(dataList, idDoc) {
    try {
        await db
            .collection("water_scan")
            .doc(idDoc)
            .update({
                investors: dataList
            });

    } catch (e) {
        console.error("updateSolutionListById", e)
    }
}

async function updateById(key, value, idDoc) {
    try {
        var payload = {}
        payload[key] = value
        await db
            .collection("water_scan")
            .doc(idDoc)
            .update(payload);
    } catch (e) {
        console.error("updateById", e)
    }
}

async function getWaterScanProposalByRegister(register) {
    try {
        let proposal = []
        let ref = await db
            .collection("water_scan")
            .where("registration", "==", register)
            .get();

        ref.forEach((doc) => proposal = doc.data());

        return proposal
    } catch (e) {
        console.error("getWaterScanProposalByRegister", e)
    }
}
async function deleteProposal(idProposal) {
    try {
        await db
            .collection("water_scan")
            .doc(idProposal)
            .delete();
        return 0;
    } catch (error) {
        console.error(error);
        return 1;
    }
}
async function updateProposal(data, idProposal) {
    try {
        await db
            .collection("water_scan")
            .doc(idProposal)
            .update(data);
        return (true);
    } catch (error) {
        console.error('updateProposal: ' + error);
        throw error;
    }
}

export default {
    generateIdDocRef,
    createProposal,
    deleteProposal,
    updateProposal,
    getProposalById,
    checkRegistration,
    getProposalByRegistration,
    getWaterScanProposalByRegister,
    updateSolutionListById,
    updateInvestorsById,
    updateById,
    getProposalTotalByRegistration,
}
