import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import CheckList from '@/components/FormsComponents/WaterFixSteps/CheckList.vue'
import Devolution from '@/components/FormsComponents/WaterFixSteps/Devolution.vue'
import Compromise from '@/components/FormsComponents/WaterFixSteps/Compromise.vue'
import Installation from '@/components/FormsComponents/WaterFixSteps/Installation.vue'
import LocationInformation from '@/components/FormsComponents/WaterFixSteps/LocationInformation.vue'
import ProgressBar from '@/components/ProgressBar/ProgressBar.vue'
import StepsBar from '@/components/StepsBar/StepsBar.vue'
import { showErrorSnackBar, showSuccessSnackBar } from "@/utils/SnackBartHelper"
import WaterFixService from '@/services/firebase/WaterFix/WaterFixService';
import Utils from '@/scripts/Utils';

import SignatureController from "../../../objects/Waterfix/CheckListTab/SignatureController";

export default {

  name: "NewWaterScan",
  components: {
    LocationInformation,
    ProgressBar,
    StepsBar,
    CheckList,
    Devolution,
    Compromise,
    Installation,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    closeFunc: {
      type: Function,
      default: () => {},
    },
    registration: {
      type: String,
      default: '',
    },
  },
  data: function () {
    return {
      closeAllPages: true,
      requestSave: false,
      validFormPag1: false,
      validFormPag2: false,
      validFormPag3: false,
      validFormPag4: false,
      advance: [],
      overlay: false,
      statusPos: 1,
      widthScreen: 0,
      enrollDialog: false,
      infoAlerts: 0,
      isPwsVisible: false,
      modensAlert: [],
      enrollObj: {
        locationInformation: [],
        checkList: [],
        // historicConsumption: [],
      },
      steps: [
        'Cadastro', 
        'Check List',
        'Devolução',
        'Ciência',
        'Instalação',
      ],
      isProposalFromWaterFix: false,
      isAllowedUpdate: false,
      positionStep: 0,
      selectStep: 1,
      enableStepWarn: [],
      nextStep: 0,

      saveLocaleInfo: false,
      saveChecklist: false,
      saveHistoryc: false,
      saveBuildProposal: false,

      // ---------------------------------------------------------------------
      // ---------------------------------------------------------------------
      // ---------------------------------------------------------------------

      pagesInformation: [
        {
          name: 'LocationInformation',
          alias: 'Cadastro',
          index: 0,
          dependsOn: [],
        }, 
        {
          name: 'CheckList',
          alias: 'Check List',
          index: 1,
          dependsOn: [],
        }, 
        {
          name: 'Devolution',
          alias: 'Devolução',
          index: 2,
          dependsOn: [],
        },
        {
          name: 'Compromise',
          alias: 'Ciência',
          index: 3,
          dependsOn: [],
        },
        {
          name: 'Installation',
          alias: 'Instalação',
          index: 4,
          dependsOn: [],
        },
      ],
      pagesIndexToName: [
        'LocationInformation',
        'CheckList',
        'Devolution',
        'Compromise',
        'Installation',
      ],
      lastIndexPage: -1,
      currentIndexPage: 0,
      hasInvalidPage: false,
      hasProposalWaterFixCreated: false,
      existsProposal: false,
      signatureController: null,
      proposal: null,
    }
  },
  computed: {
  },
  methods: {
    ...mapActions([
      'closeChannel',
      'subscribeChannel',
      'publishChannel',
    ]),
    async saveProposal() {
      if (this.proposal) {
        const { id } = this.proposal
        var signatures = await this.signatureController.reset_all_signatures(id)
        this.publishChannel({
          channel: 'waterfix',
          event: `proposal.signature:update`,
          value: signatures,
        })
        this.proposal = null
      }
      let status = await this.saveAllPages();
      if (!status) return
      this.setIsProposalFromWaterFix(true)
      if (!this.hasInvalidPage) this.closeFunc();
    },
    clearDisplay() {
      this.closeFunc();
      this.closeAllPages = false
    },
    // ---------------------------------------------------------------------
    // ---------------------------------------------------------------------
    // ---------------------------------------------------------------------
    setHasProposalWaterFixCreated(status) {
      this.hasProposalWaterFixCreated = status;
    },
    isNewProposal(status) {
      this.existsProposal = !status
      if (this.isProposalFromWaterFix) return;
      if (status) this.setIsProposalFromWaterFix(true)
    },
    getRefPage(pageName) {
      return this.$refs[pageName]
    },
    getPageInformation(pageName) {
      return this.pagesInformation.find((page) => page.name === pageName);
    },
    getPageNameFromIndex(pageIndex) {
      return this.pagesIndexToName[pageIndex]
    },
    setIsProposalFromWaterFix(status) {
      this.isProposalFromWaterFix = status;
    },
    setLastIndexPage(index) {
      this.lastIndexPage = index
    },
    setCurrentIndexPage(index) {
      this.setLastIndexPage(this.currentIndexPage)
      this.currentIndexPage = index
    },
    setNextStep(nextStep) {
      this.nextStep = nextStep
    },
    isEnabledUpdateWhenChangingTabs() {
      return this.isProposalFromWaterFix;
    },
    checkValidatePage(pageName, forceUpdate = false) {
      let pageRef = this.getRefPage(pageName);
      let pageInformation = this.getPageInformation(pageName)
      let alias = pageInformation.alias;
      this.hasInvalidPage = false;
      if (!pageRef.isValidadePage() && (this.existsProposal || forceUpdate)) {
        showErrorSnackBar(`Há dados inválidos no passo '${alias}'. Verifique as pendências!`)
        this.hasInvalidPage = true;
        // throw new Error("Proposta com pendência para salvar")
        return false;
      }
      return true;
    },
    async savePage(pageName, forceUpdate = false) {
      if (!this.existsProposal && !forceUpdate) return true;
      let pageRef = this.getRefPage(pageName);
      let pageInformation = this.getPageInformation(pageName)
      let dependsOn = pageInformation.dependsOn;
      // Verificando se a página atual é válida
      if (!this.checkValidatePage(pageName, forceUpdate)) return false;
      // Verificando se as dependencias da página estão válidas
      for (let i = 0; i < dependsOn.length; i++) {
        let dependentPageName = dependsOn[i];
        if (!this.checkValidatePage(dependentPageName, forceUpdate)) return false;
      }
      // Salvando os dados da página
      await pageRef.saveAll()
      return true
    },
    async savePageFromIndex(pageIndex) {
      let pageName = this.getPageNameFromIndex(pageIndex)
      if (!pageName) return;
      return await this.savePage(pageName)
    },
    async saveAllPages() {
      for (let i = 0; i < this.pagesIndexToName.length; i++) {
        let pageName = this.pagesIndexToName[i]
        let status = await this.savePage(pageName, true)
        if (!status) return false
      }
      return true;
    },
  },
  async created() {
    let statusWaterFix = await WaterFixService.checkRegistration(this.registration)
    if (statusWaterFix) {
      this.setIsProposalFromWaterFix(true)
    }
  },
  watch: {
    open() {
      this.closeAllPages = this.open
    },
    async positionStep(position) {
      this.setCurrentIndexPage(position)
      if (!this.isEnabledUpdateWhenChangingTabs()) {
        this.setNextStep(this.currentIndexPage)
        return;
      }
      await this.savePageFromIndex(this.lastIndexPage);
      this.setNextStep(this.currentIndexPage)
    },
    existsProposal: {
      immediate: true,
      handler: function (newValue) {
        if (!newValue) {
          this.steps = [
            'Cadastro', 
            'Check List',
          ]
          this.pagesInformation = [
            {
              name: 'LocationInformation',
              alias: 'Cadastro',
              index: 0,
              dependsOn: [],
            }, 
            {
              name: 'CheckList',
              alias: 'Check List',
              index: 1,
              dependsOn: [],
            }
          ]
          this.pagesIndexToName = [
            'LocationInformation',
            'CheckList',
          ]
        } else {
          this.steps = [
            'Cadastro', 
            'Check List',
            'Devolução',
            'Ciência',
            'Instalação',
          ]
          this.pagesInformation = [
            {
              name: 'LocationInformation',
              alias: 'Cadastro',
              index: 0,
              dependsOn: [],
            }, 
            {
              name: 'CheckList',
              alias: 'Check List',
              index: 1,
              dependsOn: [],
            }, 
            {
              name: 'Devolution',
              alias: 'Devolução',
              index: 2,
              dependsOn: [],
            },
            {
              name: 'Compromise',
              alias: 'Ciência',
              index: 3,
              dependsOn: [],
            },
            {
              name: 'Installation',
              alias: 'Instalação',
              index: 4,
              dependsOn: [],
            },
          ]
          this.pagesIndexToName = [
            'LocationInformation',
            'CheckList',
            'Devolution',
            'Compromise',
            'Installation',
          ]
        }
      },
    },
  },
  created() {
    this.signatureController = new SignatureController()

    this.subscribeChannel({
      channel: 'waterfix',
      events: [
        // -----------------------------------------------------
        {
          event: 'proposal',
          fn: (proposal) => {
            this.proposal = proposal
          }
        },
      ]
    })
  },
};