<template>
    <v-row class="no-margin no-padding">
        <v-col cols="12" md="6" style="padding: 0 5px;">
            <div class="container-info">
                <div style="border-bottom: 2px solid white;">
                    <v-card-text style="color: white;font-weight: bold;text-align: center;font-size: 1.2vh;text-transform: uppercase;display: flex;justify-content: center;align-items: center;height: 5vh;">
                        <span>Não Periódico</span>
                    </v-card-text>
                </div>
                <div class="container-data">
                    <div class="data-row">
                        <div class="data-info"><span>Falta de Sinal</span></div>
                        <div class="data-value"><span>{{ getNonPeriodicAlert('lack_of_signal') }}</span></div>
                    </div>
                    <div class="data-row" style="background-color: #1E90FF;">
                        <div class="data-info"><span>Bateria Fraca</span></div>
                        <div class="data-value"><span>{{ getNonPeriodicAlert('low_battery') }}</span></div>
                    </div>
                    <div class="data-row">
                        <div class="data-info"><span>Sem Variação</span></div>
                        <div class="data-value"><span>{{ getNonPeriodicAlert('no_variation') }}</span></div>
                    </div>
                    <div class="data-row" style="background-color: #228C22;border-radius: 0 0 10px 10px;">
                        <div class="data-info"><span>Total</span></div>
                        <div class="data-value"><span>{{ getNonPeriodicAlert() }}</span></div>
                    </div>
                </div>
            </div>
        </v-col>
        <v-col cols="12" md="6" style="padding: 0 5px;">
            <div class="container-info">
                <div style="border-bottom: 2px solid white;">
                    <v-card-text style="color: white;font-weight: bold;text-align: center;font-size: 1.2vh;text-transform: uppercase;display: flex;justify-content: center;align-items: center;height: 5vh;">
                        <span>Periódico</span>
                    </v-card-text>
                </div>
                <div class="container-data">
                    <div class="data-row">
                        <div class="data-info"><span>Consumo</span></div>
                        <div class="data-value"><span>{{ getPeriodicAlert('consumption') }}</span></div>
                    </div>
                    <div class="data-row" style="background-color: #228C22;border-radius: 0 0 10px 10px;">
                        <div class="data-info"><span>Total</span></div>
                        <div class="data-value"><span>{{ getPeriodicAlert() }}</span></div>
                    </div>
                </div>
            </div>
        </v-col>
    </v-row>
</template>

<script>
import Utils from '../../../scripts/Utils';

export default {
    
    data: () => ({
        
    }),

    props: {
        periodic: {
            type: Array,
            default: [],
        },
        non_periodic: {
            type: Object,
            default: {
                lack_of_signal: {
                    total: 0
                },
                low_battery: {
                    total: 0
                },
                no_variation: {
                    total: 0
                },
            }
        },
    },

    computed: {
    
    },

    methods: {
        getNonPeriodicAlert(type = null) {
            let total = 0;
            if (type === null) {
                for (const prop in this.non_periodic) {
                    total += this.non_periodic[prop].total
                }
            } else {
                if (this.non_periodic.hasOwnProperty(type)) {
                    total += this.non_periodic[type]['total'];
                }
            }
            return total;
        },
        getPeriodicAlert(type = null) {
            let total = 0;
            if (type === null) {
                total += this.periodic.reduce(
                    (previousValue, currentValue) => {
                        return previousValue + currentValue.total
                    },
                    total
                );
            } else {
                total += this.periodic.reduce(
                    (previousValue, currentValue) => {
                        if (currentValue.parameters.type !== type) return previousValue;
                        return previousValue + currentValue.total
                    },
                    total
                );
            }
            return total
        },
    },

    watch: {
        
    },

    created() {
        
    }

}
</script>


<style scoped>
    .container-info {
        background-color: #1976D2;
        display: flex;
        flex-direction: column;
        border-radius: 5px;
    }
    .container-data {
        display: flex;
        flex-direction: column;
    }
    .data-row {
        display: flex;
        height: 4vh;
    }
    .data-info {
        width: 125px;
        display: flex;
        align-items: center;
        padding-left: 15px;
        border-right: 2px solid white;
    }
    .data-info > span {
        font-size: 15px;
        color: white;
        font-weight: bold;
        letter-spacing: 1px;
        font-size: 1.1vh;
        text-transform: uppercase;
    }
    .data-value {
        flex: 4;
        display: flex;
        align-items: center;
        padding-left: 15px;
    }
    .data-value > span {
        font-size: 15px;
        color: white;
        font-weight: bold;
        letter-spacing: 1px;
        font-size: 1.1vh;
        text-transform: uppercase;
    }
    .no-margin {
        margin: 0 !important;
      }
      .no-padding {
        padding: 0 !important;
      }
</style>