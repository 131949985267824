<template>
  <div>
      <!-- <v-row>
          <v-col cols="12">
              <h3>Listagem dos itens devolvidos:</h3>
          </v-col>
      </v-row> -->
      <v-divider></v-divider>

      <!-- Dialog de novo local -->
      <v-row justify="center">
          <v-dialog transition="dialog-top-transition" v-model="installationModal" persistent max-width="600px">
              <v-card>
                <v-form ref="installationForm" lazy-validation>
                  <v-toolbar color="primary" dark> <span class="text-h5">Adicionar Local</span></v-toolbar>
                  <v-card-title>
                      <v-spacer></v-spacer>
                  </v-card-title>
                  <v-card-text style="padding-bottom: 0px;">
                        <v-row>
                            <v-col cols="12">
                                <div
                                    v-text="getLocalSelected"
                                    style="font-size: 20px;font-weight: bold;"
                                ></div>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row>
                            <v-col cols="12">
                                <v-autocomplete
                                    dense
                                    v-model="selected.solutionId"
                                    :items="getAvailableSolutions"
                                    :label="'Solução'"
                                    item-text="solution"
                                    item-value="id"
                                    outlined
                                    hide-details
                                    :loading="loading"
                                    :rules="rulesRequired"
                                    :disabled="loading"
                                >
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field 
                                    readonly
                                    label="Tipo do Item"
                                    :value="selected.point"
                                    disabled
                                >
                                </v-text-field>
                            </v-col>
                            <!-- <v-col cols="6">
                                <v-text-field 
                                    readonly
                                    label="Quantidade"
                                    :value="selected.amount"
                                    disabled
                                >
                                </v-text-field>
                            </v-col> -->
                        </v-row>
                        <v-divider></v-divider>
                        <v-row>
                            <v-col cols="12">
                                <v-textarea
                                    outlined
                                    name="input-7-4"
                                    label="Descrição"
                                    v-model="selected.description"
                                    :loading="loading"
                                    :disabled="!hasSelected || loading"
                                    :rules="rulesRequired"
                                ></v-textarea>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row>
                            <v-col cols="12">
                                <v-textarea
                                    outlined
                                    name="input-7-4"
                                    label="Comentário"
                                    v-model="selected.comments"
                                    :loading="loading"
                                    :disabled="!hasSelected || loading"
                                ></v-textarea>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="() => closeInstallationModal()" :loading="loading" :disabled="loading">
                            Cancelar
                        </v-btn>
                        <v-btn color="blue darken-1" text @click="addInstallation" :loading="loading" :disabled="loading">Adicionar</v-btn>
                    </v-card-actions> 
                </v-form>
              </v-card>
          </v-dialog>
      </v-row>

      <v-col cols="12">
        <v-row>
          <h3>Termo de Instalação:</h3>
        </v-row>
      </v-col>

      <v-col cols="12">
        <v-row>
          <v-autocomplete
            dense
            v-model="selected.localId"
            :items="getAvailableLocals"
            :label="'Local'"
            outlined
            item-text="local"
            item-value="id"
            hide-details
            @keyup.backspace="() => {}"
            :loading="loading"
            :disabled="!hasAvailableLocals"
            @input="(e) => updateSearchLocal(e)"
          >
          </v-autocomplete>
        </v-row>
      </v-col>
      <v-divider></v-divider>
      <v-col cols="12">
        <v-row>
          <v-data-table
            v-if="proposal !== null"
            :headers="headers"
            :items="proposal.installationList"
            class="elevation-1"
            style="width: 100%"
          >
            <template v-slot:body="{ items }">
              <tbody>
                <tr v-for="(installation, index) in items" :key="index">
                  <td>
                    <v-text-field       
                        readonly             
                        :value="installation.local" 
                      >
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field    
                      readonly                
                      :value="installation.solution" 
                    >
                    </v-text-field>
                  </td>
                  <!-- <td>
                    <v-text-field   
                      readonly
                      :value="installation.amount"
                    />
                  </td> -->
                  <td>
                    <v-text-field   
                      readonly
                      :value="installation.description"
                    />
                  </td>
                  <td>
                    <v-text-field   
                      readonly
                      :value="installation.comments"
                    />
                  </td>
                  <td>
                    <v-btn
                      elevation="2"
                      @click="confirmDelete(installation)"
                      :loading="loading"
                      style="height: 30px; margin: 2px"
                    >
                      <v-icon color="primary" small> mdi-delete </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-row>
      </v-col>
  </div>
</template>
<script>
import Rules from "@/utils/Rules";
import ConsumptionForm from "@/components/FormsComponents/WaterFix/ConsumptionTest.vue";
import firebase from "../../../firebase";

import WaterScanStoragefrom from '@/services/storage/WaterScanStorage';
import WaterFixService from '@/services/firebase/WaterFix/WaterFixService';
import Utils from '@/scripts/Utils';
import SolutionsServices from '@/services/firebase/SolutionsServices/SolutionsServices';
import SolutionsPoint from '@/services/firebase/SolutionsPoint/SolutionsPoint';
import SolutionsGroup from '@/services/firebase/SolutionsGroup/SolutionsGroup';
import { showDeleteDialogAlert } from "@/utils/DialogAlertHelper";
import { showInfoSnackBar, showErrorSnackBar, showSuccessSnackBar } from "@/utils/SnackBartHelper";
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    requestData: {
      default: null,
    },
    requestSave: {
      default: null,
    },
    registration: {
      default: ""
    },
    viewOrEdit: {
      default: ""
    },
    validFormProp: {
      default: () => {},
    },
    closeProposed: {
      default: () => {},
    },
    
  },
  name: "addressForm",
  data: function () {
    return {
        headers: [
          { text: "Local", value: "local", width: 100, sortable: true },
          { text: "Solução", value: "solution", width: 500, sortable: true },
          // { text: "Quantidade", value: "amount", width: 120, sortable: true },
          { text: "Descrição", value: "description", width: 500, sortable: true },
          { text: "Observação", value: "comments", width: 300, sortable: true },
          { text: "Ações", value: "action", sortable: false },
        ],
        proposalId: null,
        proposal: null,
        locals: [],
        solutions: [],
        groups: [],
        points: [],
        // --------------------------------------------
        dialog: false,
        selected: {
            local: null,
            localId: null,
            solution: null,
            solutionId: null,
            point: null,
            pointId: null,
            group: null,
            groupId: null,
            description: null,
            comments: null,
            amount: 0,
            type: null,
        },
        selectedLocal: null,
        selectedSolution: null,
        selectedGroup: null,
        selectedPoint: null,
        // --------------------------------------------
        installationListIds: [],
        searchInstallationListIds: [],
        installationModal: false,
        tempInstallation: null,
        // --------------------------------------------------------
        // --------------------------------------------------------
        // --------------------------------------------------------
        index: 2,
        loading: false,
        // --------------------------------------------------------
    };
  },
  methods: {
    ...mapActions([
      'subscriberWaterfix',
    ]),
    resetCompromise() {
        this.selected = {
            local: null,
            localId: null,
            solution: null,
            solutionId: null,
            point: null,
            pointId: null,
            group: null,
            groupId: null,
            description: null,
            comments: null,
            amount: 0,
            type: null,
        };
        this.selectedLocal = null;
        this.selectedSolution = null;
        this.selectedGroup = null;
        this.selectedPoint = null;
    },
    updateSearchLocal(input) {
      if (input !== null) {
        this.setNewInstalattion()
      }
    },
    // ---------------------------------------------------------------------------
    // Métodos obrigatórios para o gerenciamento do componente pai
    isValidadePage() { return true; }, // É preciso deixar para não quebrar a lógica do componente pai
    saveAll() {}, // É preciso deixar para não quebrar a lógica do componente pai
    setLoading(status) {
      this.loading = status;
    },
    emitLoadingEvent(status) {
      this.$emit("loading", status);
    },
    confirmDelete(compromise) {
      this.tempInstallation = compromise
      showDeleteDialogAlert(`Deseja excluir esse item?`, this.deleteCompromise)
    },
    async deleteCompromise() {
        let installationList = this.proposal.installationList.filter((item) => item.id !== this.tempInstallation.id)
        await WaterFixService.updateProposal(this.proposalId, { installationList })
        this.proposal.installationList = installationList
        await this.$nextTick(
            async () => {
            await this.updateProposalCycle(Utils.copyObject(this.proposal));
            this.tempInstallation = null;
            }
        )
    },
    // ---------------------------------------------------------------------------
    // ---------------------------------------------------------------------------
    // ---------------------------------------------------------------------------
    // Operações das listas de devolução, de solução e pontos
    async getProposalFromWaterFix(registration) {
      // Verificar se existe alguma proposta de water fix
      try {
        const proposalObject = await WaterFixService.getProposalByRegistration(registration)
        if (proposalObject) {
            return proposalObject;
        } else {
            showErrorSnackBar("Não foi encontrado a proposta")
            return null;
        }
      } catch (e) {
        showErrorSnackBar("Ocorreu um erro na busca da proposta para o termo de ciência")
        return null;
      }
    },
    async initializeLocals(proposalObject) {
      if (proposalObject) {
        this.proposal = proposalObject
        this.proposalId = this.proposal.id;
        let locals = Utils.removeDuplicatesCustom(this.proposal.solutionList.map(
          (item) => {
            return {
              id: this.createLocalId(item.local),
              local: item.local,
            }
          }
        ), ['id'])
        this.locals = locals;
        return;
      } else {
        showErrorSnackBar("Ocorreu um erro na busca dos termos de instalação da proposta")
      }
    },
    async initializeSublocals(proposalObject) {
      if (proposalObject) {
        let sublocals = Utils.removeDuplicatesCustom(this.proposal.solutionList.map(
          (item) => {
            return {
              id: this.createSublocalId(item.sub_local),
              sublocal: item.sub_local,
            }
          }
        ), ['id'])
        this.sublocals = sublocals;
        return;
      } else {
        showErrorSnackBar("Ocorreu um erro na busca dos termos de instalação da proposta")
      }
    },
    async initializeSolutions(proposalObject) {
        if (proposalObject) {
            let solutionIds = []
            let groupIds    = []
            let pointIds    = []
            if (proposalObject.solutionList) {
                solutionIds = proposalObject.solutionList.map((item) => item.solution)
            }
            if (solutionIds.length > 0) {
                solutionIds = solutionIds.filter(v => v !== null)
                let solutions = await SolutionsServices.GetSolutionsByIds(solutionIds)
                this.solutions = Object.values(solutions).filter((item) => item !== null)
                groupIds = this.solutions.map((item) => item.groupId)
                pointIds = this.solutions.map((item) => item.pointId)
            }
            if (groupIds.length > 0) {
                let groups = await SolutionsGroup.GetSolutionsGroupByIds(groupIds)
                this.groups = Object.values(groups)
            }
            if (pointIds.length > 0) {
                let points = await SolutionsPoint.GetSolutionsPointByIds(pointIds)
                this.points = Object.values(points)
            }
            return;
      } else {
        showErrorSnackBar("Ocorreu um erro na busca dos termos de ciência da proposta")
      }
    },
    async updateInstallation() {
        this.setLoading(true)
        this.emitLoadingEvent(true)
        this.resetCompromise();
        let proposalFromWaterFix = await this.getProposalFromWaterFix(this.registration);
        if (!proposalFromWaterFix) {
            this.setLoading(false)
            this.emitLoadingEvent(false)
            showErrorSnackBar("Ocorreu um erro na busca da proposta")
            return;
        }
        await this.updateProposalCycle(proposalFromWaterFix)
    },
    async updateProposalCycle(proposal) {
      this.setLoading(true)
      await this.initializeLocals(proposal);
      await this.initializeSublocals(proposal);
      await this.initializeSolutions(proposal);
      this.updateInstallationListIds();
      this.updateSearchInstallationListIds();
      this.setLoading(false)
      this.emitLoadingEvent(false)
    },
    // ---------------------------------------------------------------------------
    // Criar a lista de checklist disponivel para consultar
    getLocalById(localId) {
      return this.locals.find((item) => item.id == localId)
    },
    getSublocalById(sublocalId) {
      return this.sublocals.find((item) => item.id == sublocalId)
    },
    getSolutionById(solutionId) {
      return this.solutions.find((item) => item.id == solutionId)
    },
    getGroupById(groupId) {
      return this.groups.find((item) => item.id == groupId)
    },
    getPointById(pointId) {
      return this.points.find((item) => item.id == pointId)
    },
    createLocalId(local) {
        return Utils.createSlug(local);
    },
    createSublocalId(sublocal) {
        return Utils.createSlug(sublocal);
    },
    generateInstallationId(proposalId, localId, solutionId) {
      return `${proposalId}_${localId}_${solutionId}`
    },
    updateInstallationListIds() {
        this.installationListIds = this.proposal.installationList.map(
            (item) => {
                const localId = item.localId;
                const solutionId = item.solutionId;
                const groupId = item.groupId;
                const pointId = item.pointId;
                const id = this.generateInstallationId(this.proposalId, localId, solutionId);
                return {
                    id,
                    localId,
                    solutionId,
                    groupId,
                    pointId,
                };
            }
        ).filter((item) => item !== null)
    },
    updateSearchInstallationListIds() {
        this.searchInstallationListIds = this.proposal.solutionList.map(
            (item) => {
                const localId = this.createLocalId(item.local)
                const local = this.getLocalById(localId)
                if (!local) return null;
                const solution = this.getSolutionById(item.solution)
                if (!solution) return null;
                const group = this.getGroupById(solution.groupId)
                if (!group) return null;
                const point = this.getPointById(solution.pointId)
                if (!point) return null;
                const id = this.generateInstallationId(this.proposalId, localId, solution.id)
                return {
                    id,
                    localId: local.id,
                    solutionId: solution.id,
                    groupId: group.id,
                    pointId: point.id,
                }
            }
        )
        .filter((item) => item !== null)
    },
    openInstallationModal() {
      this.installationModal = true;
    },
    closeInstallationModal() {
      this.installationModal = false;
      this.resetForm();
      this.resetCompromise();
    },
    setNewInstalattion() {
      this.openInstallationModal()
    },
    async addInstallation() {
        this.setLoading(true)
        if (!this.getValidateForm) {
            showErrorSnackBar("Há dado(s) inválido(s) na inserção do termo de instalação")
            this.setLoading(false)
            return;
        }
        let selected = Utils.copyObject(this.selected)
        this.proposal.installationList.push({
            ...selected,
            id: this.generateInstallationId(this.proposalId, this.selected.localId, this.selected.solutionId),
        })
        await WaterFixService.updateProposal(this.proposalId, { installationList: this.proposal.installationList })
        this.$nextTick(
            async () => {
                await this.updateProposalCycle(Utils.copyObject(this.proposal));
                showSuccessSnackBar("Um novo termo de instalação foi inserido com sucesso!");
                this.closeInstallationModal();
                this.setLoading(false)
            }
        )
    },
    // // ---------------------------------------------------------------------------
    resetForm() {
      if (!this.$refs.installationForm) return;
      this.$refs.installationForm.reset();
    },
    async updateLocalListener(beforeId, afterId, text) {
      this.proposal.solutionList = this.proposal.solutionList.map(
        (solution) => {
          let localId = Utils.createSlug(solution.local)
          if (beforeId !== localId) return solution;
          return {
            ...solution,
            local: text,
          }
        }
      )
      this.proposal.installationList = this.proposal.installationList.map(
        (installation) => {
          const localId = Utils.createSlug(installation.local)
          if (beforeId !== localId) return installation;
          return {
            ...installation,
            local: text,
          }
        }
      )
      await WaterFixService.updateProposal(this.proposalId, { installationList: this.proposal.installationList })
      await this.updateProposalCycle(Utils.copyObject(this.proposal));
    },
  },
  computed: {
    rulesRequired() {
      return Rules.required();
    },
    rulesAmount() {
      return Rules.number(true, 1);
    },
    getLocalSelected() {
      if (!this.selected.localId) return "";
      return Utils.capitalizeString(this.getLocalById(this.selected.localId).local)
    },
    hasSelected() {
      return (
        this.selected.localId !== null &&
        this.selected.solutionId !== null
      )
    },
    getUsedInstallationIds() {
        return this.installationListIds.map((item) => item.id)
    },
    getAvailableInstallationListIds() {
        return this.searchInstallationListIds.filter((item) => !this.getUsedInstallationIds.includes(item.id))
    },
    getAvailableLocalIds() {
        return Utils.removeDuplicates(this.getAvailableInstallationListIds.map((item) => item.localId))
    },
    getAvailableSolutionIds() {
        return this.getAvailableInstallationListIds
        .filter((item) => (
            item.localId == this.selected.localId
        ))
        .map((item) => item.solutionId)
    },
    getAvailableLocals() {
        let locals = this.getAvailableLocalIds.map((localId) => this.getLocalById(localId))
        if (locals.length === 1) {
            if (this.selected.localId !== locals[0].id) {
                this.selected.localId = locals[0].id
            }
        }
        return locals;
    },
    getAvailableSolutions() {
        let solutions = this.getAvailableSolutionIds.map((solutionId) => this.getSolutionById(solutionId))
        if (solutions.length === 1) {
            if (this.selected.solutionId !== solutions[0].id) {
                this.selected.solutionId = solutions[0].id
            }
        }
        return solutions;
    },
    hasAvailableLocals() {
      return this.getAvailableLocals.length > 0;
    },
    hasAvailableSolutions() {
      return this.getAvailableSolutions.length > 0;
    },
    getValidateForm() {
      if (!this.$refs.installationForm) return false;
      return this.$refs.installationForm.validate();
    },
  },
  watch: {
    "selected.localId": function (newValue) {
      if (newValue) {
        this.selectedLocal = this.getLocalById(newValue)
        this.selected.local = this.selectedLocal.local
      }
    },
    "selected.solutionId": function (newValue) {
      if (newValue) {
        this.selectedSolution = this.getSolutionById(newValue)
        this.selectedGroup = this.getGroupById(this.selectedSolution.groupId)
        this.selectedPoint = this.getPointById(this.selectedSolution.pointId)
        this.selected.solution = this.selectedSolution.solution
        this.selected.amount = 0
        this.selected.groupId = this.selectedGroup.id
        this.selected.pointId = this.selectedPoint.id
        this.selected.group = this.selectedGroup.name
        this.selected.type = this.selectedGroup.type
        this.selected.point = this.selectedPoint.name
      }
    },
  },
  async created() {
    this.subscriberWaterfix({
      channel: 'checklist-local:update',
      fn: this.updateLocalListener
    })
    await this.updateInstallation();
  },
};
</script>