import Utils from '@/scripts/Utils';

const createChannelName = (name) => name ? Utils.createSlug(name) : name

export default {

    state: {
        PublishSubscribe: {
            
        }
    },

    actions: {
        async subscribeChannel({ state, commit }, obj) {
            let channel = createChannelName(obj.channel)
            if (!channel) throw new Error(`The channel name undefined`)
            let _PublishSubscribe = state.PublishSubscribe
            // let _PublishSubscribe = state.PublishSubscribe
            if (!_PublishSubscribe.hasOwnProperty(channel)) {
                _PublishSubscribe[channel] = []
                commit('SET_VALUE', {
                    PublishSubscribe: _PublishSubscribe
                })
            }
            if (!_PublishSubscribe.hasOwnProperty(channel)) throw new Error(`The channel '${channel}' not found to subscribe`)
            let events = obj.events
            let _event = undefined
            let _fn = undefined
            if (!events) {
                _event = obj.event
                if (!_event) throw new Error(`The event to subscribe undefined`)
                _fn = obj.fn
                if (!_fn) throw new Error(`The subscribe function undefined`)
                events = [
                    {
                        event: _event,
                        fn: _fn,
                    }
                ]
            }
            events.forEach(
                ({event, fn}) => {
                    if (!event) throw new Error(`The event to subscribe undefined`)
                    if (!fn) throw new Error(`The subscribe function undefined`)
                    _PublishSubscribe[channel].push({
                        e: event,
                        fn,
                    })
                }
            )
            commit('SET_VALUE', {
                PublishSubscribe: _PublishSubscribe
            })
        },
        publishChannel({ state, }, obj) {
            let channel = createChannelName(obj.channel)
            if (!channel) throw new Error(`The channel name undefined`)
            let _PublishSubscribe = state.PublishSubscribe
            if (!_PublishSubscribe.hasOwnProperty(channel)) {
                _PublishSubscribe[channel] = []
                commit('SET_VALUE', {
                    PublishSubscribe: _PublishSubscribe
                })
            }
            let _event = undefined
            let _value = undefined
            let events = obj.events
            if (!events) {
                _event = obj.event
                if (!_event) throw new Error(`The event to publish undefined`)
                _value = obj.value !== undefined ? obj.value : obj.val !== undefined ? obj.val : undefined
                if (_value === undefined) throw new Error(`The value to publish undefined`)
                events = [
                    {
                        event: _event,
                        value: _value,
                    }
                ]
            }
            if (!_PublishSubscribe.hasOwnProperty(channel)) throw new Error(`The channel '${channel}' not found to publish`)
            _PublishSubscribe[channel].forEach(
                ({ e, fn }) => {
                    events.forEach(
                        (_obj) => {
                            let event = _obj.event
                            let value = obj.value !== undefined ? obj.value : obj.val !== undefined ? obj.val : undefined
                            if (!event) throw new Error(`The event to subscribe undefined`)
                            if (value === undefined) throw new Error(`The value to subscribe undefined`)
                            if (e == event) {
                                fn(value)
                            }
                        }
                    )
                }
            );
        },
    },

    getters: {
        
    },

}