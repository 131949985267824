<template>
  <v-container fluid>
    <Signature 
      v-if="dialogSignature"
      :pdfUrl="documentUrl"
      :dialog="dialogSignature"
    />
    <!-- Dialog de novo local -->
    <v-row justify="center">
      <v-dialog transition="dialog-top-transition" v-model="choosePdfModal" persistent max-width="600px">
        <v-card>
          <v-form ref="downloadPdfForm" lazy-validation>
            <v-toolbar color="primary" dark> <span class="text-h5">Escolher a forma de baixar</span></v-toolbar>
            <v-card-title>
                <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text style="padding-bottom: 0px;">
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="termOption"
                    :items="termOptions"
                    label="Opção do PDF"
                    item-text="name"
                    item-value="id"
                    outlined
                    :loading="loading"
                    :disabled="loading"
                    :rules="rulesRequired"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row v-if="IsSignableDocument && !isSignedDocument">
                <v-col cols="12" style="display: flex; justify-content: center;">
                  <v-btn
                    class="ma-2"
                    color="secondary"
                    @click="() => openDialogSignature()"
                    :loading="loading"
                    :disabled="loading"
                  >
                    Assinar Documento!
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-show="isSignedDocument">
                <v-col cols="12">
                  <v-autocomplete
                    v-model="downloadOption"
                    :items="downloadOptions"
                    label="Qual a opção de baixar o PDF?"
                    item-text="name"
                    item-value="id"
                    outlined
                    :loading="loading"
                    :disabled="loading || hasUniqueDownloadOptions || !isSignedDocument"
                    :rules="rulesRequired"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="downloadOption"
                    :items="downloadOptions"
                    label="Qual a opção de baixar o PDF?"
                    item-text="name"
                    item-value="id"
                    outlined
                    :loading="loading"
                    :disabled="loading || hasUniqueDownloadOptions"
                    :rules="rulesRequired"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row v-show="isSignedDocument">
                <v-col v-if="isLocalDownload" cols="12">
                  <v-autocomplete
                    v-model="formGroupingOption"
                    :items="formGroupingOptions"
                    label="Qual a forma de agrupamento?"
                    item-text="name"
                    item-value="id"
                    outlined
                    :loading="loading"
                    :disabled="loading"
                    :rules="rulesRequired"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row v-show="isSignedDocument">
                <v-col v-if="isLocalDownload && hasLocals" cols="12">
                  <v-autocomplete
                    v-model="selectedLocals"
                    :items="locals"
                    item-text="local"
                    item-value="id"
                    outlined
                    dense
                    chips
                    small-chips
                    label="Quais os locais?"
                    multiple
                    :rules="rulesRequired"
                    :loading="loading"
                    :disabled="loading"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="() => closeChoosePdfModal()" :loading="loading" :disabled="loading">
                    Cancelar
                </v-btn>
                <v-btn color="blue darken-1" text @click="() => downloadPdfModal()" :loading="loading" :disabled="loading || !isSignedDocument">Baixar</v-btn>
            </v-card-actions> 
          </v-form>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card style="border-radius: 20px">
          <v-overlay absolute style="opacity: 0.6" :value="isLoadingProposal">
            <v-progress-circular
              indeterminate
              color="primary"
              :size="50"
            ></v-progress-circular>
          </v-overlay>
          <v-data-table
            :headers="headers"
            :items="filteredItems"
            no-data-text="Sem modems para exibir"
            style="border-radius: 20px"
          >
            <template v-slot:top>
              <div
                class="title-displays"
                style="padding-top: 15px; padding-left: 15px"
              >
                Propostas
              </div>
              <v-spacer></v-spacer>

              <!-- Busca -->
              <v-row style="justify-content: space-between">
                <v-text-field
                  style="width: 80%; padding: 1em"
                  class="px-4"
                  prepend-icon="search"
                  label="Busca"
                  v-model="searchText"
                ></v-text-field>
                <v-select
                  :items="searchItens"
                  hint="Buscar nessa coluna"
                  persistent-hint
                  style="width: 20%; padding: 1em"
                  return-object
                  single-line
                  v-model="columnToSeach"
                ></v-select>
              </v-row>
            </template>
            <template v-slot:body="{ items }">
              <tbody>
                <tr v-for="(proposal, index) in items" :key="index">
                  <td>
                    <v-text-field
                      :value="proposal.registration"
                      placeholder="Sem Matrícula"
                      type="text"
                      single-line
                      readonly
                    ></v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      :value="proposal.locationInformation.name"
                      placeholder="Sem dados"
                      type="text"
                      single-line
                      readonly
                    ></v-text-field>
                  </td>

                  <td>
                    <v-btn
                      elevation="2"
                      @click="() => {
                        setWaterFixOpenStatus('edit')
                        openViewComponent(proposal.registration)
                      }"
                    >
                      <v-icon color="primary" small> edit </v-icon>
                    </v-btn>

                    <v-btn
                      elevation="2"
                      @click="getProposalDelet(proposal.registration)"
                    >
                      <v-icon color="primary" small> mdi-delete </v-icon>
                    </v-btn>

                    <v-btn
                      elevation="2"
                      @click="() => newDownloadPdf(proposal)"
                    >
                      <v-icon color="primary" small>mdi-file-pdf-box</v-icon>
                    </v-btn>                  
                  </td>                 
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <!-- Inicio Nova Proposta -->
    <NewWaterFix
      v-if="openViewProposal"
      :open="openViewProposal"
      :closeFunc="closeComponent"
      :registration="registration"
      :viewOrEdit="'view'"
    />
    <!-- Fim Nova Proposta -->
  </v-container>
</template>
<script>
import ConsultWaterFixScript from "./ConsultWaterFixScript";
export default ConsultWaterFixScript;
</script>